/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type ApplyAddonToExperienceMutationVariables = Types.Exact<{
  addonId: Types.Scalars['String']['input'];
  experienceId: Types.Scalars['ID']['input'];
  checkoutId: Types.Scalars['String']['input'];
}>;


export type ApplyAddonToExperienceMutation = { __typename: 'Mutation', applyAddonToExperience: { __typename: 'Experience', id: string, addons: Array<{ __typename: 'Addon', id: string, chargebeeSku: string | null, displayName: string }> } };


export const ApplyAddonToExperienceDocument = `
    mutation ApplyAddonToExperience($addonId: String!, $experienceId: ID!, $checkoutId: String!) {
  applyAddonToExperience(
    addonId: $addonId
    experienceId: $experienceId
    checkoutId: $checkoutId
  ) {
    id
    addons {
      id
      chargebeeSku
      displayName
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ApplyAddonToExperience: build.mutation<ApplyAddonToExperienceMutation, ApplyAddonToExperienceMutationVariables>({
      query: (variables) => ({ document: ApplyAddonToExperienceDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useApplyAddonToExperienceMutation } = injectedRtkApi;

