import { useEffect } from 'react';
import { useLoadMyUserQuery } from '../data/auth';
import { useIsLoggedIn } from '../data/auth/auth.hooks';
import { clearUser, identifyUser } from '../util/sentry';

/**
 *
 * This component listens to login state and supplies the user id to sentry session
 * to allow for more accurate error traces.
 * It should only ever be used in the root App component for now, as it should not be rendered more than once
 * to avoid fetching and setting multiple times
 */
export const SentryIdentifier = () => {
  const isLoggedIn = useIsLoggedIn();
  const { data: myUser, refetch } = useLoadMyUserQuery();

  // Keep this up-to-date based on login state
  useEffect(() => {
    refetch();
  }, [isLoggedIn, refetch]);

  useEffect(() => {
    const activeUserId = myUser?.myUser.id;
    if (activeUserId) {
      identifyUser(activeUserId);
    } else {
      clearUser();
    }
  }, [myUser?.myUser.id]);
  return <></>;
};
