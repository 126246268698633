import { Button, Headline, Text } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import { Nullable } from 'types/util';

import { Card } from 'components/card.component';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { StartConfirmationModal } from './start-confirmation-modal';
import { displayToast } from 'services/toast';
import {
  useScheduleExperienceMutation,
  useStartCohortMutation,
  useWorkspaceExperiencesQuery,
} from 'data/experiences';
import {
  buildScheduleParams,
  buildBaseParams,
  StartEndFormInputData,
  useStartEndForm,
} from './use-start-end-form';
import { format, intervalToDuration, formatDuration } from 'date-fns';
import { Trans } from 'react-i18next';
import {
  systemTimezoneAbbreviation,
  abbreviateTimespan,
  MissionTrigger,
  checkExperienceOverlaps,
} from '../../util/time-util';
import {
  BroadcastTrigger,
  CohortState,
  ExperienceTriggerTiming,
  TriggerTimeUnit,
  WorkspaceType,
} from 'data/models';
import StartEndFormUI from './start-end-form-ui';
import { InlineBanner } from 'components/inline-banner';

interface DraftFormProps {
  cohortId: string;
  experienceId: string;
  workspace: {
    id: string;
    myWorkspaceMember: Nullable<{ roleId: string }>;
    type: WorkspaceType;
    subscription: { plan: { maxConcurrentExperiences: Nullable<number> } };
  };
  missionTriggers?: MissionTrigger[];
  broadcastTriggers?: BroadcastTrigger[];
  experienceTimezone: string;
}

// eslint-disable-next-line complexity
const DraftForm = ({
  experienceId,
  cohortId,
  missionTriggers,
  broadcastTriggers,
  workspace,
  experienceTimezone,
}: DraftFormProps) => {
  const { t } = useTranslate('pages.startEnd');
  const [showModal, setShowModal] = useState(false);
  const [scheduleCohort, { isLoading: isLoadingScheduleCohort }] = useScheduleExperienceMutation();
  const [startCohort, { isLoading: isLoadingStartCohort }] = useStartCohortMutation();
  const [startDateTime, setStartDateTime] = useState<Nullable<Date>>(null);
  const [endDateTime, setEndDateTime] = useState<Nullable<Date>>(null);
  const { data: workspaceExperiences } = useWorkspaceExperiencesQuery({
    workspaceId: workspace.id,
  });

  const isLoading = isLoadingScheduleCohort || isLoadingStartCohort;

  const currentExperience = workspaceExperiences?.workspaceExperiences.find(
    (experience) => experience.id === experienceId,
  );
  const experienceOverlaps = checkExperienceOverlaps(
    startDateTime,
    endDateTime,
    workspaceExperiences?.workspaceExperiences.filter(
      (experience) =>
        // filter the current experience because it can't overlap with itself
        experience.id !== experienceId &&
        // filter experiences by status
        (experience.status === CohortState.Active || experience.status === CohortState.Scheduled) &&
        // filter experiences that have addons
        !experience.addons.length,
    ) ?? [],
  );
  const overlapExperiencesCount = experienceOverlaps.filter(
    (overlap) => overlap.overlapStart || overlap.overlapEnd,
  ).length;
  // if experience overlap count is greater or equal than maxConcurrentExperiences
  // and the current experience does not have addons, limit the live experiences
  const shouldLimitLiveExperiences =
    typeof workspace.subscription.plan.maxConcurrentExperiences === 'number' &&
    overlapExperiencesCount >= workspace.subscription.plan.maxConcurrentExperiences &&
    !currentExperience?.addons.length;
  const overlapStart = experienceOverlaps.some((overlap) => overlap.overlapStart);
  const overlapEnd = experienceOverlaps.some((overlap) => overlap.overlapEnd);

  const { control, trigger, handleSubmit, formState, getValues } = useStartEndForm({
    defaultValues: {
      startTiming: 'NOW',
      endTiming: ExperienceTriggerTiming.Relative,
      relativeUnit: TriggerTimeUnit.Hours,
    },
    editingLive: false,
    startDate: null,
    overlapStart: shouldLimitLiveExperiences && overlapStart,
    overlapEnd: shouldLimitLiveExperiences && overlapEnd,
  });

  useEffect(() => {
    if (formState.isDirty) {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLimitLiveExperiences]);

  const handleSave = async (data: StartEndFormInputData) => {
    try {
      if (data.startTiming === 'NOW') {
        const startParams = buildBaseParams(cohortId, data);
        await startCohort({ params: startParams });

        displayToast({
          id: 'start-experience-toast',
          title: t('startToast.success.title'),
          body: t('startToast.success.body'),
          type: 'success',
        });
      } else {
        const schedulingParams = buildScheduleParams(cohortId, data);
        await scheduleCohort({ params: schedulingParams });

        displayToast({
          id: 'schedule-experience-toast',
          title: t('scheduleToast.success.title'),
          body: t('scheduleToast.success.body'),
          type: 'success',
        });
      }
    } catch {
      if (data.startTiming === 'NOW') {
        displayToast({
          id: 'start-experience-toast',
          title: t('startToast.error.title'),
          body: t('startToast.error.body'),
          type: 'error',
        });
      } else {
        displayToast({
          id: 'schedule-experience-toast',
          title: t('scheduleToast.error.title'),
          body: t('scheduleToast.error.body'),
          type: 'error',
        });
      }
    }
    setShowModal(false);
  };

  const totalTime = useMemo(() => {
    if (!(startDateTime && endDateTime)) {
      return '';
    }
    const duration = intervalToDuration({ start: startDateTime, end: endDateTime });
    return abbreviateTimespan(
      formatDuration(duration, {
        format: ['years', 'months', 'days', 'hours', 'minutes'],
      }),
    );
  }, [startDateTime, endDateTime]);

  const handleChangeStart = useCallback(
    (val: Nullable<Date>) => {
      setStartDateTime(val);
    },
    [setStartDateTime],
  );

  const handleChangeEnd = useCallback(
    (val: Nullable<Date>) => {
      setEndDateTime(val);
      if (val !== endDateTime) {
        trigger();
      }
    },
    [setEndDateTime, trigger, endDateTime],
  );

  return (
    <>
      <Card className="mt-4 p-5 w-full">
        <>
          <Headline type="secondary" size="sm" className="mb-6">
            {t('draftHeadline')}
          </Headline>
          <StartEndFormUI
            control={control}
            trigger={trigger}
            onChangeStart={handleChangeStart}
            onChangeEnd={handleChangeEnd}
            startDisabled={false}
            endDisabled={false}
            cohortState={CohortState.Draft}
            startDateTime={startDateTime}
            missionTriggers={missionTriggers}
            broadcastTriggers={broadcastTriggers}
            experienceTimezone={experienceTimezone}
          />
          <div className="border-t border-black-12 pt-6 mt-4">
            {startDateTime && endDateTime && (
              <Text className="mb-6">
                <Trans>
                  {t(getValues('startTiming') === 'NOW' ? 'goLiveInfo' : 'scheduleInfo', {
                    startDateTime: format(startDateTime, "EEEE, LLLL d yyyy 'at' h:mm aa"),
                    endDateTime: format(endDateTime, "EEEE, LLLL d yyyy 'at' h:mm aa"),
                    timeZone: systemTimezoneAbbreviation,
                    totalTime: totalTime,
                  })}
                </Trans>
              </Text>
            )}
            {shouldLimitLiveExperiences ? (
              <div className="mb-6">
                <InlineBanner
                  i18nKey="freeLiveExperienceLimit"
                  showUpgradePlanAction
                  showUpgradeExperienceAction={workspace.type === WorkspaceType.Regular}
                />
              </div>
            ) : null}
            <Button
              label={getValues('startTiming') === 'NOW' ? t('goLive') : t('schedule')}
              leftIcon="Play"
              onClick={() => setShowModal(true)}
              disabled={!formState.isValid || shouldLimitLiveExperiences}
            />
          </div>
        </>
      </Card>
      <StartConfirmationModal
        show={showModal}
        onSubmit={handleSubmit(handleSave)}
        onClose={() => setShowModal(false)}
        scheduled={getValues('startTiming') !== 'NOW'}
        startDateTime={startDateTime ? format(startDateTime, "EEEE, LLLL d yyyy 'at' h:mm aa") : ''}
        endDateTime={endDateTime ? format(endDateTime, "EEEE, LLLL d yyyy 'at' h:mm aa") : ''}
        timeZone={systemTimezoneAbbreviation ?? ''}
        totalTime={totalTime}
        disableConfirm={isLoading}
      />
    </>
  );
};

export default DraftForm;
