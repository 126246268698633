import { Label, mergeClasses, Text } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { HelpCircle } from 'components/help-circle.component';
import { displayToast } from '../../services/toast';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';

interface JoinCodeProps {
  joinCode?: string;
}

const JoinCode = ({ joinCode }: JoinCodeProps) => {
  const { t } = useTranslate('sidebar.experienceSettings.joinCode');

  const handleClick = () => {
    if (joinCode) {
      navigator.clipboard.writeText(joinCode).then(() => {
        displayToast({
          id: 'join-code-copied',
          type: 'success',
          title: t('copiedToast.title'),
          body: t('copiedToast.body'),
        });
      });
    }
  };

  return (
    <div className={mergeClasses('flex flex-col gap-4', { invisible: !joinCode })}>
      <div className="flex flex-row gap-2">
        <Label className="uppercase">{t('label')}</Label>
        <HelpCircle id="join-code-tooltip" tooltipText={t('tooltip.body')} className="w-[193px]" />
      </div>
      <button
        className="py-1 px-2 bg-offWhite rounded-lg border border-black-12"
        onClick={handleClick}
        aria-label={t('ariaLabel') ?? undefined}>
        <Text size="md">{joinCode}</Text>
      </button>
    </div>
  );
};

export const ExperienceSettings = () => {
  const { data } = useExperienceInfo();

  const joinCode = data?.experience?.joinCode;

  return (
    <div className={`border-t border-black-12 p-4`}>
      <JoinCode joinCode={joinCode} />
      <div className={`flex flex-row justify-between pt-4`}>
        {/* TODO: Show modals on clicking Settings/Share - uncomment when features are implemented */}
        {/* <Icon name="Settings" size={24} color="black" className="inline-block" />
        <Icon name="Share" size={24} color="black" className="inline-block" /> */}
      </div>
    </div>
  );
};
