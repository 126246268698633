import { api as _loadMyUserApi } from './graphql/__generated__/load-my-user.query.generated';
import {
  api as loginApi,
  type LoginMutation,
} from './graphql/__generated__/login.mutation.generated';
import {
  api as signUpApi,
  type SignUpMutation,
} from './graphql/__generated__/sign-up.mutation.generated';
import { api as logoutApi } from './graphql/__generated__/logout.mutation.generated';
import { api as changePasswordApi } from './graphql/__generated__/change-password.mutation.generated';
import { api as _updateUserProfileApi } from './graphql/__generated__/update-user-profile.mutation.generated';
import { api as _deleteAccountApi } from './graphql/__generated__/delete-account.mutation.generated';
import { api as forgotPasswordApi } from './graphql/__generated__/forgot-password.mutation.generated';
import { api as isPasswordResetIdValidApi } from './graphql/__generated__/is-password-reset-id-valid.query.generated';
import { api as resetPasswordApi } from './graphql/__generated__/reset-password.mutation.generated';
import { api as checkAccountDeletionApi } from './graphql/__generated__/check-account-deletion.query.generated';
import { api as authImpersonationSessionApi } from './graphql/__generated__/auth-impersonation-session.generated';
export {
  loginApi,
  type LoginMutation,
  signUpApi,
  type SignUpMutation,
  logoutApi,
  changePasswordApi,
  forgotPasswordApi,
  isPasswordResetIdValidApi,
  resetPasswordApi,
  checkAccountDeletionApi,
  authImpersonationSessionApi,
};

export const loadMyUserApi = _loadMyUserApi.enhanceEndpoints({
  endpoints: { LoadMyUser: { providesTags: ['MyUser'] } },
});

export const updateUserProfileApi = _updateUserProfileApi.enhanceEndpoints({
  endpoints: { UpdateUserProfile: { invalidatesTags: ['MyUser'] } },
});

export const deleteAccountApi = _deleteAccountApi.enhanceEndpoints({
  endpoints: {
    DeleteAccount: {
      invalidatesTags: ['MyUser'],
    },
  },
});
