import {
  closeAndResetTeamModal,
  useLoadTeamsForExperienceQuery,
  useSelectedTeam,
} from '../../data/teams';
import { TeamListItem } from './team-list-item';
import { ReactComponent as HatchedEgg } from 'media/hatched-egg.svg';
import { useTranslate } from '../../util/i18n';
import { LoadingSpinner } from '../loading-spinner';
import { EmptyState } from '../empty-state';
import { EditTeamModal } from './edit-team-modal';
import { useDispatch } from 'react-redux';
import { InlineBanner } from 'components/inline-banner';
import { ShowUpgradeBanner } from 'data/models';

interface I18nData {
  key: string;
  values: Record<string, number | null>;
}

interface GetInlineBannerI18nData {
  maxParticipantsPerExperienceFromPlan?: number | null;
  maxParticipantsPerExperienceFromExperience?: number | null;
  maxTeamsPerExperienceFromExperience?: number | null;
  shouldDisableIndividualMode?: boolean;
}

const getInlineBannerI18nData = ({
  maxParticipantsPerExperienceFromPlan,
  maxParticipantsPerExperienceFromExperience,
  maxTeamsPerExperienceFromExperience,
  shouldDisableIndividualMode,
}: GetInlineBannerI18nData): I18nData => {
  let key = 'maxParticipantsPerExperience';
  if (typeof maxParticipantsPerExperienceFromPlan === 'number') {
    key = 'maxParticipantsPerExperienceReached';
  } else if (shouldDisableIndividualMode) {
    key = 'maxTeamsPerExperience';
  }

  const values = {
    maxTeamsPerExperience: maxTeamsPerExperienceFromExperience ?? null,
    maxParticipantsPerExperience: maxParticipantsPerExperienceFromExperience ?? null,
  };

  return {
    key,
    values,
  };
};

interface TeamListProps {
  experienceId: string;
  shouldDisableIndividualMode?: boolean;
  showUpgradeExperienceAction?: boolean;
}

// eslint-disable-next-line complexity
export const TeamList = ({
  experienceId,
  shouldDisableIndividualMode,
  showUpgradeExperienceAction,
}: TeamListProps) => {
  const { data, isLoading } = useLoadTeamsForExperienceQuery({ experienceId });
  const cohort = data?.experience?.cohorts[0]; // For now we assume 1 cohort
  const plan = data?.experience?.workspace.subscription.plan;
  const experience = data?.experience;
  const teams = cohort?.teams ?? [];
  const { t } = useTranslate('pages.participants.teams');
  const dispatch = useDispatch();
  const selectedTeam = useSelectedTeam();

  const handleClose = () => {
    dispatch(closeAndResetTeamModal());
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const i18nData = getInlineBannerI18nData({
    maxParticipantsPerExperienceFromPlan: plan?.maxParticipantsPerExperience,
    maxParticipantsPerExperienceFromExperience: experience?.maxParticipantsPerExperience ?? null,
    maxTeamsPerExperienceFromExperience: experience?.maxTeamsPerExperience ?? null,
    shouldDisableIndividualMode,
  });

  return (
    <>
      {(plan?.showUpgradeBanner === ShowUpgradeBanner.Always &&
        (typeof experience?.maxParticipantsPerExperience === 'number' ||
          typeof experience?.maxTeamsPerExperience === 'number')) ||
      (plan?.showUpgradeBanner === ShowUpgradeBanner.AtParticipantLimit &&
        typeof experience?.maxParticipantsPerExperience === 'number' &&
        typeof cohort?.numberOfParticipants === 'number' &&
        cohort.numberOfParticipants >= experience.maxParticipantsPerExperience) ? (
        <div className="mb-6">
          <InlineBanner
            i18nKey={i18nData.key}
            i18nValues={i18nData.values}
            showUpgradeExperienceAction={showUpgradeExperienceAction}
            showUpgradePlanAction
          />
        </div>
      ) : null}
      {!(teams.length || isLoading) || !cohort ? (
        <div className="flex justify-center items-center flex-col">
          <EmptyState
            renderGraphic={() => <HatchedEgg width="323" className="mb-6" />}
            text={t('emptyState.title')}
            subtext={t('emptyState.body')}
          />
        </div>
      ) : (
        <div className="border-[1px] border-black-12 rounded-lg bg-white divide-y-[1px] divide-black-12">
          {teams.map((team) => (
            <TeamListItem
              key={team.id}
              id={team.id}
              name={team.displayName}
              teamMaxSize={team.maxSize}
              numParticipants={team.participants.length}
              passcode={team.passcode}
              isPreCreated={team.isPreCreated}
              teamMode={team.mode}
              photoUrl={team.photoUrl}
            />
          ))}
        </div>
      )}

      {selectedTeam && (
        <EditTeamModal
          defaultValues={{
            ...selectedTeam,
            passcode: selectedTeam.passcode ?? '',
          }}
          numParticipants={selectedTeam.numParticipants}
          photoUrl={selectedTeam.photoUrl ?? undefined}
          onClose={handleClose}
          onSubmitSuccess={handleClose}
          shouldDisableIndividualMode={shouldDisableIndividualMode}
        />
      )}
    </>
  );
};
