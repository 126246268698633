import { Dropdown } from '@goosechase/ui';
import type { TFunction } from 'i18next';

import { MISSION_TRIGGER_TIMINGS, MissionTriggerTiming } from '../../data/models';
import { useTranslate } from '../../util/i18n';
import { DropdownOptionType } from './dropdown-option.component';
import { LabelledSetting } from '../labelled-setting.component';

const MISSION_TRIGGER_TIMING_TO_TRANSLATION_KEY_MAP: Record<
  MissionTriggerTiming | 'DEFAULT',
  string
> = {
  DEFAULT: 'default',
  RELATIVE: 'relative',
  SPECIFIC: 'specific',
};

type TriggerType = 'releaseTrigger' | 'expireTrigger';

const convertMissionTriggerTimingToDropdownOption = (
  t: TFunction,
  triggerType: TriggerType,
  missionTriggerTiming: MissionTriggerTiming | 'DEFAULT',
): DropdownOptionType => ({
  id: missionTriggerTiming,
  title: t(
    `timing.${triggerType}.${MISSION_TRIGGER_TIMING_TO_TRANSLATION_KEY_MAP[missionTriggerTiming]}.title`,
  ),
  description: t(
    `timing.${triggerType}.${MISSION_TRIGGER_TIMING_TO_TRANSLATION_KEY_MAP[missionTriggerTiming]}.description`,
  ),
});

interface MissionTriggerTimingDropdownProps {
  value: MissionTriggerTiming;
  onChange: (value: MissionTriggerTiming) => void;
  triggerType: TriggerType;
}

export const MissionTriggerTimingDropdown = ({
  value,
  onChange,
  triggerType,
}: MissionTriggerTimingDropdownProps) => {
  const { t } = useTranslate('missionForm.missionTrigger');

  const options = ['DEFAULT' as const, ...MISSION_TRIGGER_TIMINGS].map((missionTriggerTiming) =>
    convertMissionTriggerTimingToDropdownOption(t, triggerType, missionTriggerTiming),
  );

  const selectedDropdownOption = convertMissionTriggerTimingToDropdownOption(t, triggerType, value);

  return (
    <LabelledSetting
      label={t(`timing.${triggerType}Title`)}
      description={t(`timing.${triggerType}Description`)}>
      <Dropdown
        buttonClassName="w-full"
        label={selectedDropdownOption.title}
        options={options}
        selectedOptionId={value}
        onSelect={(val: string) => onChange(val as MissionTriggerTiming)}
      />
    </LabelledSetting>
  );
};
