import { Headline } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { useExperienceFormDetailsQuery } from '../../data/experiences';
import { useExperienceOutletContext } from '../../components/experience-layout';
import { getExperienceSubPageTitleOptions } from '../utils';
import { TeamList } from '../../components/team-list';
import { Card } from '../../components/card.component';
import { PageHeadline } from '../../components/page-headline.component';
import { CreateTeamModal } from './create-team-modal';
import ParticipantsOptions from './participants-options.component';
import { ErrorState } from 'components/error-state';
import { LoadingSpinner } from 'components/loading-spinner';
import { ShareJoinCode } from './share-join-code.component';
import { useExperienceWorkspaceQuery } from 'data/workspaces';
import { WorkspaceType } from 'data/models';
import { useLoadTeamsForExperienceQuery } from '../../data/teams';

export const ParticipantsPage = () => {
  const { experienceId } = useExperienceOutletContext();
  const { t } = useTranslate('pages.participants');
  const { data, isLoading, isError } = useExperienceFormDetailsQuery({ id: experienceId });
  const { data: teamsData, isLoading: teamsIsLoading } = useLoadTeamsForExperienceQuery({
    experienceId,
  });

  const displayName = data?.experience?.displayName;
  const {
    data: workspaceData,
    isError: workspaceError,
    isLoading: workspaceLoading,
  } = useExperienceWorkspaceQuery({ id: experienceId });

  if (isError || workspaceError) {
    return (
      <Card className="mt-4 p-5 w-full">
        <ErrorState />
      </Card>
    );
  }

  if (
    !data?.experience ||
    isLoading ||
    !workspaceData?.experience?.workspace ||
    workspaceLoading ||
    !teamsData?.experience ||
    teamsIsLoading
  ) {
    return (
      <Card className="mt-4 p-5 w-full">
        <div className="flex justify-center py-16 px-8">
          <LoadingSpinner />
        </div>
      </Card>
    );
  }

  const workspace = workspaceData.experience.workspace;

  const shouldDisableIndividualMode = !workspace.subscription.plan.individualModeEnabled;
  const planHasLimitOfParticipantsPerExperience =
    typeof workspace.subscription.plan.maxParticipantsPerExperience === 'number';
  const showUpgradeExperienceAction =
    workspace.type === WorkspaceType.Regular && !planHasLimitOfParticipantsPerExperience;
  const numberOfTeams = teamsData.experience.cohorts[0]?.teams.length ?? 0; // For now we assume 1 cohort
  const teamLimitReached =
    typeof teamsData.experience.maxTeamsPerExperience === 'number' &&
    numberOfTeams >= teamsData.experience.maxTeamsPerExperience;
  const shouldDisableCreateNewTeam = shouldDisableIndividualMode && teamLimitReached;

  return (
    <Page title={t('meta.title', getExperienceSubPageTitleOptions(displayName))}>
      <div className="flex flex-col gap-6 pb-6">
        <PageHeadline>{t('title')}</PageHeadline>
        <ShareJoinCode />
        <Card className="p-6">
          <ParticipantsOptions
            experience={data.experience}
            shouldDisableIndividualMode={shouldDisableIndividualMode}
          />
        </Card>
        <Card className="p-6">
          <>
            <div className="flex justify-between mb-6">
              <Headline type="secondary" size="sm">
                {t('teams.title')}
              </Headline>
              <CreateTeamModal
                defaultTeamMode={data.experience.studioDefaultTeamMode}
                defaultTeamMaxSize={data.experience.studioDefaultTeamMaxSize}
                shouldDisableIndividualMode={shouldDisableIndividualMode}
                shouldDisableCreateNewTeam={shouldDisableCreateNewTeam}
              />
            </div>
            <TeamList
              experienceId={experienceId}
              shouldDisableIndividualMode={shouldDisableIndividualMode}
              showUpgradeExperienceAction={showUpgradeExperienceAction}
            />
          </>
        </Card>
      </div>
    </Page>
  );
};
