import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useTranslate } from '../../util/i18n';
import { transformEmptyInputToNull } from 'util/form-util';

export interface SubmissionBonusFormInputData {
  note: string;
  value: string;
}

export type SubmissionBonusFormOutputData = SubmissionBonusFormInputData;

export const NOTE_MAX_CHARS = 500;

export const SUBMISSION_BONUS_FORM_DEFAULT_VALUES = {
  note: '',
  value: '',
};

export const useSubmissionBonusForm = () => {
  const { t } = useTranslate('submissionBonusForm');

  const submissionBonusFormSchema = z.object({
    value: z.coerce
      .string()
      .min(1, { message: t('value.errors.required') ?? undefined })
      .transform((val, ctx) => {
        const parsed = parseInt(val);
        if (isNaN(parsed)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('value.errors.number') ?? undefined,
          });
          return z.NEVER;
        }
        return parsed;
      }),
    note: z
      .string()
      .max(NOTE_MAX_CHARS, {
        message: t('note.errors.max', { maxChars: NOTE_MAX_CHARS }) ?? undefined,
      })
      .transform(transformEmptyInputToNull),
  });

  return useForm<SubmissionBonusFormInputData, unknown, SubmissionBonusFormOutputData>({
    mode: 'onTouched',
    defaultValues: SUBMISSION_BONUS_FORM_DEFAULT_VALUES,
    resolver: zodResolver(submissionBonusFormSchema),
  });
};
