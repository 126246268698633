import {
  Dropdown,
  Label,
  RadioButton,
  DatePicker,
  Text,
  TimeInput,
  mergeClasses,
} from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import {
  Controller,
  type UseFormTrigger,
  useWatch,
  type Control,
  useFormState,
} from 'react-hook-form';
import { StartEndFormInputData } from './use-start-end-form';
import {
  BroadcastTrigger,
  ExperienceTriggerTiming,
  TriggerTimeUnit,
  TRIGGER_TIME_UNITS,
} from '../../data/models';
import { useEffect, useMemo } from 'react';
import {
  allBroadcastTriggersValid,
  allMissionTriggersValid,
  calculateRelativeTriggerTime,
  mergeDateAndTime,
  TIME_UNIT_TO_TRANSLATION_KEY_MAP,
  type MissionTrigger,
} from 'util/time-util';
import { Nullable } from 'types/util';
import { NumberInput } from 'components/number-input';

interface EndDateTimeProps {
  control: Control<StartEndFormInputData>;
  trigger: UseFormTrigger<StartEndFormInputData>;
  onChangeDateTime: (date: Date | null) => void;
  startDateTime: Nullable<Date>;
  className?: string;
  disabled?: boolean;
  missionTriggers?: MissionTrigger[];
  broadcastTriggers?: BroadcastTrigger[];
  experienceTimezone: string;
}

interface TriggersWarningProps {
  missionWarning: boolean;
  broadcastWarning: boolean;
}

const TriggersWarning = ({ missionWarning, broadcastWarning }: TriggersWarningProps) => {
  const { t } = useTranslate('pages.startEnd');
  let warningText = '';
  if (missionWarning && broadcastWarning) {
    warningText = t('warnings.bothTriggers');
  } else if (missionWarning) {
    warningText = t('warnings.missionTrigger');
  } else if (broadcastWarning) {
    warningText = t('warnings.broadcastTrigger');
  }
  return (
    <div className="bg-vibrantRed-10 border border-vibrantRed rounded-lg p-4 mt-3">
      <Text size="md">
        <span dangerouslySetInnerHTML={{ __html: warningText }} />
      </Text>
    </div>
  );
};

const EndDateTime = ({
  control,
  trigger,
  onChangeDateTime,
  startDateTime,
  className,
  disabled = false,
  missionTriggers,
  broadcastTriggers,
  experienceTimezone,
}: EndDateTimeProps) => {
  const { t } = useTranslate('pages.startEnd');

  const formWatch = useWatch({ control });
  const formState = useFormState({ control });

  const relativeUnitOptions = TRIGGER_TIME_UNITS.map((timeUnit) => {
    return {
      id: timeUnit,
      title: t(`form.endUnit.options.${TIME_UNIT_TO_TRANSLATION_KEY_MAP[timeUnit].toLowerCase()}`),
    };
  });

  const endDateTime = useMemo(() => {
    if (
      formWatch.endTiming === 'RELATIVE' &&
      formWatch.relativeDuration &&
      formWatch.relativeUnit &&
      startDateTime
    ) {
      return calculateRelativeTriggerTime(
        startDateTime,
        formWatch.relativeUnit,
        formWatch.relativeDuration,
      );
    } else if (formWatch.endTiming === 'ABSOLUTE' && formWatch.endDate && formWatch.endTime) {
      return mergeDateAndTime(formWatch.endDate, formWatch.endTime);
    } else {
      return null;
    }
  }, [
    formWatch.endTiming,
    formWatch.endDate,
    formWatch.endTime,
    formWatch.relativeDuration,
    formWatch.relativeUnit,
    startDateTime,
  ]);

  useEffect(() => {
    onChangeDateTime(endDateTime);
  }, [endDateTime, onChangeDateTime]);

  const missionTriggersValid =
    startDateTime && endDateTime && missionTriggers
      ? allMissionTriggersValid(startDateTime, endDateTime, missionTriggers, experienceTimezone)
      : true;
  const broadcastTriggersValid =
    startDateTime && endDateTime && broadcastTriggers
      ? allBroadcastTriggersValid(startDateTime, endDateTime, broadcastTriggers, experienceTimezone)
      : true;

  const allTriggersValid = missionTriggersValid && broadcastTriggersValid;

  return (
    <div className={className}>
      <Controller
        control={control}
        name="endTiming"
        render={({ field: { onChange, value } }) => (
          <>
            <Label size="sm" className="block mb-2">
              {t('form.end.label').toUpperCase()}
            </Label>
            <div>
              <label
                className={mergeClasses('flex items-center mb-3 cursor-pointer', {
                  'cursor-default': disabled,
                })}>
                <RadioButton
                  value={ExperienceTriggerTiming.Relative}
                  name="End"
                  checked={value === ExperienceTriggerTiming.Relative}
                  onChange={onChange}
                  disabled={disabled}
                />
                <Text
                  className={mergeClasses('ml-1.5', {
                    'text-black-48 cursor-default': disabled,
                  })}>
                  {t('form.end.options.relative')}
                </Text>
              </label>
              <div
                className={mergeClasses('mb-3', {
                  hidden: formWatch.endTiming !== ExperienceTriggerTiming.Relative,
                })}>
                <div className="flex flex-row w-full mb-1 gap-x-3 justify-between">
                  <Controller
                    control={control}
                    name="relativeDuration"
                    render={({
                      field: {
                        onChange: onChangeEndDuration,
                        onBlur: onBlurEndDuration,
                        value: endDurationValue,
                        name: endDurationName,
                      },
                      fieldState: fieldStateDuration,
                    }) => (
                      <NumberInput
                        label={t('form.endDuration.label') ?? undefined}
                        placeholder={t('form.endDuration.label') ?? undefined}
                        name={endDurationName}
                        onBlur={onBlurEndDuration}
                        onChange={onChangeEndDuration}
                        error={Boolean(fieldStateDuration.error?.message)}
                        hideSpinButtons
                        value={endDurationValue ?? null}
                        containerClassName="w-full"
                        className="w-full"
                        disabled={disabled}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="relativeUnit"
                    render={({ field: { onChange: onChangeEndUnit, value: endUnitValue } }) => (
                      <Dropdown
                        label={
                          relativeUnitOptions.find((option) => option.id === endUnitValue)?.title ??
                          t('form.endUnit.label')
                        }
                        options={relativeUnitOptions}
                        selectedOptionId={endUnitValue}
                        onSelect={(val: string) => onChangeEndUnit(val as TriggerTimeUnit)}
                        containerClassName="w-full"
                        buttonClassName="w-full"
                        disabled={disabled}
                      />
                    )}
                  />
                </div>
                {allTriggersValid ? (
                  <Text className="text-black-64 w-full mb-2 basis-full" size="xs">
                    {t('form.end.hint.relative')}
                  </Text>
                ) : (
                  <TriggersWarning
                    missionWarning={!missionTriggersValid}
                    broadcastWarning={!broadcastTriggersValid}
                  />
                )}
                {formState.errors.relativeDuration && (
                  <Text size="xs" className="text-vibrantRed h-4 mb-3">
                    {formState.errors.relativeDuration?.message ?? ''}
                  </Text>
                )}
              </div>

              <label
                className={mergeClasses('flex items-center mb-3 cursor-pointer', {
                  'cursor-default': disabled,
                })}>
                <RadioButton
                  value={ExperienceTriggerTiming.Absolute}
                  name="End"
                  checked={value === ExperienceTriggerTiming.Absolute}
                  onChange={onChange}
                  disabled={disabled}
                />
                <Text
                  className={mergeClasses('ml-1.5', {
                    'text-black-48 cursor-default': disabled,
                  })}>
                  {t('form.end.options.absolute')}
                </Text>
              </label>
              <div
                className={mergeClasses('flex flex-col desktop:flex-row gap-x-3 gap-y-3', {
                  hidden: formWatch.endTiming !== ExperienceTriggerTiming.Absolute,
                })}>
                <Controller
                  control={control}
                  name="endDate"
                  render={({
                    field: { onChange: onChangeEndDate, value: endDateValue },
                    fieldState: { isDirty, error: errorDate },
                  }) => (
                    <DatePicker
                      date={endDateValue}
                      onChange={(val) => {
                        trigger();
                        onChangeEndDate(val);
                      }}
                      isDirty={isDirty}
                      error={errorDate?.message ?? ''}
                      disabled={disabled}
                      disablePastDates
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="endTime"
                  render={({
                    field: { onChange: onChangeEndTime, value: endTimeValue },
                    fieldState: { isDirty, error: errorTime },
                  }) => (
                    <TimeInput
                      value={endTimeValue}
                      onChangeValue={(val) => {
                        trigger();
                        onChangeEndTime(val);
                      }}
                      isDirty={isDirty}
                      name="endTime"
                      error={errorTime?.message ?? ''}
                      disabled={disabled}
                      aria-label={t('endTime.ariaLabel') ?? 'End time'}
                    />
                  )}
                />
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default EndDateTime;
