import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { analytics } from 'util/analytics';

export const usePageView = () => {
  const location = useLocation();

  useEffect(() => {
    analytics.page(location.pathname);
  }, [location]);
};
