import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'data/hooks';
import { setAuthState, type LoginMutation } from 'data/auth';
import { useLazyMyUseCasesQuery } from 'data/onboarding';
import { AccountFormLayout } from 'components/account-form-layout.component';
import { useTranslate } from 'util/i18n';
import { Text, Headline } from '@goosechase/ui';
import { LoginForm } from './login-form';
import { Trans } from 'react-i18next';
import { ReactComponent as GooseCarryingSubmissions } from 'media/goose-carrying-submissions.svg';
import { AppRoutes } from 'constants/routes';
import { AppLink } from 'components/app-link.component';
import { Page } from 'components/page.component';
import { useQueryParams } from 'hooks/use-query-params';

export const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [fetchMyUseCases, { isUninitialized }] = useLazyMyUseCasesQuery();
  const { t } = useTranslate('pages.login');
  const queryParams = useQueryParams();

  const isFetchingSelectedUseCases = !isUninitialized;
  const redirectTo = queryParams.get('redirectTo');

  const handleLoginSuccess = async ({ userId, token, intercomHmac }: LoginMutation['signIn']) => {
    dispatch(setAuthState({ accessToken: token, intercomHmac, userId }));
    try {
      if (redirectTo) {
        return navigate(redirectTo);
      }
      // Fetch selected use cases and redirect accordingly
      const myUseCases = await fetchMyUseCases().unwrap();
      const hasSelectedUseCases = (myUseCases.myUseCases ?? []).length > 0;

      if (hasSelectedUseCases) {
        navigate(AppRoutes.HOME);
      } else {
        // TODO: Handle bypassing this for EDU users
        navigate(AppRoutes.USE_CASE_SELECTION);
      }
    } catch {
      // TODO: Sentry - Failed to fetch UseCase status
      // Fail silently - This check will happen again on the next login
      navigate(AppRoutes.HOME);
    }
  };

  return (
    <Page title={t('meta.title')} description={t('meta.description') as string}>
      <AccountFormLayout>
        <AccountFormLayout.Body>
          <AccountFormLayout.Title>{t('heading')}</AccountFormLayout.Title>
          <AccountFormLayout.Description>
            <Trans
              t={t}
              i18nKey="subheading"
              components={{
                Link: <AppLink page="SIGNUP" queryParams={queryParams} />,
              }}
              href={AppRoutes.SIGNUP}
            />
          </AccountFormLayout.Description>
          <LoginForm
            isLoadingOnboarding={isFetchingSelectedUseCases}
            onLoginSuccess={handleLoginSuccess}
          />
        </AccountFormLayout.Body>
        <AccountFormLayout.Panel className="bg-lilac">
          <div className="flex flex-col jutify-center">
            <Headline size="sm" className="mb-3" type="secondary">
              {t('rightPanel.heading')}
            </Headline>
            <Text size="lg">{t('rightPanel.description')}</Text>
            <div className="mb-2 w-96 self-center">
              <GooseCarryingSubmissions className="fill-black" />
            </div>
          </div>
        </AccountFormLayout.Panel>
      </AccountFormLayout>
    </Page>
  );
};
