import { Button, NotificationBanner } from '@goosechase/ui';
import { LabelledField } from 'components/labelled-field.component';
import { SelectField } from 'components/select-field.component';
import { useForm, Controller, Control } from 'react-hook-form';
import { useTranslate } from 'util/i18n';
import { zodResolver } from '@hookform/resolvers/zod';
import { TFunction } from 'i18next';
import { z } from 'zod';
import { EducatorProfile, POSITIONS, SCHOOL_LEVELS } from 'data/onboarding/types';
import { useSubmitEducatorProfileMutation } from 'data/onboarding';
import { useState } from 'react';
import { useCreateEducatorWorkspaceMutation } from 'data/workspaces';

interface FormFieldProps {
  name: keyof EducatorInfoFormData;
  control: Control<EducatorInfoFormData>;
  label: string;
  errorMessage?: string;
  className?: string;
}
const ControlledTextField = ({ name, control, label, errorMessage, className }: FormFieldProps) => {
  return (
    <div className="mt-4">
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur }, fieldState: { error } }) => (
          <LabelledField
            label={label}
            onChange={onChange}
            onBlur={onBlur}
            className={className}
            errorMessage={error?.message ?? errorMessage}
          />
        )}
      />
    </div>
  );
};

type EducatorInfoFormData = EducatorProfile;

const formDataSchema = (t: TFunction) =>
  z.object({
    firstName: z.string().min(1, { message: t('firstName.errors.required') ?? undefined }),
    lastName: z.string().min(1, { message: t('lastName.errors.required') ?? undefined }),
    position: z.enum(POSITIONS, {
      required_error: t('position.errors.required') ?? undefined,
      invalid_type_error: t('position.errors.required') ?? undefined,
    }),
    schoolDistrict: z
      .string()
      .min(1, { message: t('schoolDistrict.errors.required') ?? undefined }),
    schoolName: z.string().min(1, { message: t('schoolName.errors.required') ?? undefined }),
    schoolLevel: z.enum(SCHOOL_LEVELS, {
      required_error: t('schoolLevel.errors.required') ?? undefined,
      invalid_type_error: t('schoolLevel.errors.required') ?? undefined,
    }),
  });

export interface EducatorFormProps {
  onSubmitSuccess?: () => void;
  onSubmitError?: (e: Error) => void;
}

export const EducatorInfoForm = (props: EducatorFormProps) => {
  const { t } = useTranslate('pages.onboarding.educatorInfo.form');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<EducatorInfoFormData>({
    mode: 'onTouched',
    resolver: zodResolver(formDataSchema(t)),
  });

  const [submitEducatorForm] = useSubmitEducatorProfileMutation();
  const [createEducatorWorkspace] = useCreateEducatorWorkspaceMutation();

  const onSubmit = async (fields: EducatorProfile) => {
    try {
      setErrorMessage(null);
      const educatorInfoResult = await submitEducatorForm({ params: fields }).unwrap();
      await createEducatorWorkspace({
        displayName: educatorInfoResult.createEducatorProfile.schoolName,
      }).unwrap();
      props.onSubmitSuccess?.();
    } catch (e) {
      setErrorMessage(t('errors.unknown'));
      props.onSubmitError?.(e as Error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
      <div className="flex flex-row justify-between">
        <ControlledTextField
          name="firstName"
          control={control}
          label={t('firstName.label')}
          className="mr-2"
        />
        <ControlledTextField name="lastName" control={control} label={t('lastName.label')} />
      </div>
      <div className="mt-4">
        <Controller
          control={control}
          name="position"
          render={({ field: { onChange, onBlur }, fieldState: { error } }) => (
            <SelectField
              options={POSITIONS}
              onSelect={onChange}
              onBlur={onBlur}
              label={t('position.label') ?? ''}
              name="position"
              getOptionLabel={(option) => t(`position.options.${option.toLowerCase()}`)}
              errorMessage={error?.message}
              allowEmptyOption
            />
          )}
        />
      </div>
      <ControlledTextField
        name="schoolDistrict"
        control={control}
        label={t('schoolDistrict.label')}
      />
      <ControlledTextField name="schoolName" control={control} label={t('schoolName.label')} />
      <div className="mt-4">
        <Controller
          control={control}
          name="schoolLevel"
          render={({ field: { onChange, onBlur }, fieldState: { error } }) => (
            <SelectField
              options={SCHOOL_LEVELS}
              onSelect={onChange}
              onBlur={onBlur}
              label={t('schoolLevel.label') ?? ''}
              name="position"
              getOptionLabel={(option) => t(`schoolLevel.options.${option.toLowerCase()}`)}
              errorMessage={error?.message}
              allowEmptyOption
            />
          )}
        />
      </div>
      {errorMessage && (
        <div className="mt-4">
          <NotificationBanner type="error" title={t('errors.title')} body={errorMessage} />
        </div>
      )}

      <div className="self-center">
        <Button
          className="w-full tablet-narrow:w-fit mt-4"
          label={t('submit')}
          disabled={!isValid}
        />
      </div>
    </form>
  );
};
