import { IconButton, Modal, mergeClasses } from '@goosechase/ui';
import { DeleteSubmissionModal } from 'components/delete-submission-modal';
import { SubmissionBonusFormOutputData } from 'components/submission-bonus-form';
import { useAddSubmissionBonusMutation, useToggleLikeSubmissionMutation } from 'data/submissions';
import { useCopySubmissionLink } from 'hooks/use-copy-submission-link.hook';
import { useDeleteSubmissionBonus } from 'hooks/use-delete-submission-bonus.hook';
import { useDeleteSubmissionModal } from 'hooks/use-delete-submission-modal.hook';
import { useDownloadSubmission } from 'hooks/use-download-submission.hook';
import { Trans } from 'react-i18next';
import { displayToast } from 'services/toast';
import { useTranslate } from 'util/i18n';
import { SubmissionFocusedView } from '../submission-focused-view';
import { type Submission } from '../submission-grid';

interface SubmissionFocusedViewModalProps {
  currentFocusedSubmission?: Submission;
  setCurrentFocusedSubmissionId: (submissionId?: string) => void;
  experienceId: string;
  submissions: Submission[];
  autoFocusBonus?: boolean;
  setAutoFocusBonus?: (value: boolean) => void;
  onAddBonus?: (submissionId: string, bonusId: string, value: number, note?: string) => void;
}

export const SubmissionFocusedViewModal = ({
  setCurrentFocusedSubmissionId,
  currentFocusedSubmission,
  experienceId,
  submissions,
  autoFocusBonus,
  setAutoFocusBonus,
  onAddBonus,
}: SubmissionFocusedViewModalProps) => {
  const { t } = useTranslate('submissionFocusedView');
  const { t: t2 } = useTranslate('likeButton');
  const [addSubmissionBonus] = useAddSubmissionBonusMutation();
  const deleteSubmissionBonus = useDeleteSubmissionBonus();
  const [toggleLike] = useToggleLikeSubmissionMutation();
  const { show, submissionId, missionName, onClose, onShow } = useDeleteSubmissionModal();
  const copySubmissionLink = useCopySubmissionLink();
  const downloadSubmission = useDownloadSubmission({
    filename: currentFocusedSubmission?.submissionId,
    missionType: currentFocusedSubmission?.missionType,
    mediaSrc: currentFocusedSubmission?.submissionDownloadSrc,
  });

  const clearCurrentSubmission = () => {
    setCurrentFocusedSubmissionId(undefined);
    setAutoFocusBonus?.(false);
  };

  const handleDeleteSubmission = () => {
    if (currentFocusedSubmission) {
      clearCurrentSubmission();
      onShow(currentFocusedSubmission?.submissionId, currentFocusedSubmission?.missionName);
    }
  };

  const handleAddSubmissionBonus = async (values: SubmissionBonusFormOutputData) => {
    if (currentFocusedSubmission) {
      try {
        const result = await addSubmissionBonus({
          params: {
            submissionId: currentFocusedSubmission.submissionId,
            note: values.note,
            value: Number(values.value),
          },
        }).unwrap();
        // update local state if on activity feed page due to pagination
        onAddBonus?.(
          currentFocusedSubmission.submissionId,
          result.addSubmissionBonus.id,
          Number(values.value),
          values.note,
        );
        displayToast({
          id: currentFocusedSubmission?.submissionId,
          title: t(`toasts.addSubmissionBonusSuccess.title`),
          type: 'success',
          body: (
            <Trans
              t={t}
              i18nKey="toasts.addSubmissionBonusSuccess.body"
              values={{
                value: values.value,
                teamName: currentFocusedSubmission.teamName,
              }}
            />
          ),
        });
      } catch {
        displayToast({
          id: currentFocusedSubmission?.submissionId,
          title: t(`toasts.addSubmissionBonusError.title`),
          type: 'error',
          body: t(`toasts.addSubmissionBonusError.body`, { value: values.value }),
        });
      }
    }
  };

  const handleNextSubmission = () => {
    const currentIndex = submissions.findIndex(
      (submission) => submission.submissionId === currentFocusedSubmission?.submissionId,
    );
    if (currentIndex !== -1) {
      const isLastItem = currentIndex === submissions.length - 1;
      setCurrentFocusedSubmissionId(submissions[isLastItem ? 0 : currentIndex + 1].submissionId);
      setAutoFocusBonus?.(false);
    }
  };

  const handlePreviousSubmission = () => {
    const currentIndex = submissions.findIndex(
      (submission) => submission.submissionId === currentFocusedSubmission?.submissionId,
    );
    if (currentIndex !== -1) {
      const isFirstItem = currentIndex === 0;
      setCurrentFocusedSubmissionId(
        submissions[isFirstItem ? submissions.length - 1 : currentIndex - 1].submissionId,
      );
      setAutoFocusBonus?.(false);
    }
  };

  const handleLikeSubmission = async () => {
    if (currentFocusedSubmission?.submissionId) {
      try {
        await toggleLike({ id: currentFocusedSubmission?.submissionId }).unwrap();
      } catch (e) {
        const toastKey = currentFocusedSubmission.isLiked ? 'removeLikeError' : 'likeError';
        displayToast({
          id: currentFocusedSubmission?.submissionId,
          title: t2(`toasts.${toastKey}.title`),
          type: 'error',
          body: t2(`toasts.${toastKey}.body`),
        });
      }
    }
  };

  const handleCopySubmissionLink = () => {
    if (currentFocusedSubmission?.submissionId) {
      copySubmissionLink(currentFocusedSubmission.submissionId);
    }
  };

  const isCameraMission = currentFocusedSubmission?.missionType === 'CameraMission';

  return (
    <>
      <Modal
        show={Boolean(currentFocusedSubmission)}
        onClose={clearCurrentSubmission}
        className="flex flex-col overflow-y-auto tablet-wide:overflow-y-hidden h-full rounded-none border-0 w-[100vw] h-[calc(100vh-112px)] tablet-narrow:rounded-xl tablet-narrow:w-[552px] tablet-narrow:h-[876px] tablet-wide:w-[832px] tablet-wide:h-[552px] desktop:w-[1080px] desktop:h-[708px] relative"
        overlayClassName="bg-transparent items-end tablet-narrow:bg-black/50 tablet-narrow:items-center">
        <div className="absolute top-4 right-3 z-10">
          <IconButton
            icon="Close"
            iconColor={isCameraMission ? 'white' : 'black'}
            iconClassName={mergeClasses(
              'tablet-wide:stroke-black tablet-wide:group-hover:stroke-black drop-shadow-md',
              {
                'group-hover:stroke-black': !isCameraMission,
                'group-hover:stroke-white': isCameraMission,
              },
            )}
            onClick={clearCurrentSubmission}
            aria-label={t('ariaCloseLabel') ?? undefined}
          />
        </div>
        {currentFocusedSubmission ? (
          <SubmissionFocusedView
            submission={currentFocusedSubmission}
            experienceId={experienceId}
            onDeleteSubmission={handleDeleteSubmission}
            onAddSubmissionBonus={handleAddSubmissionBonus}
            onDeleteSubmissionBonus={deleteSubmissionBonus}
            onNextSubmission={submissions.length > 1 ? handleNextSubmission : undefined}
            onPreviousSubmission={submissions.length > 1 ? handlePreviousSubmission : undefined}
            onLikeSubmission={handleLikeSubmission}
            onCopySubmissionLink={handleCopySubmissionLink}
            onDownloadSubmission={downloadSubmission}
            autoFocusBonus={autoFocusBonus}
          />
        ) : null}
      </Modal>
      <DeleteSubmissionModal
        show={show}
        submissionId={submissionId}
        missionName={missionName}
        onClose={onClose}
      />
    </>
  );
};
