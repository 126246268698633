import { Button, Headline, Text } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import { Card } from 'components/card.component';
import { useCallback, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  buildBaseParams,
  buildScheduleParams,
  StartEndFormInputData,
  useStartEndForm,
} from './use-start-end-form';
import { format, formatDuration, intervalToDuration } from 'date-fns';
import {
  abbreviateTimespan,
  checkExperienceOverlaps,
  MissionTrigger,
  systemTimezoneAbbreviation,
} from 'util/time-util';
import { Nullable } from 'types/util';
import {
  BroadcastTrigger,
  CohortState,
  ExperienceTrigger,
  ExperienceTriggerTiming,
  TriggerTimeUnit,
  WorkspaceType,
} from '../../data/models';
import { ResetConfirmationModal } from './reset-confirmation-modal';
import {
  useScheduleExperienceMutation,
  useStartCohortMutation,
  useResetExperienceMutation,
  useWorkspaceExperiencesQuery,
} from 'data/experiences';
import { displayToast } from 'services/toast';
import { EditScheduledConfirmationModal } from './edit-scheduled-confirmation-modal';
import { StartConfirmationModal } from './start-confirmation-modal';
import StartEndFormUI from './start-end-form-ui';
import { InlineBanner } from 'components/inline-banner';

interface ScheduledFormProps {
  experienceId: string;
  cohortId: string;
  startTrigger: ExperienceTrigger;
  endTrigger: ExperienceTrigger;
  missionTriggers?: MissionTrigger[];
  broadcastTriggers?: BroadcastTrigger[];
  workspace: {
    id: string;
    myWorkspaceMember: Nullable<{ roleId: string }>;
    type: WorkspaceType;
    subscription: { plan: { maxConcurrentExperiences: Nullable<number> } };
  };
  experienceTimezone: string;
}

// eslint-disable-next-line complexity
const ScheduledForm = ({
  experienceId,
  cohortId,
  startTrigger,
  endTrigger,
  missionTriggers,
  broadcastTriggers,
  workspace,
  experienceTimezone,
}: ScheduledFormProps) => {
  const { t } = useTranslate('pages.startEnd');
  const [editable, setEditable] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showGoLiveModal, setShowGoLiveModal] = useState(false);
  const [resetExperience] = useResetExperienceMutation();
  const [scheduleCohort, { isLoading: isLoadingScheduleCohort }] = useScheduleExperienceMutation();
  const [startCohort, { isLoading: isLoadingStartCohort }] = useStartCohortMutation();
  const { data: workspaceExperiences } = useWorkspaceExperiencesQuery({
    workspaceId: workspace.id,
  });

  const isLoading = isLoadingScheduleCohort || isLoadingStartCohort;

  // TODO: make sure these are in the right time zone
  const [startDateTime, setStartDateTime] = useState<Nullable<Date>>(
    new Date(startTrigger.triggerAt),
  );
  const [endDateTime, setEndDateTime] = useState<Nullable<Date>>(new Date(endTrigger.triggerAt));

  const defaultValues = {
    startTiming: startTrigger.timing ?? 'NOW',
    startDate: startTrigger.triggerAt ? new Date(startTrigger.triggerAt) : undefined,
    startTime: startTrigger.triggerAt ? new Date(startTrigger.triggerAt) : undefined,
    endTiming: endTrigger.timing ?? ExperienceTriggerTiming.Relative,
    relativeDuration: endTrigger.relativeDuration ?? undefined,
    relativeUnit: endTrigger.relativeUnit ?? TriggerTimeUnit.Hours,
    endDate: endTrigger.triggerAt ? new Date(endTrigger.triggerAt) : undefined,
    endTime: endTrigger.triggerAt ? new Date(endTrigger.triggerAt) : undefined,
  };

  const currentExperience = workspaceExperiences?.workspaceExperiences.find(
    (experience) => experience.id === experienceId,
  );
  const experienceOverlaps = checkExperienceOverlaps(
    startDateTime,
    endDateTime,
    workspaceExperiences?.workspaceExperiences.filter(
      (experience) =>
        // filter the current experience because it can't overlap with itself
        experience.id !== experienceId &&
        // filter experiences by status
        (experience.status === CohortState.Active || experience.status === CohortState.Scheduled) &&
        // filter experiences that have addons
        !experience.addons.length,
    ) ?? [],
  );
  const overlapExperiencesCount = experienceOverlaps.filter(
    (overlap) => overlap.overlapStart || overlap.overlapEnd,
  ).length;
  // if experience overlap count is greater or equal than maxConcurrentExperiences
  // and the current experience does not have addons, limit the live experiences
  const shouldLimitLiveExperiences =
    typeof workspace.subscription.plan.maxConcurrentExperiences === 'number' &&
    overlapExperiencesCount >= workspace.subscription.plan.maxConcurrentExperiences &&
    !currentExperience?.addons.length;
  const overlapStart = experienceOverlaps.some((overlap) => overlap.overlapStart);
  const overlapEnd = experienceOverlaps.some((overlap) => overlap.overlapEnd);

  const { control, trigger, reset, formState, handleSubmit, getValues } = useStartEndForm({
    defaultValues,
    editingLive: false,
    startDate: null,
    overlapStart: shouldLimitLiveExperiences && overlapStart,
    overlapEnd: shouldLimitLiveExperiences && overlapEnd,
  });

  const toggleEditable = (resetForm: boolean) => {
    if (editable && resetForm) {
      setEndDateTime(new Date(endTrigger.triggerAt));
      reset(defaultValues);
    }
    setEditable(!editable);
    trigger();
  };

  const handleReset = async () => {
    try {
      await resetExperience({ id: experienceId });
      displayToast({
        id: 'reset-experience-toast',
        title: t('resetToast.success.title'),
        body: t('resetToast.success.body'),
        type: 'success',
      });
    } catch {
      displayToast({
        id: 'reset-experience-toast',
        title: t('resetToast.error.title'),
        body: t('resetToast.error.body'),
        type: 'error',
      });
    }
    setShowResetModal(false);
  };

  const handleSave = async (data: StartEndFormInputData) => {
    try {
      if (data.startTiming === 'NOW') {
        const startParams = buildBaseParams(cohortId, data);
        await startCohort({ params: startParams });

        displayToast({
          id: 'start-experience-toast',
          title: t('startToast.success.title'),
          body: t('startToast.success.body'),
          type: 'success',
        });
        setShowGoLiveModal(false);
      } else {
        const schedulingParams = buildScheduleParams(cohortId, data);
        await scheduleCohort({ params: schedulingParams });

        displayToast({
          id: 'schedule-experience-toast',
          title: t('scheduleToast.success.title'),
          body: t('scheduleToast.success.body'),
          type: 'success',
        });
        setShowEditModal(false);
      }
      toggleEditable(false);
    } catch {
      displayToast({
        id: 'schedule-experience-toast',
        title: t('scheduleToast.error.title'),
        body: t('scheduleToast.error.body'),
        type: 'error',
      });
    }
  };

  const totalTime = useMemo(() => {
    if (!(startDateTime && endDateTime)) {
      return '';
    }
    const duration = intervalToDuration({ start: startDateTime, end: endDateTime });
    return abbreviateTimespan(
      formatDuration(duration, {
        format: ['years', 'months', 'days', 'hours', 'minutes'],
      }),
    );
  }, [startDateTime, endDateTime]);

  const handleChangeStart = useCallback(
    (val: Nullable<Date>) => {
      setStartDateTime(val);
    },
    [setStartDateTime],
  );

  const handleChangeEnd = useCallback(
    (val: Nullable<Date>) => {
      setEndDateTime(val);
      trigger();
    },
    [setEndDateTime, trigger],
  );

  return (
    <>
      <Card className="mt-4 p-5 w-full">
        <>
          <Headline type="secondary" size="sm" className="mb-6">
            {t('draftHeadline')}
          </Headline>
          <StartEndFormUI
            control={control}
            trigger={trigger}
            onChangeStart={handleChangeStart}
            onChangeEnd={handleChangeEnd}
            startDisabled={!editable}
            endDisabled={!editable}
            cohortState={CohortState.Scheduled}
            startDateTime={startDateTime}
            missionTriggers={missionTriggers}
            broadcastTriggers={broadcastTriggers}
            experienceTimezone={experienceTimezone}
          />
          <div className="border-t border-black-12 pt-6 mt-4">
            {startDateTime && endDateTime && (
              <Text className="mb-6">
                <Trans>
                  {t('scheduleInfo', {
                    startDateTime: format(startDateTime, "EEEE, LLLL d yyyy 'at' h:mm aa"),
                    endDateTime: format(endDateTime, "EEEE, LLLL d yyyy 'at' h:mm aa"),
                    timeZone: systemTimezoneAbbreviation,
                    totalTime: totalTime,
                  })}
                </Trans>
              </Text>
            )}
            {editable ? (
              <>
                {' '}
                {shouldLimitLiveExperiences ? (
                  <div className="mb-6">
                    <InlineBanner
                      i18nKey="freeLiveExperienceLimit"
                      showUpgradePlanAction
                      showUpgradeExperienceAction={workspace.type === WorkspaceType.Regular}
                    />
                  </div>
                ) : null}
                <div className="flex flex-row gap-x-6">
                  <Button
                    label={t('form.saveChanges')}
                    onClick={() =>
                      getValues('startTiming') === 'NOW'
                        ? setShowGoLiveModal(true)
                        : setShowEditModal(true)
                    }
                    disabled={!formState.isValid}
                  />
                  <Button label={t('form.cancel')} onClick={() => toggleEditable(true)} outlined />
                </div>
              </>
            ) : (
              <Button leftIcon="Edit" label="Edit" outlined onClick={() => toggleEditable(false)} />
            )}
          </div>
        </>
      </Card>
      <Card className="mt-4 p-5 w-full">
        <>
          <Headline type="secondary" size="xs">
            {t('resetHeadline')}
          </Headline>
          <Text className="my-6">{t('resetInfo')}</Text>
          <Button
            label={t('resetStartAndEndTimes')}
            leftIcon="Refresh"
            outlined
            onClick={() => setShowResetModal(true)}
          />
        </>
      </Card>
      <ResetConfirmationModal
        show={showResetModal}
        onSubmit={handleReset}
        onClose={() => setShowResetModal(false)}
      />
      <EditScheduledConfirmationModal
        show={showEditModal}
        onSubmit={handleSubmit(handleSave)}
        onClose={() => setShowEditModal(false)}
      />
      <StartConfirmationModal
        show={showGoLiveModal}
        onSubmit={handleSubmit(handleSave)}
        onClose={() => setShowGoLiveModal(false)}
        scheduled={false}
        startDateTime={startDateTime ? format(startDateTime, "EEEE, LLLL d yyyy 'at' h:mm aa") : ''}
        endDateTime={endDateTime ? format(endDateTime, "EEEE, LLLL d yyyy 'at' h:mm aa") : ''}
        timeZone={systemTimezoneAbbreviation ?? ''}
        totalTime={totalTime}
        disableConfirm={isLoading}
      />
    </>
  );
};

export default ScheduledForm;
