import { mergeClasses, Text } from '@goosechase/ui';
import { LabelledSetting } from 'components/labelled-setting.component';
import { BroadcastSendOption } from 'data/models';
import { useToggle } from 'hooks/use-toggle';
import { Control, Controller, useFormState, UseFormTrigger, useWatch } from 'react-hook-form';
import { useTranslate } from 'util/i18n';
import { BroadcastSendTimingDropdown } from './broadcast-send-timing-dropdown.component';
import { BroadcastFormInputData } from '../use-broadcast-form';
import { BeforeExperienceTimingFields } from './before-experience-timing-fields.component';
import { DuringExperienceTimingFields } from './during-experience-timing-fields.component';
import { AfterExperienceTimingFields } from './after-experience-timing-fields.component';
import { Nullable } from 'types/util';
import { format as formatDate } from 'date-fns';
import { BroadcastTriggerSummary } from './broadcast-trigger-summary.component';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';

export interface BroadcastSendTimingFieldProps {
  control: Control<BroadcastFormInputData>;
  isInFuture: boolean;
  anticipatedSendDateTime: Nullable<Date>;
  trigger: UseFormTrigger<BroadcastFormInputData>;
}

// eslint-disable-next-line complexity
export const BroadcastSendTimingField = ({
  control,
  isInFuture,
  anticipatedSendDateTime,
  trigger,
}: BroadcastSendTimingFieldProps) => {
  const { t } = useTranslate('broadcastForm.sendTiming');
  const { errors } = useFormState({ control });

  const sendTiming = useWatch({ control, name: `sendTiming` });
  const broadcastId = useWatch({ control, name: 'id' });

  const displaySummary = Boolean(broadcastId);
  const [triggerCollapsed, toggleTriggerCollapse] = useToggle(displaySummary);

  useEffect(() => {
    if (broadcastId) {
      trigger();
    }
  }, [trigger, broadcastId]);

  const hasErrors = Boolean(
    errors.beforeExperienceDuration?.message ||
      errors.beforeExperienceUnit?.message ||
      errors.relativeAnchor?.message ||
      errors.relativeDuration?.message ||
      errors.relativeUnit?.message ||
      errors.specificDay?.message ||
      errors.specificTime?.message ||
      errors.afterExperienceDuration?.message ||
      errors.afterExperienceUnit?.message ||
      errors.sendTiming?.message,
  );

  return (
    <div
      className={mergeClasses(
        'border border-black-24 rounded-md p-4 flex flex-col gap-2 relative',
        { 'border-vibrantRed': hasErrors },
      )}>
      {displaySummary ? (
        <button
          className="absolute top-0 right-0 p-4"
          type="button"
          onClick={() => toggleTriggerCollapse()}
          disabled={!triggerCollapsed && hasErrors}>
          <Text
            className={mergeClasses('text-vibrantBlue', {
              'text-black-24': !triggerCollapsed && hasErrors,
            })}>
            {t(triggerCollapsed ? 'edit' : 'collapse')}
          </Text>
        </button>
      ) : null}
      {triggerCollapsed && displaySummary ? (
        <>
          <BroadcastTriggerSummary control={control} isInFuture={isInFuture} />
        </>
      ) : (
        <>
          <Controller
            control={control}
            name="sendTiming"
            render={({ field: { onChange: onChangeSendTiming, value } }) => {
              return (
                <LabelledSetting label={t('title')} description={t('description')}>
                  <BroadcastSendTimingDropdown
                    value={value ?? BroadcastSendOption.Now}
                    onChange={(val) => {
                      trigger();
                      onChangeSendTiming(val);
                    }}
                  />
                </LabelledSetting>
              );
            }}
          />
          {sendTiming === BroadcastSendOption.BeforeExperience ? (
            <div className="flex gap-2">
              <BeforeExperienceTimingFields control={control} isInFuture={isInFuture} />
            </div>
          ) : null}

          {sendTiming === BroadcastSendOption.AtStartOfExperience && !isInFuture ? (
            <div className="bg-yellowOrange-10 border border-yellowOrange rounded-lg p-4">
              <Trans>{t('warnings.past')}</Trans>
            </div>
          ) : null}

          {sendTiming === BroadcastSendOption.DuringExperience ? (
            <div className="flex gap-2">
              <DuringExperienceTimingFields
                control={control}
                isInFuture={isInFuture}
                trigger={trigger}
              />
            </div>
          ) : null}

          {sendTiming === BroadcastSendOption.AtEndOfExperience && !isInFuture ? (
            <div className="bg-yellowOrange-10 border border-yellowOrange rounded-lg p-4">
              <Trans>{t('warnings.past')}</Trans>
            </div>
          ) : null}

          {sendTiming === BroadcastSendOption.AfterExperience ? (
            <div className="flex gap-2">
              <AfterExperienceTimingFields control={control} isInFuture={isInFuture} />
            </div>
          ) : null}

          {anticipatedSendDateTime ? (
            <Text size="sm" className="text-black-64 self-end">
              (On {formatDate(anticipatedSendDateTime, "EE, LLL d yyyy 'at' h:mm aa")})
            </Text>
          ) : null}
        </>
      )}
    </div>
  );
};
