import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useTranslate } from '../../util/i18n';
import { transformEmptyInputToNull } from 'util/form-util';

export type TeamBonusFormInputData =
  | {
      bonusId: string;
      note: string;
      value: string;
    }
  | {
      teamId: string;
      note: string;
      value: string;
    };

export type TeamBonusFormOutputData = TeamBonusFormInputData;

export const TEAM_BONUS_FORM_DEFAULT_VALUES: TeamBonusFormInputData = {
  teamId: '',
  bonusId: '',
  note: '',
  value: '',
};

export const useTeamBonusForm = (defaultValues = TEAM_BONUS_FORM_DEFAULT_VALUES) => {
  const { t } = useTranslate('teamBonusForm');

  const teamBonusBaseSchema = z.object({
    value: z.coerce
      .string()
      .min(1, { message: t('value.errors.required') ?? undefined })
      .transform((val, ctx) => {
        const parsed = parseInt(val);
        if (isNaN(parsed)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('value.errors.number') ?? undefined,
          });
          return z.NEVER;
        }
        return parsed;
      }),
    note: z.string().transform(transformEmptyInputToNull),
  });

  const teamBonusFormSchema = z
    .object({
      teamId: z.string().uuid(),
    })
    .merge(teamBonusBaseSchema)
    .or(
      z
        .object({
          bonusId: z.string().uuid(),
        })
        .merge(teamBonusBaseSchema),
    );

  return useForm<TeamBonusFormInputData, unknown, TeamBonusFormOutputData>({
    mode: 'onTouched',
    defaultValues,
    resolver: zodResolver(teamBonusFormSchema),
  });
};
