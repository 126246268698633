/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { HostedCheckoutPageFragmentFragmentDoc } from './hosted-checkout-page.fragment.generated';
import { rootApi } from 'data/api';
module.hot?.accept();
export type LoadAddonCheckoutMutationVariables = Types.Exact<{
  addonId: Types.Scalars['String']['input'];
}>;


export type LoadAddonCheckoutMutation = { __typename: 'Mutation', loadAddonCheckout: { __typename: 'HostedCheckoutPage', id: string, type: string, url: string, state: string, embed: boolean, created_at: number, expires_at: number, object: string, updated_at: number, resource_version: number } };


export const LoadAddonCheckoutDocument = `
    mutation LoadAddonCheckout($addonId: String!) {
  loadAddonCheckout(addonId: $addonId) {
    ...HostedCheckoutPageFragment
  }
}
    ${HostedCheckoutPageFragmentFragmentDoc}`;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    LoadAddonCheckout: build.mutation<LoadAddonCheckoutMutation, LoadAddonCheckoutMutationVariables>({
      query: (variables) => ({ document: LoadAddonCheckoutDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useLoadAddonCheckoutMutation } = injectedRtkApi;

