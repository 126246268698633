import i18next from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';

// TODO: Update i18n with multiple translation files located beside components
// https://app.asana.com/0/1203835673732701/1204138340212288/f

// eslint-disable-next-line
const en = require('constants/lang/en-US.json');

// Setup - must be called before we can use i18n.t or the useTranslation hook
export const initIntl = () => {
  i18next.use(initReactI18next).init({
    resources: {
      en,
    },
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      // This allows us to render things like forward slashes in interpolated text (/)
      escapeValue: false,
    },
  });
};

/**
 * A convenience wrapper hook to simplify passing a key prefix to the react-i18next provided useTranslation hook
 * @param prefix prefix to be applied to all string keys
 * @returns
 */
export const useTranslate = (prefix?: string) =>
  useTranslation('translation', { keyPrefix: prefix });

export type TranslateFn = (key: string) => string;
