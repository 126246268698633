import { Headline, Text, Toggle } from '@goosechase/ui';
import { MaxSizeInput } from 'components/max-size-input';
import { ExperienceFormDetailsQuery, useUpdateExperienceMutation } from 'data/experiences';
import { TeamCreationMode, TeamMode } from 'data/models';
import debounce from 'lodash.debounce';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import { useTranslate } from 'util/i18n';
import { TeamCreationModeInput } from './team-mode-input.component';
import {
  ParticipantOptionsData,
  useParticipantOptionsForm,
} from './use-participant-options-form.hook';

interface ParticipantsOptionsProps {
  experience: NonNullable<ExperienceFormDetailsQuery['experience']>;
  shouldDisableIndividualMode?: boolean;
}

const ParticipantsOptions = ({
  experience,
  shouldDisableIndividualMode,
}: ParticipantsOptionsProps) => {
  const { t } = useTranslate('pages.participants.participantsOptions');
  const [updateExperience] = useUpdateExperienceMutation();
  const { control, handleSubmit, watch } = useParticipantOptionsForm({
    allowParticipantsToCreateTeams: experience.teamCreationMode !== TeamCreationMode.None,
    studioDefaultTeamMaxSize: experience.studioDefaultTeamMaxSize,
    studioDefaultTeamMode: experience.studioDefaultTeamMode,
    teamCreationMode:
      experience.teamCreationMode !== TeamCreationMode.None
        ? experience.teamCreationMode
        : experience.studioDefaultTeamMode === TeamMode.Solo
        ? TeamCreationMode.Solo
        : TeamCreationMode.Team,
  });

  const onSubmit = async (values: ParticipantOptionsData) => {
    const params = {
      id: experience.id,
      teamCreationMode: values.allowParticipantsToCreateTeams
        ? values.teamCreationMode
        : TeamCreationMode.None,
      studioDefaultTeamMaxSize: values.studioDefaultTeamMaxSize,
      studioDefaultTeamMode:
        values.teamCreationMode === TeamCreationMode.Solo ? TeamMode.Solo : TeamMode.Team,
    };

    await updateExperience({
      params,
    });
  };

  // participant options form has no submit button, so we debounce the calls
  useEffect(() => {
    const subscription = watch(debounce(() => handleSubmit(onSubmit)(), 2000));
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, watch]);

  const teamCreationMode = watch('teamCreationMode');

  return (
    <form className="flex flex-col gap-6">
      <Headline type="secondary" size="sm">
        {t('title')}
      </Headline>
      <Text>{t('warning')}</Text>
      <div className="w-full h-px bg-black-12" />
      <div className="flex">
        <div className="flex flex-col flex-1 gap-1">
          <Headline type="secondary" size="xs">
            {t('allowParticipantsToCreateTeams.title')}
          </Headline>
          <Text>{t('allowParticipantsToCreateTeams.info')}</Text>
        </div>
        <div>
          <Controller
            name="allowParticipantsToCreateTeams"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Toggle
                name={name}
                onChange={onChange}
                checked={value}
                label={t('allowParticipantsToCreateTeams.title') ?? undefined}
              />
            )}
          />
        </div>
      </div>
      <div className="w-full h-px bg-black-12" />
      <div className="flex flex-col gap-6">
        <Headline type="secondary" size="xs">
          {t('teamCreationMode.label')}
        </Headline>
        <Controller
          control={control}
          name="teamCreationMode"
          render={({ field: { name, onChange, value } }) => (
            <TeamCreationModeInput
              name={name}
              onChange={onChange}
              value={value}
              shouldDisableIndividualMode={shouldDisableIndividualMode}
            />
          )}
        />
        <Controller
          control={control}
          name="studioDefaultTeamMode"
          render={({ field: { name, onChange, value } }) => (
            <input
              className="hidden"
              name={name}
              onChange={onChange}
              value={teamCreationMode !== TeamCreationMode.None ? teamCreationMode : value}
            />
          )}
        />
        {teamCreationMode === TeamCreationMode.Team ? (
          <Controller
            control={control}
            name="studioDefaultTeamMaxSize"
            render={({ field: { name, onChange, onBlur, value } }) => (
              <MaxSizeInput
                name={name}
                label={t('maxSize')}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />
        ) : (
          <Controller
            control={control}
            name="studioDefaultTeamMaxSize"
            render={({ field: { name } }) => <input className="hidden" name={name} value="1" />}
          />
        )}
      </div>
    </form>
  );
};

export default ParticipantsOptions;
