import { Label, RadioSelector, DatePicker, Text, TimeInput } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import { Controller, type UseFormTrigger, useWatch, type Control } from 'react-hook-form';
import { StartEndFormInputData } from './use-start-end-form';
import { CohortState, ExperienceTriggerTiming } from '../../data/models';
import { useEffect, useMemo } from 'react';
import { mergeDateAndTime, systemTimezoneAbbreviation } from 'util/time-util';
import { format as formatDate } from 'date-fns';

interface StartDateTimeProps {
  control: Control<StartEndFormInputData>;
  trigger: UseFormTrigger<StartEndFormInputData>;
  onChangeDateTime: (date: Date | null) => void;
  className?: string;
  disabled?: boolean;
  cohortState: CohortState;
}

const StartDateTime = ({
  control,
  trigger,
  onChangeDateTime,
  className,
  disabled = false,
  cohortState,
}: StartDateTimeProps) => {
  const { t } = useTranslate('pages.startEnd.form.start');

  const formWatch = useWatch({ control });

  const startOptions = [
    {
      value: 'NOW',
      label: t('options.startNow'),
    },
    {
      value: ExperienceTriggerTiming.Absolute,
      label: t('options.absolute'),
    },
  ];

  const startDateTime = useMemo(() => {
    if (formWatch.startTiming === 'NOW') {
      if (disabled && formWatch.startDate && formWatch.startTime) {
        return mergeDateAndTime(formWatch.startDate, formWatch.startTime);
      } else {
        return new Date();
      }
    } else if (formWatch.startTiming === 'ABSOLUTE' && formWatch.startDate && formWatch.startTime) {
      return mergeDateAndTime(formWatch.startDate, formWatch.startTime);
    } else {
      return null;
    }
  }, [formWatch.startTiming, formWatch.startDate, formWatch.startTime, disabled]);

  useEffect(() => {
    onChangeDateTime(startDateTime);
  }, [startDateTime, onChangeDateTime]);

  if (cohortState === CohortState.Active && disabled && startDateTime) {
    return (
      <div className={className}>
        <Label size="sm" className="block mb-2 uppercase">
          {t('label')}
        </Label>
        <Text className="text-black-48">
          {`${formatDate(
            startDateTime,
            "EEEE, LLLL d yyyy 'at' h:mm aa",
          )} (${systemTimezoneAbbreviation})`}
        </Text>
      </div>
    );
  }

  return (
    <div className={className}>
      <Controller
        control={control}
        name="startTiming"
        render={({ field: { onChange, value }, fieldState }) => (
          <>
            <Label size="sm" className="block mb-2 uppercase">
              {t('label')}
            </Label>
            <RadioSelector
              options={startOptions}
              name="Start"
              className="mb-3"
              onSelect={onChange}
              selectedValue={value}
              disabled={disabled}
              optionClassName="mb-3"
              errorMessage={fieldState.error?.message}
            />
          </>
        )}
      />
      {formWatch.startTiming === 'ABSOLUTE' && (
        <div className="flex flex-col desktop:flex-row gap-x-3 gap-y-3">
          <Controller
            control={control}
            name="startDate"
            render={({ field: { onChange, value }, fieldState: { isDirty, error } }) => (
              <DatePicker
                date={value}
                onChange={(val) => {
                  onChange(val);
                  trigger();
                }}
                isDirty={isDirty}
                error={error?.message ?? ''}
                disabled={disabled}
                disablePastDates
              />
            )}
          />
          <Controller
            control={control}
            name="startTime"
            render={({ field: { onChange, value }, fieldState: { isDirty, error } }) => (
              <TimeInput
                value={value}
                onChangeValue={(val) => {
                  onChange(val);
                  trigger();
                }}
                isDirty={isDirty}
                name="startTime"
                error={error?.message ?? ''}
                disabled={disabled}
                aria-label={t('startTime.ariaLabel') ?? 'Start time'}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export default StartDateTime;
