import { useCopySubmissionLink } from 'hooks/use-copy-submission-link.hook';
import { useDownloadSubmission } from 'hooks/use-download-submission.hook';

import { useToggleLikeSubmissionMutation } from '../../data/submissions';
import { displayToast } from '../../services/toast';
import { useTranslate } from '../../util/i18n';
import { SubmissionCardUI, type SubmissionCardUIProps } from './submission-card-ui.component';

export type SubmissionCardProps = {
  submissionId: string;
  submissionDownloadSrc?: string;
  onClick: (submissionId: string) => void;
  onBonus: (submissionId: string) => void;
} & Omit<SubmissionCardUIProps, 'onClick' | 'onClickLike' | 'onDownload' | 'onCopy' | 'onBonus'>;

export const SubmissionCard = ({
  submissionId,
  submissionDownloadSrc,
  onClick,
  onBonus,
  ...props
}: SubmissionCardProps) => {
  const { t } = useTranslate('likeButton');
  const copySubmissionLink = useCopySubmissionLink();
  const [toggleLike] = useToggleLikeSubmissionMutation();
  const downloadSubmission = useDownloadSubmission({
    missionType: props.missionType,
    mediaSrc: submissionDownloadSrc,
    filename: submissionId,
  });

  const handleClick = () => {
    onClick(submissionId);
  };

  const handleClickLike = async () => {
    try {
      await toggleLike({ id: submissionId }).unwrap();
    } catch (e) {
      const toastKey = props.isLiked ? 'removeLikeError' : 'likeError';
      displayToast({
        id: submissionId,
        title: t(`toasts.${toastKey}.title`),
        type: 'error',
        body: t(`toasts.${toastKey}.body`),
      });
    }
  };

  const handleCopy = () => {
    copySubmissionLink(submissionId);
  };

  const handleBonus = () => {
    onBonus(submissionId);
  };

  return (
    <SubmissionCardUI
      {...props}
      onClick={handleClick}
      onClickLike={handleClickLike}
      onDownload={downloadSubmission}
      onCopy={handleCopy}
      onBonus={handleBonus}
    />
  );
};
