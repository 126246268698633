/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, run the Core API and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  Void: { input: void; output: void; }
};

export type Account = {
  __typename: 'Account';
  email: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type AddSubmissionBonusInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  submissionId: Scalars['ID']['input'];
  value: Scalars['Int']['input'];
};

export type AddTeamBonusInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['ID']['input'];
  value: Scalars['Int']['input'];
};

export type AddToExperienceParams = {
  assetId: Scalars['ID']['input'];
  experienceTemplateId: Scalars['ID']['input'];
  role: ExperienceAssetRole;
};

export type Addon = {
  __typename: 'Addon';
  chargebeeSku: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  maxTeamsPerExperience: Maybe<Scalars['Int']['output']>;
  priceUsdCents: Scalars['Int']['output'];
};

export type Asset = {
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uploaded: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
};

export type AuthPayload = {
  __typename: 'AuthPayload';
  intercomHmac: Maybe<Scalars['String']['output']>;
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  /** @deprecated No longer supported */
  workspaceId: Maybe<Scalars['String']['output']>;
};

export type Billing = {
  __typename: 'Billing';
  card: Card;
  nextBillingAt: Scalars['DateTime']['output'];
  priceUsdCents: Scalars['Int']['output'];
};

export type Broadcast = {
  __typename: 'Broadcast';
  cohortId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  recipientType: BroadcastRecipientType;
  recipients: Array<BroadcastRecipient>;
  state: BroadcastState;
  trigger: Maybe<BroadcastTrigger>;
  type: BroadcastType;
  updatedAt: Scalars['DateTime']['output'];
};

export type BroadcastRecipient = {
  __typename: 'BroadcastRecipient';
  broadcastId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  team: Team;
};

export enum BroadcastRecipientType {
  All = 'ALL',
  Team = 'TEAM'
}

export enum BroadcastState {
  Deleted = 'DELETED',
  Invalid = 'INVALID',
  Scheduled = 'SCHEDULED',
  Sent = 'SENT'
}

export enum BroadcastTaskType {
  SendBroadcast = 'SEND_BROADCAST'
}

export type BroadcastTrigger = {
  __typename: 'BroadcastTrigger';
  broadcastId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  experienceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  relativeAnchor: Maybe<TriggerTimeAnchor>;
  relativeDuration: Maybe<Scalars['Int']['output']>;
  relativeUnit: Maybe<TriggerTimeUnit>;
  specificDay: Maybe<Scalars['Int']['output']>;
  specificTime: Maybe<Scalars['String']['output']>;
  taskType: BroadcastTaskType;
  timing: BroadcastTriggerTiming;
  updatedAt: Scalars['DateTime']['output'];
};

export enum BroadcastTriggerTiming {
  Now = 'NOW',
  Relative = 'RELATIVE',
  Specific = 'SPECIFIC'
}

export enum BroadcastType {
  Immediate = 'IMMEDIATE',
  Scheduled = 'SCHEDULED'
}

export type CameraMission = Mission & {
  __typename: 'CameraMission';
  allowCameraRollUploads: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  draft: Scalars['Boolean']['output'];
  experience: Maybe<Experience>;
  expireAt: Maybe<Scalars['DateTime']['output']>;
  expireTrigger: Maybe<MissionTrigger>;
  feedVisibility: FeedVisibility;
  id: Scalars['ID']['output'];
  image: Maybe<Scalars['String']['output']>;
  link: Maybe<Scalars['String']['output']>;
  mediaType: MediaType;
  missionType: MissionType;
  numSubmissions: Scalars['Int']['output'];
  points: Scalars['Int']['output'];
  position: Maybe<Scalars['Int']['output']>;
  releaseAt: Maybe<Scalars['DateTime']['output']>;
  releaseTrigger: Maybe<MissionTrigger>;
  status: Maybe<MissionStatus>;
  submissions: Array<Submission>;
  updatedAt: Scalars['DateTime']['output'];
};


export type CameraMissionExpireAtArgs = {
  cohortId: Scalars['ID']['input'];
};


export type CameraMissionNumSubmissionsArgs = {
  cohortId: InputMaybe<Scalars['ID']['input']>;
};


export type CameraMissionReleaseAtArgs = {
  cohortId: Scalars['ID']['input'];
};


export type CameraMissionStatusArgs = {
  cohortId: Scalars['ID']['input'];
};


export type CameraMissionSubmissionsArgs = {
  cohortId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
};

export type CameraSubmission = Submission & {
  __typename: 'CameraSubmission';
  assets: Array<SubmissionAsset>;
  bonusPoints: Scalars['Int']['output'];
  bonuses: Array<SubmissionBonus>;
  caption: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creator: Participant;
  id: Scalars['ID']['output'];
  /** Always false if not authenticated */
  liked: Scalars['Boolean']['output'];
  mission: CameraMission;
  numLikes: Scalars['Int']['output'];
  publicUrl: Scalars['String']['output'];
  reason: Maybe<Scalars['String']['output']>;
  state: SubmissionState;
  team: Team;
  updatedAt: Scalars['DateTime']['output'];
};

export type Card = {
  __typename: 'Card';
  last4: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ChangePasswordInput = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type Cohort = {
  __typename: 'Cohort';
  broadcasts: Array<Broadcast>;
  endDate: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  individualModeEnabled: Scalars['Boolean']['output'];
  maxParticipantsPerExperience: Maybe<Scalars['Int']['output']>;
  maxTeamsPerExperience: Maybe<Scalars['Int']['output']>;
  numSubmissions: Scalars['Int']['output'];
  numberOfParticipants: Scalars['Int']['output'];
  numberOfTeams: Scalars['Int']['output'];
  preview: CohortPreview;
  startDate: Maybe<Scalars['DateTime']['output']>;
  statistics: Maybe<CohortStatistics>;
  status: CohortState;
  submissions: Array<Submission>;
  teams: Array<Team>;
  triggers: Array<ExperienceTrigger>;
};

export type CohortPreview = {
  __typename: 'CohortPreview';
  numberOfTeams: Scalars['Int']['output'];
  teams: Array<TeamPreview>;
};

export enum CohortState {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Ended = 'ENDED',
  Scheduled = 'SCHEDULED'
}

export type CohortStatistics = {
  __typename: 'CohortStatistics';
  activeTeams: Maybe<Scalars['Int']['output']>;
  averageMissionCompletion: Maybe<Scalars['String']['output']>;
  totalSubmissions: Maybe<Scalars['Int']['output']>;
  totalTeams: Maybe<Scalars['Int']['output']>;
};

export type Connection = {
  edges: Array<Edge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ContactUsParams = {
  email: Scalars['String']['input'];
  intercomDistinctId?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  mixpanelDistinctId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  segmentDistinctId?: InputMaybe<Scalars['String']['input']>;
  sourceSite?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAndJoinTeamParams = {
  assetId?: InputMaybe<Scalars['ID']['input']>;
  cohortId: Scalars['ID']['input'];
  experiencePassword?: InputMaybe<Scalars['String']['input']>;
  participantEmail?: InputMaybe<Scalars['String']['input']>;
  participantName?: InputMaybe<Scalars['String']['input']>;
  passcode?: InputMaybe<Scalars['String']['input']>;
  teamName: Scalars['String']['input'];
};

export type CreateAssetParams = {
  fileName: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateAssetPayload = {
  __typename: 'CreateAssetPayload';
  assetId: Scalars['ID']['output'];
  presignedUrl: Scalars['String']['output'];
};

export type CreateCameraMissionInput = {
  allowCameraRollUploads: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  draft: Scalars['Boolean']['input'];
  experienceId: Scalars['String']['input'];
  expireTrigger?: InputMaybe<MissionTriggerInput>;
  feedVisibility: FeedVisibility;
  imageAssetId?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  mediaType: MediaType;
  points: Scalars['Int']['input'];
  releaseTrigger?: InputMaybe<MissionTriggerInput>;
};

export type CreateEducatorProfileInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  position: EducatorPosition;
  schoolDistrict: Scalars['String']['input'];
  schoolLevel?: InputMaybe<SchoolLevel>;
  schoolName: Scalars['String']['input'];
};

export type CreateExperienceInvitationParams = {
  email: Scalars['String']['input'];
  experienceId: Scalars['ID']['input'];
};

export type CreateExperienceParams = {
  defaultVisible?: InputMaybe<Scalars['Boolean']['input']>;
  description: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  photoId?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type CreateGpsMissionInput = {
  description: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  draft: Scalars['Boolean']['input'];
  experienceId: Scalars['String']['input'];
  expireTrigger?: InputMaybe<MissionTriggerInput>;
  feedVisibility: FeedVisibility;
  imageAssetId?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['String']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  longitude: Scalars['String']['input'];
  points: Scalars['Int']['input'];
  radius: Scalars['Int']['input'];
  releaseTrigger?: InputMaybe<MissionTriggerInput>;
};

export type CreateSubmissionArchiveInput = {
  cohortId: Scalars['ID']['input'];
  grouping: SubmissionArchiveGroupingInput;
};

export type CreateTeamParams = {
  assetId?: InputMaybe<Scalars['ID']['input']>;
  cohortId: Scalars['ID']['input'];
  displayName: Scalars['String']['input'];
  maxSize?: InputMaybe<Scalars['Int']['input']>;
  mode: TeamMode;
  passcode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTextMissionInput = {
  allowApproximateAnswers: Scalars['Boolean']['input'];
  answers: Array<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  draft: Scalars['Boolean']['input'];
  experienceId: Scalars['String']['input'];
  expireTrigger?: InputMaybe<MissionTriggerInput>;
  feedVisibility: FeedVisibility;
  imageAssetId?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  points: Scalars['Int']['input'];
  releaseTrigger?: InputMaybe<MissionTriggerInput>;
};

export type CreateWorkspaceInvitationParams = {
  email: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type DeleteCheck = {
  __typename: 'DeleteCheck';
  deletionAllowed: Scalars['Boolean']['output'];
  message: Maybe<Scalars['String']['output']>;
};

export type DeleteSubmissionInput = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteWorkspaceMemberParams = {
  workspaceId: Scalars['ID']['input'];
  workspaceMemberUserId: Scalars['ID']['input'];
};

export type Device = {
  __typename: 'Device';
  fcmToken: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type Edge = {
  cursor: Scalars['String']['output'];
};

export enum EducatorPosition {
  Administrator = 'ADMINISTRATOR',
  InstructionalTechnologist = 'INSTRUCTIONAL_TECHNOLOGIST',
  Other = 'OTHER',
  Principal = 'PRINCIPAL',
  Teacher = 'TEACHER'
}

export type EducatorProfile = {
  __typename: 'EducatorProfile';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  position: EducatorPosition;
  schoolDistrict: Scalars['String']['output'];
  schoolLevel: Maybe<SchoolLevel>;
  schoolName: Scalars['String']['output'];
};

export type Experience = {
  __typename: 'Experience';
  addons: Array<Addon>;
  assets: Array<ExperienceAsset>;
  cohorts: Array<Cohort>;
  createdAt: Scalars['DateTime']['output'];
  defaultVisible: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  /** @deprecated Use participantEmailRequired instead */
  emailSharingEnabled: Scalars['Boolean']['output'];
  endDate: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  individualModeEnabled: Scalars['Boolean']['output'];
  isPasswordProtected: Scalars['Boolean']['output'];
  joinCode: Scalars['String']['output'];
  latitude: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
  longitude: Maybe<Scalars['String']['output']>;
  maxParticipantsPerExperience: Maybe<Scalars['Int']['output']>;
  maxTeamsPerExperience: Maybe<Scalars['Int']['output']>;
  missionOrder: MissionOrder;
  missions: Array<Mission>;
  myExperienceManager: Maybe<ExperienceManager>;
  myParticipant: Maybe<Participant>;
  numMissions: Scalars['Int']['output'];
  participantEmailRequired: Scalars['Boolean']['output'];
  password: Maybe<Scalars['String']['output']>;
  photoUrl: Maybe<Scalars['String']['output']>;
  shareLink: Maybe<Scalars['String']['output']>;
  splashScreenPhotoId: Maybe<Scalars['ID']['output']>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  status: CohortState;
  studioDefaultTeamMaxSize: Maybe<Scalars['Int']['output']>;
  studioDefaultTeamMode: TeamMode;
  teamCreationMode: TeamCreationMode;
  termsOfServiceUrl: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
  triggers: Array<ExperienceTrigger>;
  updatedAt: Scalars['DateTime']['output'];
  workspace: Workspace;
};

export type ExperienceAsset = Asset & {
  __typename: 'ExperienceAsset';
  assetId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  experienceId: Scalars['ID']['output'];
  extension: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  role: ExperienceAssetRole;
  updatedAt: Scalars['DateTime']['output'];
  uploaded: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
  workspaceId: Scalars['ID']['output'];
};

export enum ExperienceAssetRole {
  Photo = 'PHOTO',
  SplashScreenPhoto = 'SPLASH_SCREEN_PHOTO'
}

export type ExperienceInvitation = {
  __typename: 'ExperienceInvitation';
  email: Scalars['String']['output'];
  experience: PublicExperience;
  id: Scalars['ID']['output'];
  lastSentAt: Scalars['DateTime']['output'];
  roleId: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type ExperienceManager = {
  __typename: 'ExperienceManager';
  createdAt: Scalars['DateTime']['output'];
  role: ExperienceManagerRole;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type ExperienceManagerRole = {
  __typename: 'ExperienceManagerRole';
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export enum ExperienceTaskType {
  EndExperience = 'END_EXPERIENCE',
  StartExperience = 'START_EXPERIENCE'
}

export type ExperienceTrigger = {
  __typename: 'ExperienceTrigger';
  cohortId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  relativeAnchor: Maybe<TriggerTimeAnchorV1>;
  relativeDuration: Maybe<Scalars['Int']['output']>;
  relativeUnit: Maybe<TriggerTimeUnit>;
  taskType: ExperienceTaskType;
  timing: ExperienceTriggerTiming;
  triggerAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum ExperienceTriggerTiming {
  Absolute = 'ABSOLUTE',
  Relative = 'RELATIVE'
}

export enum FeedVisibility {
  AlwaysPrivate = 'ALWAYS_PRIVATE',
  AlwaysPublic = 'ALWAYS_PUBLIC',
  PublicAtExperienceEnd = 'PUBLIC_AT_EXPERIENCE_END'
}

export type GetTemplateLibraryExperiencesInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GpsMission = Mission & {
  __typename: 'GpsMission';
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  draft: Scalars['Boolean']['output'];
  experience: Maybe<Experience>;
  expireAt: Maybe<Scalars['DateTime']['output']>;
  expireTrigger: Maybe<MissionTrigger>;
  feedVisibility: FeedVisibility;
  id: Scalars['ID']['output'];
  image: Maybe<Scalars['String']['output']>;
  latitude: Scalars['String']['output'];
  link: Maybe<Scalars['String']['output']>;
  locationName: Maybe<Scalars['String']['output']>;
  longitude: Scalars['String']['output'];
  missionType: MissionType;
  numSubmissions: Scalars['Int']['output'];
  points: Scalars['Int']['output'];
  position: Maybe<Scalars['Int']['output']>;
  radius: Scalars['Int']['output'];
  releaseAt: Maybe<Scalars['DateTime']['output']>;
  releaseTrigger: Maybe<MissionTrigger>;
  status: Maybe<MissionStatus>;
  submissions: Array<Submission>;
  updatedAt: Scalars['DateTime']['output'];
};


export type GpsMissionExpireAtArgs = {
  cohortId: Scalars['ID']['input'];
};


export type GpsMissionNumSubmissionsArgs = {
  cohortId: InputMaybe<Scalars['ID']['input']>;
};


export type GpsMissionReleaseAtArgs = {
  cohortId: Scalars['ID']['input'];
};


export type GpsMissionStatusArgs = {
  cohortId: Scalars['ID']['input'];
};


export type GpsMissionSubmissionsArgs = {
  cohortId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
};

export type GpsSubmission = Submission & {
  __typename: 'GpsSubmission';
  bonusPoints: Scalars['Int']['output'];
  bonuses: Array<SubmissionBonus>;
  caption: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creator: Participant;
  id: Scalars['ID']['output'];
  latitude: Scalars['String']['output'];
  /** Always false if not authenticated */
  liked: Scalars['Boolean']['output'];
  longitude: Scalars['String']['output'];
  mission: GpsMission;
  numLikes: Scalars['Int']['output'];
  publicUrl: Scalars['String']['output'];
  reason: Maybe<Scalars['String']['output']>;
  state: SubmissionState;
  team: Team;
  updatedAt: Scalars['DateTime']['output'];
};

export type HostedCheckoutPage = {
  __typename: 'HostedCheckoutPage';
  created_at: Scalars['Int']['output'];
  embed: Scalars['Boolean']['output'];
  expires_at: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  object: Scalars['String']['output'];
  resource_version: Scalars['Float']['output'];
  state: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updated_at: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type ImpersonationAuthResponse = {
  __typename: 'ImpersonationAuthResponse';
  accessToken: Scalars['String']['output'];
};

export type IsTeamNameAvailableParams = {
  cohortId: Scalars['ID']['input'];
  displayName: Scalars['String']['input'];
};

export type JoinTeamParams = {
  participantEmail?: InputMaybe<Scalars['String']['input']>;
  participantName?: InputMaybe<Scalars['String']['input']>;
  passcode?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['ID']['input'];
};

export enum MediaType {
  Photo = 'PHOTO',
  PhotoAndVideo = 'PHOTO_AND_VIDEO',
  Video = 'VIDEO'
}

export type Mission = {
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  draft: Scalars['Boolean']['output'];
  experience: Maybe<Experience>;
  expireAt: Maybe<Scalars['DateTime']['output']>;
  expireTrigger: Maybe<MissionTrigger>;
  feedVisibility: FeedVisibility;
  id: Scalars['ID']['output'];
  image: Maybe<Scalars['String']['output']>;
  link: Maybe<Scalars['String']['output']>;
  missionType: MissionType;
  numSubmissions: Scalars['Int']['output'];
  points: Scalars['Int']['output'];
  position: Maybe<Scalars['Int']['output']>;
  releaseAt: Maybe<Scalars['DateTime']['output']>;
  releaseTrigger: Maybe<MissionTrigger>;
  status: Maybe<MissionStatus>;
  submissions: Array<Submission>;
  updatedAt: Scalars['DateTime']['output'];
};


export type MissionExpireAtArgs = {
  cohortId: Scalars['ID']['input'];
};


export type MissionNumSubmissionsArgs = {
  cohortId: InputMaybe<Scalars['ID']['input']>;
};


export type MissionReleaseAtArgs = {
  cohortId: Scalars['ID']['input'];
};


export type MissionStatusArgs = {
  cohortId: Scalars['ID']['input'];
};


export type MissionSubmissionsArgs = {
  cohortId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
};

export enum MissionOrder {
  Custom = 'CUSTOM',
  Name = 'NAME',
  Points = 'POINTS',
  Random = 'RANDOM'
}

export enum MissionStatus {
  Available = 'AVAILABLE',
  Expired = 'EXPIRED',
  Hidden = 'HIDDEN'
}

export type MissionSubmissionCount = {
  __typename: 'MissionSubmissionCount';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  submissionCount: Scalars['Int']['output'];
};

export enum MissionTaskType {
  ExpireMission = 'EXPIRE_MISSION',
  HideMission = 'HIDE_MISSION',
  ReleaseMission = 'RELEASE_MISSION'
}

export type MissionTrigger = {
  __typename: 'MissionTrigger';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  missionId: Scalars['ID']['output'];
  relativeAnchor: Maybe<TriggerTimeAnchorV1>;
  relativeDuration: Maybe<Scalars['Int']['output']>;
  relativeUnit: Maybe<TriggerTimeUnit>;
  specificDay: Maybe<Scalars['Int']['output']>;
  specificTime: Maybe<Scalars['String']['output']>;
  taskType: MissionTaskType;
  timing: MissionTriggerTiming;
  updatedAt: Scalars['DateTime']['output'];
};

export type MissionTriggerInput = {
  relativeAnchor?: InputMaybe<TriggerTimeAnchorV1>;
  relativeDuration?: InputMaybe<Scalars['Int']['input']>;
  relativeUnit?: InputMaybe<TriggerTimeUnit>;
  specificDay?: InputMaybe<Scalars['Int']['input']>;
  specificTime?: InputMaybe<Scalars['String']['input']>;
  timing: MissionTriggerTiming;
};

export enum MissionTriggerTiming {
  Relative = 'RELATIVE',
  Specific = 'SPECIFIC'
}

export enum MissionType {
  Camera = 'CAMERA',
  Gps = 'GPS',
  Text = 'TEXT'
}

export type Mutation = {
  __typename: 'Mutation';
  acceptExperienceInvitation: ExperienceInvitation;
  acceptWorkspaceInvitation: WorkspaceInvitation;
  addAssetToExperience: ExperienceAsset;
  addMissionFromMissionLibrary: Mission;
  addSubmissionBonus: SubmissionBonus;
  addTeamBonus: TeamBonus;
  applyAddonToExperience: Experience;
  /** @deprecated No longer supported */
  applyPlanToWorkspace: Workspace;
  changePassword: Account;
  claimImpersonationSession: Maybe<ImpersonationAuthResponse>;
  completePlanCheckout: Workspace;
  contactUs: Maybe<Scalars['Boolean']['output']>;
  copySharedExperience: Experience;
  /**
   * Possible Errors:
   * * INVALID_INPUT
   * * NOT_ALLOWED
   * * EXPERIENCE_TEAM_CAPACITY_REACHED
   * * TEAM_ALREADY_EXISTS
   * * COHORT_DOES_NOT_EXIST
   * * EXPERIENCE_DOES_NOT_EXIST
   * * USER_ALREADY_JOINED
   */
  createAndJoinTeam: Team;
  createAsset: CreateAssetPayload;
  createCameraMission: CameraMission;
  createEducatorProfile: EducatorProfile;
  createExperience: Experience;
  createExperienceInvitation: ExperienceInvitation;
  createGpsMission: GpsMission;
  createSubmissionArchive: SubmissionArchive;
  /**
   * Possible Error codes:
   * * INVALID_INPUT
   * * NOT_ALLOWED
   * * TEAM_ALREADY_EXISTS
   * * COHORT_DOES_NOT_EXIST
   * * EXPERIENCE_DOES_NOT_EXIST
   */
  createTeam: Team;
  createTextMission: TextMission;
  createWorkspace: Workspace;
  createWorkspaceInvitation: WorkspaceInvitation;
  deleteAccount: Account;
  /**
   * Possible Errors:
   * * NotFoundError
   * * NotAllowedError
   * * UnhandledError
   */
  deleteBroadcast: Broadcast;
  deleteExperience: Experience;
  deleteExperienceInvitation: ExperienceInvitation;
  deleteExperienceManager: ExperienceManager;
  deleteMission: Mission;
  deleteSavedMission: Mission;
  deleteSubmission: Submission;
  deleteSubmissionBonus: SubmissionBonus;
  deleteTeam: Team;
  deleteTeamBonus: TeamBonus;
  deleteWorkspaceInvitation: WorkspaceInvitation;
  deleteWorkspaceMember: WorkspaceMember;
  duplicateExperience: Experience;
  endCohort: Cohort;
  /**
   * Possible Errors:
   * * INVALID_INPUT
   * * INTERNAL_ERROR
   */
  forgotPassword: Maybe<Scalars['Void']['output']>;
  getBillingDetailsRedirect: Scalars['String']['output'];
  /**
   * Possible Errors:
   * * INVALID_INPUT
   * * TEAM_DOES_NOT_EXIST
   * * TEAM_CAPACITY_REACHED
   * * INVALID_TEAM_PASSCODE
   * * USER_ALREADY_JOINED
   * * NOT_ALLOWED
   */
  joinTeam: Participant;
  leaveExperienceAsManager: ExperienceManager;
  /**
   * Possible Errors:
   * * INVALID_INPUT
   * * NOT_ALLOWED
   * * PARTICIPANT_DOES_NOT_EXIST
   * * TEAM_DOES_NOT_EXIST
   */
  leaveTeam: Participant;
  loadAddonCheckout: HostedCheckoutPage;
  /** @deprecated No longer supported */
  loadPlanCheckout: HostedCheckoutPage;
  /**
   * Possible Error codes:
   * * FORBIDDEN
   */
  refresh: RefreshPayload;
  /**
   * Possible Error codes:
   * * INVALID_INPUT
   * * EMAIL_IN_USE
   */
  register: AuthPayload;
  registerDevice: Device;
  requestQuote: Maybe<Scalars['Boolean']['output']>;
  resendExperienceInvitation: ExperienceInvitation;
  resetExperience: Cohort;
  /**
   * Possible Errors:
   * * INVALID_INPUT
   * * NOT_FOUND
   * * REQUEST_EXPIRED
   * * INTERNAL_ERROR
   */
  resetPassword: Maybe<Scalars['Void']['output']>;
  saveMission: Mission;
  /**
   * Possible Errors:
   * * NotFoundError
   * * NotAllowedError
   * * UnhandledError
   * * LogicError - ?
   */
  scheduleBroadcast: Broadcast;
  /**
   * Possible Errors:
   * * NotFoundError
   * * NotAllowedError
   * * UnhandledError
   * * LogicError - ?
   */
  scheduleCohort: Array<ExperienceTrigger>;
  /**
   * Possible Errors:
   * * NotFoundError
   * * NotAllowedError
   * * UnhandledError
   * * LogicError - ?
   */
  sendBroadcast: Broadcast;
  setMyUseCases: Array<UseCase>;
  shareExperience: Experience;
  /**
   * Possible Error codes:
   * * INVALID_INPUT
   * * NOT_ALLOWED
   * * INTERNAL_ERROR
   */
  signIn: AuthPayload;
  /**
   * Possible Errors:
   * * UNAUTHORIZED
   */
  signOut: Maybe<Scalars['Void']['output']>;
  /**
   * Possible Errors:
   * * NotFoundError
   * * NotAllowedError
   * * UnhandledError
   * * LogicError - ?
   */
  startCohortAndScheduleEnd: Cohort;
  startPlanCheckout: HostedCheckoutPage;
  toggleLikeSubmission: Submission;
  unregisterDevice: Device;
  unshareExperience: Experience;
  updateCameraMission: CameraMission;
  /**
   * Possible Errors:
   * * NotFoundError
   * * NotAllowedError
   * * UnhandledError
   * * LogicError - ?
   */
  updateCohortEnd: Cohort;
  updateExperience: Experience;
  updateGpsMission: GpsMission;
  updateMissionCustomOrderPosition: Array<Mission>;
  /**
   * Possible Errors:
   * * NotFoundError
   * * NotAllowedError
   * * UnhandledError
   */
  updateScheduledBroadcast: Broadcast;
  updateSubmissionBonus: SubmissionBonus;
  updateTeam: Team;
  updateTeamBonus: TeamBonus;
  updateTextMission: TextMission;
  updateUserProfile: UserProfile;
  updateWorkspace: Workspace;
  updateWorkspaceInvitation: WorkspaceInvitation;
  updateWorkspaceMember: WorkspaceMember;
  validateExperiencePassword: PasswordValidationResponse;
};


export type MutationAcceptExperienceInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};


export type MutationAcceptWorkspaceInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};


export type MutationAddAssetToExperienceArgs = {
  params: AddToExperienceParams;
};


export type MutationAddMissionFromMissionLibraryArgs = {
  experienceId: Scalars['ID']['input'];
  missionId: Scalars['ID']['input'];
};


export type MutationAddSubmissionBonusArgs = {
  params: AddSubmissionBonusInput;
};


export type MutationAddTeamBonusArgs = {
  params: AddTeamBonusInput;
};


export type MutationApplyAddonToExperienceArgs = {
  addonId: Scalars['String']['input'];
  checkoutId: Scalars['String']['input'];
  experienceId: Scalars['ID']['input'];
};


export type MutationApplyPlanToWorkspaceArgs = {
  checkoutId: InputMaybe<Scalars['String']['input']>;
  planId: InputMaybe<Scalars['String']['input']>;
};


export type MutationChangePasswordArgs = {
  params: InputMaybe<ChangePasswordInput>;
};


export type MutationClaimImpersonationSessionArgs = {
  sessionId: Scalars['ID']['input'];
};


export type MutationCompletePlanCheckoutArgs = {
  checkoutId: Scalars['String']['input'];
};


export type MutationContactUsArgs = {
  params: ContactUsParams;
};


export type MutationCopySharedExperienceArgs = {
  experienceShareId: Scalars['ID']['input'];
};


export type MutationCreateAndJoinTeamArgs = {
  params: CreateAndJoinTeamParams;
};


export type MutationCreateAssetArgs = {
  params: CreateAssetParams;
};


export type MutationCreateCameraMissionArgs = {
  params: CreateCameraMissionInput;
};


export type MutationCreateEducatorProfileArgs = {
  params: InputMaybe<CreateEducatorProfileInput>;
};


export type MutationCreateExperienceArgs = {
  params: CreateExperienceParams;
};


export type MutationCreateExperienceInvitationArgs = {
  params: CreateExperienceInvitationParams;
};


export type MutationCreateGpsMissionArgs = {
  params: CreateGpsMissionInput;
};


export type MutationCreateSubmissionArchiveArgs = {
  params: CreateSubmissionArchiveInput;
};


export type MutationCreateTeamArgs = {
  params: CreateTeamParams;
};


export type MutationCreateTextMissionArgs = {
  params: CreateTextMissionInput;
};


export type MutationCreateWorkspaceArgs = {
  displayName: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<WorkspaceType>;
};


export type MutationCreateWorkspaceInvitationArgs = {
  params: CreateWorkspaceInvitationParams;
};


export type MutationDeleteBroadcastArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteExperienceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteExperienceInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};


export type MutationDeleteExperienceManagerArgs = {
  experienceId: Scalars['ID']['input'];
  experienceManagerUserId: Scalars['ID']['input'];
};


export type MutationDeleteMissionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSavedMissionArgs = {
  missionId: Scalars['ID']['input'];
};


export type MutationDeleteSubmissionArgs = {
  params: DeleteSubmissionInput;
};


export type MutationDeleteSubmissionBonusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTeamArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTeamBonusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteWorkspaceInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};


export type MutationDeleteWorkspaceMemberArgs = {
  params: DeleteWorkspaceMemberParams;
};


export type MutationDuplicateExperienceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEndCohortArgs = {
  id: Scalars['ID']['input'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationGetBillingDetailsRedirectArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type MutationJoinTeamArgs = {
  params: JoinTeamParams;
};


export type MutationLeaveExperienceAsManagerArgs = {
  experienceId: Scalars['ID']['input'];
};


export type MutationLeaveTeamArgs = {
  teamId: Scalars['ID']['input'];
};


export type MutationLoadAddonCheckoutArgs = {
  addonId: Scalars['String']['input'];
};


export type MutationLoadPlanCheckoutArgs = {
  planId: Scalars['String']['input'];
};


export type MutationRefreshArgs = {
  refreshToken: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegisterArgs = {
  params: RegisterParams;
};


export type MutationRegisterDeviceArgs = {
  fcmToken: Scalars['String']['input'];
};


export type MutationRequestQuoteArgs = {
  params: RequestQuoteParams;
};


export type MutationResendExperienceInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};


export type MutationResetExperienceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  passwordResetId: Scalars['String']['input'];
};


export type MutationSaveMissionArgs = {
  missionId: Scalars['ID']['input'];
};


export type MutationScheduleBroadcastArgs = {
  params: ScheduleBroadcastParams;
};


export type MutationScheduleCohortArgs = {
  params: ScheduleCohortParams;
};


export type MutationSendBroadcastArgs = {
  params: SendBroadcastParams;
};


export type MutationSetMyUseCasesArgs = {
  useCases: Array<Scalars['String']['input']>;
};


export type MutationShareExperienceArgs = {
  experienceId: Scalars['ID']['input'];
};


export type MutationSignInArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationStartCohortAndScheduleEndArgs = {
  params: StartCohortParams;
};


export type MutationStartPlanCheckoutArgs = {
  planId: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};


export type MutationToggleLikeSubmissionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnregisterDeviceArgs = {
  fcmToken: Scalars['String']['input'];
};


export type MutationUnshareExperienceArgs = {
  experienceId: Scalars['ID']['input'];
};


export type MutationUpdateCameraMissionArgs = {
  params: UpdateCameraMissionInput;
};


export type MutationUpdateCohortEndArgs = {
  params: UpdateCohortEndParams;
};


export type MutationUpdateExperienceArgs = {
  params: UpdateExperienceParams;
};


export type MutationUpdateGpsMissionArgs = {
  params: UpdateGpsMissionInput;
};


export type MutationUpdateMissionCustomOrderPositionArgs = {
  id: Scalars['ID']['input'];
  newPosition: Scalars['Int']['input'];
};


export type MutationUpdateScheduledBroadcastArgs = {
  params: UpdateScheduledBroadcastParams;
};


export type MutationUpdateSubmissionBonusArgs = {
  params: UpdateSubmissionBonusInput;
};


export type MutationUpdateTeamArgs = {
  params: UpdateTeamParams;
};


export type MutationUpdateTeamBonusArgs = {
  params: UpdateTeamBonusInput;
};


export type MutationUpdateTextMissionArgs = {
  params: UpdateTextMissionInput;
};


export type MutationUpdateUserProfileArgs = {
  params: UpdateUserProfileParams;
};


export type MutationUpdateWorkspaceArgs = {
  params: UpdateWorkspaceParams;
};


export type MutationUpdateWorkspaceInvitationArgs = {
  params: UpdateWorkspaceInvitationParams;
};


export type MutationUpdateWorkspaceMemberArgs = {
  params: UpdateWorkspaceMemberParams;
};


export type MutationValidateExperiencePasswordArgs = {
  experienceId: Scalars['ID']['input'];
  password: Scalars['String']['input'];
};

export type PageInfo = {
  __typename: 'PageInfo';
  endCursor: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Maybe<Scalars['String']['output']>;
};

export type PaginatedTemplateLibraryExperiences = {
  __typename: 'PaginatedTemplateLibraryExperiences';
  edges: Array<TemplateLibraryExperienceSearchResult>;
  totalCount: Scalars['Int']['output'];
};

export type Participant = {
  __typename: 'Participant';
  createdAt: Scalars['DateTime']['output'];
  displayName: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  teamId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['ID']['output'];
};

export type PasswordValidationResponse = {
  __typename: 'PasswordValidationResponse';
  valid: Scalars['Boolean']['output'];
};

export type PlansQueryFilter = {
  purchasable?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<Array<InputMaybe<WorkspaceType>>>;
};

export type PublicCameraMission = PublicMission & {
  __typename: 'PublicCameraMission';
  allowCameraRollUploads: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  feedVisibility: FeedVisibility;
  image: Maybe<Scalars['String']['output']>;
  mediaType: MediaType;
  missionType: MissionType;
  points: Scalars['Int']['output'];
};

export type PublicCameraSubmission = PublicSubmission & {
  __typename: 'PublicCameraSubmission';
  assets: Array<SubmissionAsset>;
  bonusPoints: Scalars['Int']['output'];
  bonuses: Array<SubmissionBonus>;
  caption: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Always false if not authenticated */
  liked: Scalars['Boolean']['output'];
  mission: PublicMission;
  numLikes: Scalars['Int']['output'];
  publicUrl: Scalars['String']['output'];
  team: PublicTeam;
};

export type PublicExperience = {
  __typename: 'PublicExperience';
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  missionOrder: MissionOrder;
  missions: Array<PublicMission>;
  photoUrl: Maybe<Scalars['String']['output']>;
};

export type PublicGpsMission = PublicMission & {
  __typename: 'PublicGpsMission';
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  feedVisibility: FeedVisibility;
  image: Maybe<Scalars['String']['output']>;
  latitude: Scalars['String']['output'];
  locationName: Maybe<Scalars['String']['output']>;
  longitude: Scalars['String']['output'];
  missionType: MissionType;
  points: Scalars['Int']['output'];
  radius: Scalars['Int']['output'];
};

export type PublicGpsSubmission = PublicSubmission & {
  __typename: 'PublicGpsSubmission';
  bonusPoints: Scalars['Int']['output'];
  bonuses: Array<SubmissionBonus>;
  caption: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  latitude: Scalars['String']['output'];
  /** Always false if not authenticated */
  liked: Scalars['Boolean']['output'];
  longitude: Scalars['String']['output'];
  mission: PublicMission;
  numLikes: Scalars['Int']['output'];
  publicUrl: Scalars['String']['output'];
  team: PublicTeam;
};

export type PublicMission = {
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  feedVisibility: FeedVisibility;
  image: Maybe<Scalars['String']['output']>;
  missionType: MissionType;
  points: Scalars['Int']['output'];
};

export type PublicSubmission = {
  bonusPoints: Scalars['Int']['output'];
  bonuses: Array<SubmissionBonus>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Always false if not authenticated */
  liked: Scalars['Boolean']['output'];
  mission: PublicMission;
  numLikes: Scalars['Int']['output'];
  publicUrl: Scalars['String']['output'];
  team: PublicTeam;
};

export type PublicTeam = {
  __typename: 'PublicTeam';
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mode: TeamMode;
  photoUrl: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PublicTextMission = PublicMission & {
  __typename: 'PublicTextMission';
  allowApproximateAnswers: Scalars['Boolean']['output'];
  answers: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  feedVisibility: FeedVisibility;
  image: Maybe<Scalars['String']['output']>;
  missionType: MissionType;
  points: Scalars['Int']['output'];
};

export type PublicTextSubmission = PublicSubmission & {
  __typename: 'PublicTextSubmission';
  answer: Scalars['String']['output'];
  bonusPoints: Scalars['Int']['output'];
  bonuses: Array<SubmissionBonus>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Always false if not authenticated */
  liked: Scalars['Boolean']['output'];
  mission: PublicMission;
  numLikes: Scalars['Int']['output'];
  publicUrl: Scalars['String']['output'];
  team: PublicTeam;
};

export type Query = {
  __typename: 'Query';
  availableAddons: Array<Addon>;
  checkAccountDeletion: DeleteCheck;
  cohort: Maybe<Cohort>;
  cohortSubmissionArchives: Array<SubmissionArchive>;
  experience: Maybe<Experience>;
  experienceInvitation: ExperienceInvitation;
  experienceManagers: Array<ExperienceManager>;
  experienceMissions: Array<Mission>;
  getTemplateLibraryExperiences: PaginatedTemplateLibraryExperiences;
  /**
   * Possible Errors:
   * * INVALID_INPUT
   * * INTERNAL_ERROR
   */
  isPasswordResetIdValid: Maybe<Scalars['Boolean']['output']>;
  isTeamNameAvailable: Scalars['Boolean']['output'];
  leaderboard: Array<TeamRanking>;
  likeCountBySubmission: Array<SubmissionLikeCount>;
  mission: Maybe<Mission>;
  myActiveWorkspace: Maybe<Workspace>;
  /**
   * PossibleErrors:
   * - UNAUTHORIZED if user is not authenticated
   */
  myJoinedExperiences: Array<Experience>;
  /**
   * PossibleErrors:
   * - UNAUTHORIZED if user is not authenticated
   */
  myManagedExperiences: Array<Experience>;
  myOwnedWorkspaces: Array<Workspace>;
  myPersonalExperiences: Array<Experience>;
  myPreviousMissions: Array<Mission>;
  myRemainingMissions: Array<Mission>;
  mySavedMissions: Array<Mission>;
  mySharedExperiences: Array<Experience>;
  myUseCases: Array<UseCase>;
  myUser: User;
  myWorkspaceExperiences: Array<Experience>;
  myWorkspaces: Array<Workspace>;
  pendingExperienceInvitations: Array<ExperienceInvitation>;
  pendingWorkspaceInvitations: Array<WorkspaceInvitation>;
  plans: Array<WorkspacePlan>;
  publicSubmission: PublicSubmission;
  sharedExperience: PublicExperience;
  submissionArchive: SubmissionArchive;
  submissionCountByMission: Array<MissionSubmissionCount>;
  submissionCountByTeam: Array<TeamSubmissionCount>;
  submissionFeed: SubmissionFeedConnection;
  submissionInfo: Array<SubmissionInfo>;
  submissionInfoPaginated: SubmissionInfoConnection;
  team: Maybe<Team>;
  templateLibraryTags: Array<TemplateLibraryTag>;
  upcomingBilling: Maybe<Billing>;
  useCases: Array<UseCase>;
  workspace: Workspace;
  workspaceExperiences: Array<Experience>;
  workspaceInvitation: WorkspaceInvitation;
  workspaceMembers: Array<WorkspaceMember>;
};


export type QueryCohortArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCohortSubmissionArchivesArgs = {
  cohortId: Scalars['ID']['input'];
  state: InputMaybe<SubmissionArchiveState>;
};


export type QueryExperienceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExperienceInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};


export type QueryExperienceManagersArgs = {
  experienceId: Scalars['ID']['input'];
};


export type QueryExperienceMissionsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetTemplateLibraryExperiencesArgs = {
  params: InputMaybe<GetTemplateLibraryExperiencesInput>;
};


export type QueryIsPasswordResetIdValidArgs = {
  passwordResetId: Scalars['String']['input'];
};


export type QueryIsTeamNameAvailableArgs = {
  params: IsTeamNameAvailableParams;
};


export type QueryLeaderboardArgs = {
  cohortId: Scalars['ID']['input'];
};


export type QueryLikeCountBySubmissionArgs = {
  cohortId: Scalars['ID']['input'];
};


export type QueryMissionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMyPreviousMissionsArgs = {
  excludeExperienceId: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMyRemainingMissionsArgs = {
  participantId: Scalars['ID']['input'];
};


export type QueryMyWorkspaceExperiencesArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryPendingExperienceInvitationsArgs = {
  experienceId: Scalars['ID']['input'];
};


export type QueryPendingWorkspaceInvitationsArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryPlansArgs = {
  filter: InputMaybe<PlansQueryFilter>;
};


export type QueryPublicSubmissionArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySharedExperienceArgs = {
  experienceShareId: Scalars['ID']['input'];
};


export type QuerySubmissionArchiveArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySubmissionCountByMissionArgs = {
  cohortId: Scalars['ID']['input'];
};


export type QuerySubmissionCountByTeamArgs = {
  cohortId: Scalars['ID']['input'];
};


export type QuerySubmissionFeedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  experienceId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orderBy: SubmissionFeedOrderBy;
  orderDirection: SortOrder;
};


export type QuerySubmissionInfoArgs = {
  cohortId: Scalars['ID']['input'];
};


export type QuerySubmissionInfoPaginatedArgs = {
  params: SubmissionInfoQueryParams;
};


export type QueryTeamArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUpcomingBillingArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspaceArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspaceExperiencesArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type QueryWorkspaceInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};


export type QueryWorkspaceMembersArgs = {
  workspaceId: Scalars['ID']['input'];
};

export type RefreshPayload = {
  __typename: 'RefreshPayload';
  intercomHmac: Maybe<Scalars['String']['output']>;
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type RegisterParams = {
  email: Scalars['String']['input'];
  marketingEmailsAccepted: Scalars['Boolean']['input'];
  password: Scalars['String']['input'];
};

export type RequestQuoteParams = {
  company?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  eventLabel?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  interestSelect?: InputMaybe<Scalars['String']['input']>;
  isEdu?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  page?: InputMaybe<Scalars['String']['input']>;
  participantCount?: InputMaybe<Scalars['String']['input']>;
  segmentDistinctId?: InputMaybe<Scalars['String']['input']>;
  sentFromPage?: InputMaybe<Scalars['String']['input']>;
  utm_campaign?: InputMaybe<Scalars['String']['input']>;
  utm_medium?: InputMaybe<Scalars['String']['input']>;
  utm_source?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleBroadcastParams = {
  cohortId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  recipientType: BroadcastRecipientType;
  relativeAnchor?: InputMaybe<TriggerTimeAnchor>;
  relativeDuration?: InputMaybe<Scalars['Int']['input']>;
  relativeUnit?: InputMaybe<TriggerTimeUnit>;
  specificDay?: InputMaybe<Scalars['Int']['input']>;
  specificTime?: InputMaybe<Scalars['String']['input']>;
  taskType: BroadcastTaskType;
  teamId?: InputMaybe<Scalars['ID']['input']>;
  timing: BroadcastTriggerTiming;
};

export type ScheduleCohortParams = {
  cohortId: Scalars['ID']['input'];
  endTiming: ExperienceTriggerTiming;
  relativeDuration?: InputMaybe<Scalars['Int']['input']>;
  relativeUnit?: InputMaybe<TriggerTimeUnit>;
  triggerEndAt?: InputMaybe<Scalars['DateTime']['input']>;
  triggerStartAt: Scalars['DateTime']['input'];
};

export enum SchoolLevel {
  District = 'DISTRICT',
  Elementary = 'ELEMENTARY',
  High = 'HIGH',
  Middle = 'MIDDLE',
  Other = 'OTHER'
}

export type SendBroadcastParams = {
  cohortId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  recipientType: BroadcastRecipientType;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ShowUpgradeBanner {
  Always = 'ALWAYS',
  AtParticipantLimit = 'AT_PARTICIPANT_LIMIT',
  Never = 'NEVER'
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StartCohortParams = {
  cohortId: Scalars['ID']['input'];
  endTiming: ExperienceTriggerTiming;
  relativeDuration?: InputMaybe<Scalars['Int']['input']>;
  relativeUnit?: InputMaybe<TriggerTimeUnit>;
  triggerEndAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Submission = {
  bonusPoints: Scalars['Int']['output'];
  bonuses: Array<SubmissionBonus>;
  createdAt: Scalars['DateTime']['output'];
  creator: Participant;
  id: Scalars['ID']['output'];
  /** Always false if not authenticated */
  liked: Scalars['Boolean']['output'];
  mission: Mission;
  numLikes: Scalars['Int']['output'];
  publicUrl: Scalars['String']['output'];
  reason: Maybe<Scalars['String']['output']>;
  state: SubmissionState;
  team: Team;
  updatedAt: Scalars['DateTime']['output'];
};

export type SubmissionArchive = {
  __typename: 'SubmissionArchive';
  cohortId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  grouping: SubmissionArchiveGrouping;
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  state: SubmissionArchiveState;
  updatedAt: Scalars['DateTime']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export enum SubmissionArchiveGrouping {
  ByMission = 'BY_MISSION',
  ByTeam = 'BY_TEAM',
  None = 'NONE'
}

export enum SubmissionArchiveGroupingInput {
  ByMission = 'by_mission',
  ByTeam = 'by_team',
  None = 'none'
}

export enum SubmissionArchiveState {
  Available = 'AVAILABLE',
  Deleted = 'DELETED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Requested = 'REQUESTED'
}

export type SubmissionAsset = Asset & {
  __typename: 'SubmissionAsset';
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  role: SubmissionAssetRole;
  updatedAt: Scalars['DateTime']['output'];
  uploaded: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
};

export enum SubmissionAssetRole {
  Photo = 'PHOTO',
  Video = 'VIDEO'
}

export type SubmissionBonus = {
  __typename: 'SubmissionBonus';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  note: Maybe<Scalars['String']['output']>;
  submissionId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Int']['output'];
};

export type SubmissionFeedConnection = Connection & {
  __typename: 'SubmissionFeedConnection';
  edges: Array<SubmissionFeedEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SubmissionFeedEdge = Edge & {
  __typename: 'SubmissionFeedEdge';
  cursor: Scalars['String']['output'];
  node: Submission;
};

export enum SubmissionFeedOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type SubmissionInfo = {
  __typename: 'SubmissionInfo';
  bonusPoints: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  likes: Scalars['Int']['output'];
  missionName: Scalars['String']['output'];
  participantName: Scalars['String']['output'];
  points: Scalars['Int']['output'];
  submissionDataString: Scalars['String']['output'];
  teamName: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type SubmissionInfoConnection = Connection & {
  __typename: 'SubmissionInfoConnection';
  edges: Array<SubmissionInfoEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SubmissionInfoEdge = Edge & {
  __typename: 'SubmissionInfoEdge';
  cursor: Scalars['String']['output'];
  node: SubmissionInfo;
};

export enum SubmissionInfoOrderBy {
  BonusPoints = 'BONUS_POINTS',
  CreatedAt = 'CREATED_AT',
  Likes = 'LIKES',
  MissionName = 'MISSION_NAME',
  ParticipantName = 'PARTICIPANT_NAME',
  Points = 'POINTS',
  TeamName = 'TEAM_NAME',
  Type = 'TYPE'
}

export type SubmissionInfoQueryParams = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  experienceId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy: SubmissionInfoOrderBy;
  orderDirection: SortOrder;
};

export type SubmissionLikeCount = {
  __typename: 'SubmissionLikeCount';
  likeCount: Scalars['Int']['output'];
  missionName: Scalars['String']['output'];
  submission: Submission;
  teamName: Scalars['String']['output'];
};

export enum SubmissionState {
  Accepted = 'ACCEPTED',
  Deleted = 'DELETED',
  Invalidated = 'INVALIDATED',
  Rejected = 'REJECTED'
}

export type Subscription = {
  __typename: 'Subscription';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  externalId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  plan: WorkspacePlan;
  planId: Scalars['ID']['output'];
  status: Maybe<Scalars['String']['output']>;
  termEndDate: Maybe<Scalars['DateTime']['output']>;
  termStartDate: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  workspaceId: Scalars['ID']['output'];
};

export type Team = {
  __typename: 'Team';
  bonusPoints: Scalars['Int']['output'];
  bonuses: Array<TeamBonus>;
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPreCreated: Scalars['Boolean']['output'];
  lastSubmission: Maybe<Scalars['DateTime']['output']>;
  maxSize: Maybe<Scalars['Int']['output']>;
  mode: TeamMode;
  numSubmissions: Scalars['Int']['output'];
  participants: Array<Participant>;
  passcode: Maybe<Scalars['String']['output']>;
  photoUrl: Maybe<Scalars['String']['output']>;
  points: Scalars['Int']['output'];
  submissions: Array<Submission>;
  updatedAt: Scalars['DateTime']['output'];
};

export type TeamBonus = {
  __typename: 'TeamBonus';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  note: Maybe<Scalars['String']['output']>;
  teamId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Int']['output'];
};

export enum TeamCreationMode {
  All = 'ALL',
  None = 'NONE',
  Solo = 'SOLO',
  Team = 'TEAM'
}

export enum TeamMode {
  Solo = 'SOLO',
  Team = 'TEAM'
}

export type TeamPreview = {
  __typename: 'TeamPreview';
  displayName: Scalars['String']['output'];
  mode: TeamMode;
  photoUrl: Maybe<Scalars['String']['output']>;
};

export type TeamRanking = {
  __typename: 'TeamRanking';
  rank: Scalars['Int']['output'];
  team: Team;
};

export type TeamSubmissionCount = {
  __typename: 'TeamSubmissionCount';
  submissionCount: Scalars['Int']['output'];
  team: Team;
};

export type TemplateLibraryExperienceSearchResult = {
  __typename: 'TemplateLibraryExperienceSearchResult';
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  experienceId: Scalars['ID']['output'];
  photoUrl: Maybe<Scalars['String']['output']>;
  shareUrl: Maybe<Scalars['String']['output']>;
};

export type TemplateLibraryTag = {
  __typename: 'TemplateLibraryTag';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TextMission = Mission & {
  __typename: 'TextMission';
  allowApproximateAnswers: Scalars['Boolean']['output'];
  answers: Array<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  draft: Scalars['Boolean']['output'];
  experience: Maybe<Experience>;
  expireAt: Maybe<Scalars['DateTime']['output']>;
  expireTrigger: Maybe<MissionTrigger>;
  feedVisibility: FeedVisibility;
  id: Scalars['ID']['output'];
  image: Maybe<Scalars['String']['output']>;
  link: Maybe<Scalars['String']['output']>;
  missionType: MissionType;
  numSubmissions: Scalars['Int']['output'];
  points: Scalars['Int']['output'];
  position: Maybe<Scalars['Int']['output']>;
  releaseAt: Maybe<Scalars['DateTime']['output']>;
  releaseTrigger: Maybe<MissionTrigger>;
  status: Maybe<MissionStatus>;
  submissions: Array<Submission>;
  updatedAt: Scalars['DateTime']['output'];
};


export type TextMissionExpireAtArgs = {
  cohortId: Scalars['ID']['input'];
};


export type TextMissionNumSubmissionsArgs = {
  cohortId: InputMaybe<Scalars['ID']['input']>;
};


export type TextMissionReleaseAtArgs = {
  cohortId: Scalars['ID']['input'];
};


export type TextMissionStatusArgs = {
  cohortId: Scalars['ID']['input'];
};


export type TextMissionSubmissionsArgs = {
  cohortId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
};

export type TextSubmission = Submission & {
  __typename: 'TextSubmission';
  answer: Scalars['String']['output'];
  bonusPoints: Scalars['Int']['output'];
  bonuses: Array<SubmissionBonus>;
  createdAt: Scalars['DateTime']['output'];
  creator: Participant;
  id: Scalars['ID']['output'];
  /** Always false if not authenticated */
  liked: Scalars['Boolean']['output'];
  mission: TextMission;
  numLikes: Scalars['Int']['output'];
  publicUrl: Scalars['String']['output'];
  reason: Maybe<Scalars['String']['output']>;
  state: SubmissionState;
  team: Team;
  updatedAt: Scalars['DateTime']['output'];
};

export enum TriggerTimeAnchor {
  AfterEnd = 'AFTER_END',
  AfterStart = 'AFTER_START',
  AtEnd = 'AT_END',
  AtStart = 'AT_START',
  BeforeEnd = 'BEFORE_END',
  BeforeStart = 'BEFORE_START'
}

export enum TriggerTimeAnchorV1 {
  AfterStart = 'AFTER_START',
  BeforeEnd = 'BEFORE_END'
}

export enum TriggerTimeUnit {
  Days = 'DAYS',
  Hours = 'HOURS',
  Minutes = 'MINUTES',
  Months = 'MONTHS',
  Weeks = 'WEEKS'
}

export type UpdateCameraMissionInput = {
  allowCameraRollUploads: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  draft: Scalars['Boolean']['input'];
  expireTrigger?: InputMaybe<MissionTriggerInput>;
  feedVisibility?: InputMaybe<FeedVisibility>;
  id: Scalars['ID']['input'];
  imageAssetId?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  mediaType: MediaType;
  points?: InputMaybe<Scalars['Int']['input']>;
  releaseTrigger?: InputMaybe<MissionTriggerInput>;
};

export type UpdateCohortEndParams = {
  cohortId: Scalars['ID']['input'];
  endTiming: ExperienceTriggerTiming;
  relativeDuration?: InputMaybe<Scalars['Int']['input']>;
  relativeUnit?: InputMaybe<TriggerTimeUnit>;
  triggerEndAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateExperienceParams = {
  defaultVisible?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  latitude?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  missionOrder?: InputMaybe<MissionOrder>;
  password?: InputMaybe<Scalars['String']['input']>;
  photoId?: InputMaybe<Scalars['String']['input']>;
  splashScreenPhotoId?: InputMaybe<Scalars['ID']['input']>;
  studioDefaultTeamMaxSize?: InputMaybe<Scalars['Int']['input']>;
  studioDefaultTeamMode?: InputMaybe<TeamMode>;
  teamCreationMode?: InputMaybe<TeamCreationMode>;
  termsOfServiceUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGpsMissionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  draft: Scalars['Boolean']['input'];
  expireTrigger?: InputMaybe<MissionTriggerInput>;
  feedVisibility?: InputMaybe<FeedVisibility>;
  id: Scalars['String']['input'];
  imageAssetId?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['String']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  longitude: Scalars['String']['input'];
  points?: InputMaybe<Scalars['Int']['input']>;
  radius: Scalars['Int']['input'];
  releaseTrigger?: InputMaybe<MissionTriggerInput>;
};

export type UpdateScheduledBroadcastParams = {
  broadcastId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  recipientType: BroadcastRecipientType;
  relativeAnchor?: InputMaybe<TriggerTimeAnchor>;
  relativeDuration?: InputMaybe<Scalars['Int']['input']>;
  relativeUnit?: InputMaybe<TriggerTimeUnit>;
  specificDay?: InputMaybe<Scalars['Int']['input']>;
  specificTime?: InputMaybe<Scalars['String']['input']>;
  taskType: BroadcastTaskType;
  teamId?: InputMaybe<Scalars['ID']['input']>;
  timing: BroadcastTriggerTiming;
  type: BroadcastType;
};

export type UpdateSubmissionBonusInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  submissionBonusId: Scalars['ID']['input'];
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTeamBonusInput = {
  bonusId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Int']['input'];
};

export type UpdateTeamParams = {
  assetId?: InputMaybe<Scalars['ID']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  maxSize?: InputMaybe<Scalars['Int']['input']>;
  mode?: InputMaybe<TeamMode>;
  passcode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTextMissionInput = {
  allowApproximateAnswers: Scalars['Boolean']['input'];
  answers: Array<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  draft: Scalars['Boolean']['input'];
  expireTrigger?: InputMaybe<MissionTriggerInput>;
  feedVisibility?: InputMaybe<FeedVisibility>;
  id: Scalars['ID']['input'];
  imageAssetId?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
  releaseTrigger?: InputMaybe<MissionTriggerInput>;
};

export type UpdateUserProfileParams = {
  email?: InputMaybe<Scalars['String']['input']>;
  photoId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkspaceInvitationParams = {
  invitationId: Scalars['ID']['input'];
  roleId: Scalars['String']['input'];
};

export type UpdateWorkspaceMemberParams = {
  roleId?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['ID']['input'];
  workspaceMemberUserId: Scalars['ID']['input'];
};

export type UpdateWorkspaceParams = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  photoId?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type UseCase = {
  __typename: 'UseCase';
  id: Scalars['String']['output'];
};

export type User = {
  __typename: 'User';
  accountId: Scalars['ID']['output'];
  devices: Array<Device>;
  id: Scalars['ID']['output'];
  profile: Maybe<UserProfile>;
};

export type UserProfile = {
  __typename: 'UserProfile';
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  photoUrl: Maybe<Scalars['String']['output']>;
};

export type Workspace = {
  __typename: 'Workspace';
  customerId: Maybe<Scalars['String']['output']>;
  defaultParticipantEmailRequired: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  members: Array<WorkspaceMember>;
  myWorkspaceMember: Maybe<WorkspaceMember>;
  owner: WorkspaceMember;
  photoUrl: Maybe<Scalars['String']['output']>;
  scope: WorkspaceScope;
  subscription: Subscription;
  type: WorkspaceType;
};

export type WorkspaceInvitation = {
  __typename: 'WorkspaceInvitation';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  roleId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  workspace: Workspace;
};

export type WorkspaceMember = {
  __typename: 'WorkspaceMember';
  accountId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  roleDisplayName: Scalars['String']['output'];
  roleId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['ID']['output'];
  workspace: Workspace;
  workspaceDisplayName: Scalars['String']['output'];
  workspaceId: Scalars['ID']['output'];
  workspaceScope: WorkspaceScope;
  workspaceType: WorkspaceType;
};

export type WorkspacePlan = {
  __typename: 'WorkspacePlan';
  chargebeeSku: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  individualModeEnabled: Scalars['Boolean']['output'];
  maxConcurrentExperiences: Maybe<Scalars['Int']['output']>;
  maxParticipantsPerExperience: Maybe<Scalars['Int']['output']>;
  maxTeamsPerExperience: Maybe<Scalars['Int']['output']>;
  maxWorkspaceMembers: Maybe<Scalars['Int']['output']>;
  paid: Scalars['Boolean']['output'];
  priceUsdCents: Scalars['Int']['output'];
  purchasable: Scalars['Boolean']['output'];
  requiredWorkspaceScope: WorkspaceScope;
  requiredWorkspaceType: WorkspaceType;
  showUpgradeBanner: ShowUpgradeBanner;
  upgradable: Scalars['Boolean']['output'];
};

export enum WorkspaceScope {
  Organizational = 'ORGANIZATIONAL',
  Personal = 'PERSONAL'
}

export enum WorkspaceType {
  Educator = 'EDUCATOR',
  Regular = 'REGULAR'
}



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;


/** Mapping of interface types */
export type ResolversInterfaceTypes<RefType extends Record<string, unknown>> = {
  Asset: ( ExperienceAsset ) | ( SubmissionAsset );
  Connection: ( SubmissionFeedConnection ) | ( SubmissionInfoConnection );
  Edge: ( SubmissionFeedEdge ) | ( SubmissionInfoEdge );
  Mission: ( CameraMission ) | ( GpsMission ) | ( TextMission );
  PublicMission: ( PublicCameraMission ) | ( PublicGpsMission ) | ( PublicTextMission );
  PublicSubmission: ( PublicCameraSubmission ) | ( PublicGpsSubmission ) | ( PublicTextSubmission );
  Submission: ( CameraSubmission ) | ( GpsSubmission ) | ( TextSubmission );
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Account: ResolverTypeWrapper<Account>;
  AddSubmissionBonusInput: AddSubmissionBonusInput;
  AddTeamBonusInput: AddTeamBonusInput;
  AddToExperienceParams: AddToExperienceParams;
  Addon: ResolverTypeWrapper<Addon>;
  Asset: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Asset']>;
  AuthPayload: ResolverTypeWrapper<AuthPayload>;
  Billing: ResolverTypeWrapper<Billing>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Broadcast: ResolverTypeWrapper<Broadcast>;
  BroadcastRecipient: ResolverTypeWrapper<BroadcastRecipient>;
  BroadcastRecipientType: BroadcastRecipientType;
  BroadcastState: BroadcastState;
  BroadcastTaskType: BroadcastTaskType;
  BroadcastTrigger: ResolverTypeWrapper<BroadcastTrigger>;
  BroadcastTriggerTiming: BroadcastTriggerTiming;
  BroadcastType: BroadcastType;
  CameraMission: ResolverTypeWrapper<CameraMission>;
  CameraSubmission: ResolverTypeWrapper<CameraSubmission>;
  Card: ResolverTypeWrapper<Card>;
  ChangePasswordInput: ChangePasswordInput;
  Cohort: ResolverTypeWrapper<Cohort>;
  CohortPreview: ResolverTypeWrapper<CohortPreview>;
  CohortState: CohortState;
  CohortStatistics: ResolverTypeWrapper<CohortStatistics>;
  Connection: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Connection']>;
  ContactUsParams: ContactUsParams;
  CreateAndJoinTeamParams: CreateAndJoinTeamParams;
  CreateAssetParams: CreateAssetParams;
  CreateAssetPayload: ResolverTypeWrapper<CreateAssetPayload>;
  CreateCameraMissionInput: CreateCameraMissionInput;
  CreateEducatorProfileInput: CreateEducatorProfileInput;
  CreateExperienceInvitationParams: CreateExperienceInvitationParams;
  CreateExperienceParams: CreateExperienceParams;
  CreateGpsMissionInput: CreateGpsMissionInput;
  CreateSubmissionArchiveInput: CreateSubmissionArchiveInput;
  CreateTeamParams: CreateTeamParams;
  CreateTextMissionInput: CreateTextMissionInput;
  CreateWorkspaceInvitationParams: CreateWorkspaceInvitationParams;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DeleteCheck: ResolverTypeWrapper<DeleteCheck>;
  DeleteSubmissionInput: DeleteSubmissionInput;
  DeleteWorkspaceMemberParams: DeleteWorkspaceMemberParams;
  Device: ResolverTypeWrapper<Device>;
  Edge: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Edge']>;
  EducatorPosition: EducatorPosition;
  EducatorProfile: ResolverTypeWrapper<EducatorProfile>;
  Experience: ResolverTypeWrapper<Experience>;
  ExperienceAsset: ResolverTypeWrapper<ExperienceAsset>;
  ExperienceAssetRole: ExperienceAssetRole;
  ExperienceInvitation: ResolverTypeWrapper<ExperienceInvitation>;
  ExperienceManager: ResolverTypeWrapper<ExperienceManager>;
  ExperienceManagerRole: ResolverTypeWrapper<ExperienceManagerRole>;
  ExperienceTaskType: ExperienceTaskType;
  ExperienceTrigger: ResolverTypeWrapper<ExperienceTrigger>;
  ExperienceTriggerTiming: ExperienceTriggerTiming;
  FeedVisibility: FeedVisibility;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  GetTemplateLibraryExperiencesInput: GetTemplateLibraryExperiencesInput;
  GpsMission: ResolverTypeWrapper<GpsMission>;
  GpsSubmission: ResolverTypeWrapper<GpsSubmission>;
  HostedCheckoutPage: ResolverTypeWrapper<HostedCheckoutPage>;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  ImpersonationAuthResponse: ResolverTypeWrapper<ImpersonationAuthResponse>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  IsTeamNameAvailableParams: IsTeamNameAvailableParams;
  JoinTeamParams: JoinTeamParams;
  MediaType: MediaType;
  Mission: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Mission']>;
  MissionOrder: MissionOrder;
  MissionStatus: MissionStatus;
  MissionSubmissionCount: ResolverTypeWrapper<MissionSubmissionCount>;
  MissionTaskType: MissionTaskType;
  MissionTrigger: ResolverTypeWrapper<MissionTrigger>;
  MissionTriggerInput: MissionTriggerInput;
  MissionTriggerTiming: MissionTriggerTiming;
  MissionType: MissionType;
  Mutation: ResolverTypeWrapper<{}>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PaginatedTemplateLibraryExperiences: ResolverTypeWrapper<PaginatedTemplateLibraryExperiences>;
  Participant: ResolverTypeWrapper<Participant>;
  PasswordValidationResponse: ResolverTypeWrapper<PasswordValidationResponse>;
  PlansQueryFilter: PlansQueryFilter;
  PublicCameraMission: ResolverTypeWrapper<PublicCameraMission>;
  PublicCameraSubmission: ResolverTypeWrapper<PublicCameraSubmission>;
  PublicExperience: ResolverTypeWrapper<PublicExperience>;
  PublicGpsMission: ResolverTypeWrapper<PublicGpsMission>;
  PublicGpsSubmission: ResolverTypeWrapper<PublicGpsSubmission>;
  PublicMission: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['PublicMission']>;
  PublicSubmission: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['PublicSubmission']>;
  PublicTeam: ResolverTypeWrapper<PublicTeam>;
  PublicTextMission: ResolverTypeWrapper<PublicTextMission>;
  PublicTextSubmission: ResolverTypeWrapper<PublicTextSubmission>;
  Query: ResolverTypeWrapper<{}>;
  RefreshPayload: ResolverTypeWrapper<RefreshPayload>;
  RegisterParams: RegisterParams;
  RequestQuoteParams: RequestQuoteParams;
  ScheduleBroadcastParams: ScheduleBroadcastParams;
  ScheduleCohortParams: ScheduleCohortParams;
  SchoolLevel: SchoolLevel;
  SendBroadcastParams: SendBroadcastParams;
  ShowUpgradeBanner: ShowUpgradeBanner;
  SortOrder: SortOrder;
  StartCohortParams: StartCohortParams;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  Submission: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Submission']>;
  SubmissionArchive: ResolverTypeWrapper<SubmissionArchive>;
  SubmissionArchiveGrouping: SubmissionArchiveGrouping;
  SubmissionArchiveGroupingInput: SubmissionArchiveGroupingInput;
  SubmissionArchiveState: SubmissionArchiveState;
  SubmissionAsset: ResolverTypeWrapper<SubmissionAsset>;
  SubmissionAssetRole: SubmissionAssetRole;
  SubmissionBonus: ResolverTypeWrapper<SubmissionBonus>;
  SubmissionFeedConnection: ResolverTypeWrapper<SubmissionFeedConnection>;
  SubmissionFeedEdge: ResolverTypeWrapper<SubmissionFeedEdge>;
  SubmissionFeedOrderBy: SubmissionFeedOrderBy;
  SubmissionInfo: ResolverTypeWrapper<SubmissionInfo>;
  SubmissionInfoConnection: ResolverTypeWrapper<SubmissionInfoConnection>;
  SubmissionInfoEdge: ResolverTypeWrapper<SubmissionInfoEdge>;
  SubmissionInfoOrderBy: SubmissionInfoOrderBy;
  SubmissionInfoQueryParams: SubmissionInfoQueryParams;
  SubmissionLikeCount: ResolverTypeWrapper<SubmissionLikeCount>;
  SubmissionState: SubmissionState;
  Subscription: ResolverTypeWrapper<{}>;
  Team: ResolverTypeWrapper<Team>;
  TeamBonus: ResolverTypeWrapper<TeamBonus>;
  TeamCreationMode: TeamCreationMode;
  TeamMode: TeamMode;
  TeamPreview: ResolverTypeWrapper<TeamPreview>;
  TeamRanking: ResolverTypeWrapper<TeamRanking>;
  TeamSubmissionCount: ResolverTypeWrapper<TeamSubmissionCount>;
  TemplateLibraryExperienceSearchResult: ResolverTypeWrapper<TemplateLibraryExperienceSearchResult>;
  TemplateLibraryTag: ResolverTypeWrapper<TemplateLibraryTag>;
  TextMission: ResolverTypeWrapper<TextMission>;
  TextSubmission: ResolverTypeWrapper<TextSubmission>;
  TriggerTimeAnchor: TriggerTimeAnchor;
  TriggerTimeAnchorV1: TriggerTimeAnchorV1;
  TriggerTimeUnit: TriggerTimeUnit;
  UpdateCameraMissionInput: UpdateCameraMissionInput;
  UpdateCohortEndParams: UpdateCohortEndParams;
  UpdateExperienceParams: UpdateExperienceParams;
  UpdateGpsMissionInput: UpdateGpsMissionInput;
  UpdateScheduledBroadcastParams: UpdateScheduledBroadcastParams;
  UpdateSubmissionBonusInput: UpdateSubmissionBonusInput;
  UpdateTeamBonusInput: UpdateTeamBonusInput;
  UpdateTeamParams: UpdateTeamParams;
  UpdateTextMissionInput: UpdateTextMissionInput;
  UpdateUserProfileParams: UpdateUserProfileParams;
  UpdateWorkspaceInvitationParams: UpdateWorkspaceInvitationParams;
  UpdateWorkspaceMemberParams: UpdateWorkspaceMemberParams;
  UpdateWorkspaceParams: UpdateWorkspaceParams;
  UseCase: ResolverTypeWrapper<UseCase>;
  User: ResolverTypeWrapper<User>;
  UserProfile: ResolverTypeWrapper<UserProfile>;
  Void: ResolverTypeWrapper<Scalars['Void']['output']>;
  Workspace: ResolverTypeWrapper<Workspace>;
  WorkspaceInvitation: ResolverTypeWrapper<WorkspaceInvitation>;
  WorkspaceMember: ResolverTypeWrapper<WorkspaceMember>;
  WorkspacePlan: ResolverTypeWrapper<WorkspacePlan>;
  WorkspaceScope: WorkspaceScope;
  WorkspaceType: WorkspaceType;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Account: Account;
  AddSubmissionBonusInput: AddSubmissionBonusInput;
  AddTeamBonusInput: AddTeamBonusInput;
  AddToExperienceParams: AddToExperienceParams;
  Addon: Addon;
  Asset: ResolversInterfaceTypes<ResolversParentTypes>['Asset'];
  AuthPayload: AuthPayload;
  Billing: Billing;
  Boolean: Scalars['Boolean']['output'];
  Broadcast: Broadcast;
  BroadcastRecipient: BroadcastRecipient;
  BroadcastTrigger: BroadcastTrigger;
  CameraMission: CameraMission;
  CameraSubmission: CameraSubmission;
  Card: Card;
  ChangePasswordInput: ChangePasswordInput;
  Cohort: Cohort;
  CohortPreview: CohortPreview;
  CohortStatistics: CohortStatistics;
  Connection: ResolversInterfaceTypes<ResolversParentTypes>['Connection'];
  ContactUsParams: ContactUsParams;
  CreateAndJoinTeamParams: CreateAndJoinTeamParams;
  CreateAssetParams: CreateAssetParams;
  CreateAssetPayload: CreateAssetPayload;
  CreateCameraMissionInput: CreateCameraMissionInput;
  CreateEducatorProfileInput: CreateEducatorProfileInput;
  CreateExperienceInvitationParams: CreateExperienceInvitationParams;
  CreateExperienceParams: CreateExperienceParams;
  CreateGpsMissionInput: CreateGpsMissionInput;
  CreateSubmissionArchiveInput: CreateSubmissionArchiveInput;
  CreateTeamParams: CreateTeamParams;
  CreateTextMissionInput: CreateTextMissionInput;
  CreateWorkspaceInvitationParams: CreateWorkspaceInvitationParams;
  DateTime: Scalars['DateTime']['output'];
  DeleteCheck: DeleteCheck;
  DeleteSubmissionInput: DeleteSubmissionInput;
  DeleteWorkspaceMemberParams: DeleteWorkspaceMemberParams;
  Device: Device;
  Edge: ResolversInterfaceTypes<ResolversParentTypes>['Edge'];
  EducatorProfile: EducatorProfile;
  Experience: Experience;
  ExperienceAsset: ExperienceAsset;
  ExperienceInvitation: ExperienceInvitation;
  ExperienceManager: ExperienceManager;
  ExperienceManagerRole: ExperienceManagerRole;
  ExperienceTrigger: ExperienceTrigger;
  Float: Scalars['Float']['output'];
  GetTemplateLibraryExperiencesInput: GetTemplateLibraryExperiencesInput;
  GpsMission: GpsMission;
  GpsSubmission: GpsSubmission;
  HostedCheckoutPage: HostedCheckoutPage;
  ID: Scalars['ID']['output'];
  ImpersonationAuthResponse: ImpersonationAuthResponse;
  Int: Scalars['Int']['output'];
  IsTeamNameAvailableParams: IsTeamNameAvailableParams;
  JoinTeamParams: JoinTeamParams;
  Mission: ResolversInterfaceTypes<ResolversParentTypes>['Mission'];
  MissionSubmissionCount: MissionSubmissionCount;
  MissionTrigger: MissionTrigger;
  MissionTriggerInput: MissionTriggerInput;
  Mutation: {};
  PageInfo: PageInfo;
  PaginatedTemplateLibraryExperiences: PaginatedTemplateLibraryExperiences;
  Participant: Participant;
  PasswordValidationResponse: PasswordValidationResponse;
  PlansQueryFilter: PlansQueryFilter;
  PublicCameraMission: PublicCameraMission;
  PublicCameraSubmission: PublicCameraSubmission;
  PublicExperience: PublicExperience;
  PublicGpsMission: PublicGpsMission;
  PublicGpsSubmission: PublicGpsSubmission;
  PublicMission: ResolversInterfaceTypes<ResolversParentTypes>['PublicMission'];
  PublicSubmission: ResolversInterfaceTypes<ResolversParentTypes>['PublicSubmission'];
  PublicTeam: PublicTeam;
  PublicTextMission: PublicTextMission;
  PublicTextSubmission: PublicTextSubmission;
  Query: {};
  RefreshPayload: RefreshPayload;
  RegisterParams: RegisterParams;
  RequestQuoteParams: RequestQuoteParams;
  ScheduleBroadcastParams: ScheduleBroadcastParams;
  ScheduleCohortParams: ScheduleCohortParams;
  SendBroadcastParams: SendBroadcastParams;
  StartCohortParams: StartCohortParams;
  String: Scalars['String']['output'];
  Submission: ResolversInterfaceTypes<ResolversParentTypes>['Submission'];
  SubmissionArchive: SubmissionArchive;
  SubmissionAsset: SubmissionAsset;
  SubmissionBonus: SubmissionBonus;
  SubmissionFeedConnection: SubmissionFeedConnection;
  SubmissionFeedEdge: SubmissionFeedEdge;
  SubmissionInfo: SubmissionInfo;
  SubmissionInfoConnection: SubmissionInfoConnection;
  SubmissionInfoEdge: SubmissionInfoEdge;
  SubmissionInfoQueryParams: SubmissionInfoQueryParams;
  SubmissionLikeCount: SubmissionLikeCount;
  Subscription: {};
  Team: Team;
  TeamBonus: TeamBonus;
  TeamPreview: TeamPreview;
  TeamRanking: TeamRanking;
  TeamSubmissionCount: TeamSubmissionCount;
  TemplateLibraryExperienceSearchResult: TemplateLibraryExperienceSearchResult;
  TemplateLibraryTag: TemplateLibraryTag;
  TextMission: TextMission;
  TextSubmission: TextSubmission;
  UpdateCameraMissionInput: UpdateCameraMissionInput;
  UpdateCohortEndParams: UpdateCohortEndParams;
  UpdateExperienceParams: UpdateExperienceParams;
  UpdateGpsMissionInput: UpdateGpsMissionInput;
  UpdateScheduledBroadcastParams: UpdateScheduledBroadcastParams;
  UpdateSubmissionBonusInput: UpdateSubmissionBonusInput;
  UpdateTeamBonusInput: UpdateTeamBonusInput;
  UpdateTeamParams: UpdateTeamParams;
  UpdateTextMissionInput: UpdateTextMissionInput;
  UpdateUserProfileParams: UpdateUserProfileParams;
  UpdateWorkspaceInvitationParams: UpdateWorkspaceInvitationParams;
  UpdateWorkspaceMemberParams: UpdateWorkspaceMemberParams;
  UpdateWorkspaceParams: UpdateWorkspaceParams;
  UseCase: UseCase;
  User: User;
  UserProfile: UserProfile;
  Void: Scalars['Void']['output'];
  Workspace: Workspace;
  WorkspaceInvitation: WorkspaceInvitation;
  WorkspaceMember: WorkspaceMember;
  WorkspacePlan: WorkspacePlan;
};

export type AccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['Account'] = ResolversParentTypes['Account']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddonResolvers<ContextType = any, ParentType extends ResolversParentTypes['Addon'] = ResolversParentTypes['Addon']> = {
  chargebeeSku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  maxTeamsPerExperience?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priceUsdCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssetResolvers<ContextType = any, ParentType extends ResolversParentTypes['Asset'] = ResolversParentTypes['Asset']> = {
  __resolveType: TypeResolveFn<'ExperienceAsset' | 'SubmissionAsset', ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  extension?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uploaded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type AuthPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthPayload'] = ResolversParentTypes['AuthPayload']> = {
  intercomHmac?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workspaceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Billing'] = ResolversParentTypes['Billing']> = {
  card?: Resolver<ResolversTypes['Card'], ParentType, ContextType>;
  nextBillingAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  priceUsdCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BroadcastResolvers<ContextType = any, ParentType extends ResolversParentTypes['Broadcast'] = ResolversParentTypes['Broadcast']> = {
  cohortId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recipientType?: Resolver<ResolversTypes['BroadcastRecipientType'], ParentType, ContextType>;
  recipients?: Resolver<Array<ResolversTypes['BroadcastRecipient']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['BroadcastState'], ParentType, ContextType>;
  trigger?: Resolver<Maybe<ResolversTypes['BroadcastTrigger']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['BroadcastType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BroadcastRecipientResolvers<ContextType = any, ParentType extends ResolversParentTypes['BroadcastRecipient'] = ResolversParentTypes['BroadcastRecipient']> = {
  broadcastId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  team?: Resolver<ResolversTypes['Team'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BroadcastTriggerResolvers<ContextType = any, ParentType extends ResolversParentTypes['BroadcastTrigger'] = ResolversParentTypes['BroadcastTrigger']> = {
  broadcastId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  experienceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  relativeAnchor?: Resolver<Maybe<ResolversTypes['TriggerTimeAnchor']>, ParentType, ContextType>;
  relativeDuration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  relativeUnit?: Resolver<Maybe<ResolversTypes['TriggerTimeUnit']>, ParentType, ContextType>;
  specificDay?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  specificTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskType?: Resolver<ResolversTypes['BroadcastTaskType'], ParentType, ContextType>;
  timing?: Resolver<ResolversTypes['BroadcastTriggerTiming'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CameraMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CameraMission'] = ResolversParentTypes['CameraMission']> = {
  allowCameraRollUploads?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  creatorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  draft?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  experience?: Resolver<Maybe<ResolversTypes['Experience']>, ParentType, ContextType>;
  expireAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType, RequireFields<CameraMissionExpireAtArgs, 'cohortId'>>;
  expireTrigger?: Resolver<Maybe<ResolversTypes['MissionTrigger']>, ParentType, ContextType>;
  feedVisibility?: Resolver<ResolversTypes['FeedVisibility'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mediaType?: Resolver<ResolversTypes['MediaType'], ParentType, ContextType>;
  missionType?: Resolver<ResolversTypes['MissionType'], ParentType, ContextType>;
  numSubmissions?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CameraMissionNumSubmissionsArgs>>;
  points?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  releaseAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType, RequireFields<CameraMissionReleaseAtArgs, 'cohortId'>>;
  releaseTrigger?: Resolver<Maybe<ResolversTypes['MissionTrigger']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['MissionStatus']>, ParentType, ContextType, RequireFields<CameraMissionStatusArgs, 'cohortId'>>;
  submissions?: Resolver<Array<ResolversTypes['Submission']>, ParentType, ContextType, RequireFields<CameraMissionSubmissionsArgs, 'cohortId'>>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CameraSubmissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CameraSubmission'] = ResolversParentTypes['CameraSubmission']> = {
  assets?: Resolver<Array<ResolversTypes['SubmissionAsset']>, ParentType, ContextType>;
  bonusPoints?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bonuses?: Resolver<Array<ResolversTypes['SubmissionBonus']>, ParentType, ContextType>;
  caption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Participant'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  liked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mission?: Resolver<ResolversTypes['CameraMission'], ParentType, ContextType>;
  numLikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  publicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['SubmissionState'], ParentType, ContextType>;
  team?: Resolver<ResolversTypes['Team'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CardResolvers<ContextType = any, ParentType extends ResolversParentTypes['Card'] = ResolversParentTypes['Card']> = {
  last4?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortResolvers<ContextType = any, ParentType extends ResolversParentTypes['Cohort'] = ResolversParentTypes['Cohort']> = {
  broadcasts?: Resolver<Array<ResolversTypes['Broadcast']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  individualModeEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxParticipantsPerExperience?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxTeamsPerExperience?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  numSubmissions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  numberOfParticipants?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  numberOfTeams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  preview?: Resolver<ResolversTypes['CohortPreview'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  statistics?: Resolver<Maybe<ResolversTypes['CohortStatistics']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CohortState'], ParentType, ContextType>;
  submissions?: Resolver<Array<ResolversTypes['Submission']>, ParentType, ContextType>;
  teams?: Resolver<Array<ResolversTypes['Team']>, ParentType, ContextType>;
  triggers?: Resolver<Array<ResolversTypes['ExperienceTrigger']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortPreviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['CohortPreview'] = ResolversParentTypes['CohortPreview']> = {
  numberOfTeams?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  teams?: Resolver<Array<ResolversTypes['TeamPreview']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortStatisticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CohortStatistics'] = ResolversParentTypes['CohortStatistics']> = {
  activeTeams?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  averageMissionCompletion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalSubmissions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalTeams?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Connection'] = ResolversParentTypes['Connection']> = {
  __resolveType: TypeResolveFn<'SubmissionFeedConnection' | 'SubmissionInfoConnection', ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['Edge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
};

export type CreateAssetPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateAssetPayload'] = ResolversParentTypes['CreateAssetPayload']> = {
  assetId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  presignedUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeleteCheckResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteCheck'] = ResolversParentTypes['DeleteCheck']> = {
  deletionAllowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Device'] = ResolversParentTypes['Device']> = {
  fcmToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Edge'] = ResolversParentTypes['Edge']> = {
  __resolveType: TypeResolveFn<'SubmissionFeedEdge' | 'SubmissionInfoEdge', ParentType, ContextType>;
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type EducatorProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['EducatorProfile'] = ResolversParentTypes['EducatorProfile']> = {
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['EducatorPosition'], ParentType, ContextType>;
  schoolDistrict?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  schoolLevel?: Resolver<Maybe<ResolversTypes['SchoolLevel']>, ParentType, ContextType>;
  schoolName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExperienceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Experience'] = ResolversParentTypes['Experience']> = {
  addons?: Resolver<Array<ResolversTypes['Addon']>, ParentType, ContextType>;
  assets?: Resolver<Array<ResolversTypes['ExperienceAsset']>, ParentType, ContextType>;
  cohorts?: Resolver<Array<ResolversTypes['Cohort']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  defaultVisible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailSharingEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  individualModeEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPasswordProtected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  joinCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxParticipantsPerExperience?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxTeamsPerExperience?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  missionOrder?: Resolver<ResolversTypes['MissionOrder'], ParentType, ContextType>;
  missions?: Resolver<Array<ResolversTypes['Mission']>, ParentType, ContextType>;
  myExperienceManager?: Resolver<Maybe<ResolversTypes['ExperienceManager']>, ParentType, ContextType>;
  myParticipant?: Resolver<Maybe<ResolversTypes['Participant']>, ParentType, ContextType>;
  numMissions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  participantEmailRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shareLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  splashScreenPhotoId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CohortState'], ParentType, ContextType>;
  studioDefaultTeamMaxSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  studioDefaultTeamMode?: Resolver<ResolversTypes['TeamMode'], ParentType, ContextType>;
  teamCreationMode?: Resolver<ResolversTypes['TeamCreationMode'], ParentType, ContextType>;
  termsOfServiceUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timezone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  triggers?: Resolver<Array<ResolversTypes['ExperienceTrigger']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  workspace?: Resolver<ResolversTypes['Workspace'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExperienceAssetResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExperienceAsset'] = ResolversParentTypes['ExperienceAsset']> = {
  assetId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  experienceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  extension?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['ExperienceAssetRole'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uploaded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExperienceInvitationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExperienceInvitation'] = ResolversParentTypes['ExperienceInvitation']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  experience?: Resolver<ResolversTypes['PublicExperience'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastSentAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExperienceManagerResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExperienceManager'] = ResolversParentTypes['ExperienceManager']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['ExperienceManagerRole'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExperienceManagerRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExperienceManagerRole'] = ResolversParentTypes['ExperienceManagerRole']> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExperienceTriggerResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExperienceTrigger'] = ResolversParentTypes['ExperienceTrigger']> = {
  cohortId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  relativeAnchor?: Resolver<Maybe<ResolversTypes['TriggerTimeAnchorV1']>, ParentType, ContextType>;
  relativeDuration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  relativeUnit?: Resolver<Maybe<ResolversTypes['TriggerTimeUnit']>, ParentType, ContextType>;
  taskType?: Resolver<ResolversTypes['ExperienceTaskType'], ParentType, ContextType>;
  timing?: Resolver<ResolversTypes['ExperienceTriggerTiming'], ParentType, ContextType>;
  triggerAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpsMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GpsMission'] = ResolversParentTypes['GpsMission']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  creatorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  draft?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  experience?: Resolver<Maybe<ResolversTypes['Experience']>, ParentType, ContextType>;
  expireAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType, RequireFields<GpsMissionExpireAtArgs, 'cohortId'>>;
  expireTrigger?: Resolver<Maybe<ResolversTypes['MissionTrigger']>, ParentType, ContextType>;
  feedVisibility?: Resolver<ResolversTypes['FeedVisibility'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  missionType?: Resolver<ResolversTypes['MissionType'], ParentType, ContextType>;
  numSubmissions?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<GpsMissionNumSubmissionsArgs>>;
  points?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  radius?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  releaseAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType, RequireFields<GpsMissionReleaseAtArgs, 'cohortId'>>;
  releaseTrigger?: Resolver<Maybe<ResolversTypes['MissionTrigger']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['MissionStatus']>, ParentType, ContextType, RequireFields<GpsMissionStatusArgs, 'cohortId'>>;
  submissions?: Resolver<Array<ResolversTypes['Submission']>, ParentType, ContextType, RequireFields<GpsMissionSubmissionsArgs, 'cohortId'>>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GpsSubmissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GpsSubmission'] = ResolversParentTypes['GpsSubmission']> = {
  bonusPoints?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bonuses?: Resolver<Array<ResolversTypes['SubmissionBonus']>, ParentType, ContextType>;
  caption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Participant'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  latitude?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  liked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mission?: Resolver<ResolversTypes['GpsMission'], ParentType, ContextType>;
  numLikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  publicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['SubmissionState'], ParentType, ContextType>;
  team?: Resolver<ResolversTypes['Team'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HostedCheckoutPageResolvers<ContextType = any, ParentType extends ResolversParentTypes['HostedCheckoutPage'] = ResolversParentTypes['HostedCheckoutPage']> = {
  created_at?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  embed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  expires_at?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  object?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resource_version?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpersonationAuthResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImpersonationAuthResponse'] = ResolversParentTypes['ImpersonationAuthResponse']> = {
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mission'] = ResolversParentTypes['Mission']> = {
  __resolveType: TypeResolveFn<'CameraMission' | 'GpsMission' | 'TextMission', ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  creatorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  draft?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  experience?: Resolver<Maybe<ResolversTypes['Experience']>, ParentType, ContextType>;
  expireAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType, RequireFields<MissionExpireAtArgs, 'cohortId'>>;
  expireTrigger?: Resolver<Maybe<ResolversTypes['MissionTrigger']>, ParentType, ContextType>;
  feedVisibility?: Resolver<ResolversTypes['FeedVisibility'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  missionType?: Resolver<ResolversTypes['MissionType'], ParentType, ContextType>;
  numSubmissions?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<MissionNumSubmissionsArgs>>;
  points?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  releaseAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType, RequireFields<MissionReleaseAtArgs, 'cohortId'>>;
  releaseTrigger?: Resolver<Maybe<ResolversTypes['MissionTrigger']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['MissionStatus']>, ParentType, ContextType, RequireFields<MissionStatusArgs, 'cohortId'>>;
  submissions?: Resolver<Array<ResolversTypes['Submission']>, ParentType, ContextType, RequireFields<MissionSubmissionsArgs, 'cohortId'>>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
};

export type MissionSubmissionCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['MissionSubmissionCount'] = ResolversParentTypes['MissionSubmissionCount']> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  submissionCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissionTriggerResolvers<ContextType = any, ParentType extends ResolversParentTypes['MissionTrigger'] = ResolversParentTypes['MissionTrigger']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  missionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  relativeAnchor?: Resolver<Maybe<ResolversTypes['TriggerTimeAnchorV1']>, ParentType, ContextType>;
  relativeDuration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  relativeUnit?: Resolver<Maybe<ResolversTypes['TriggerTimeUnit']>, ParentType, ContextType>;
  specificDay?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  specificTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskType?: Resolver<ResolversTypes['MissionTaskType'], ParentType, ContextType>;
  timing?: Resolver<ResolversTypes['MissionTriggerTiming'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  acceptExperienceInvitation?: Resolver<ResolversTypes['ExperienceInvitation'], ParentType, ContextType, RequireFields<MutationAcceptExperienceInvitationArgs, 'invitationId'>>;
  acceptWorkspaceInvitation?: Resolver<ResolversTypes['WorkspaceInvitation'], ParentType, ContextType, RequireFields<MutationAcceptWorkspaceInvitationArgs, 'invitationId'>>;
  addAssetToExperience?: Resolver<ResolversTypes['ExperienceAsset'], ParentType, ContextType, RequireFields<MutationAddAssetToExperienceArgs, 'params'>>;
  addMissionFromMissionLibrary?: Resolver<ResolversTypes['Mission'], ParentType, ContextType, RequireFields<MutationAddMissionFromMissionLibraryArgs, 'experienceId' | 'missionId'>>;
  addSubmissionBonus?: Resolver<ResolversTypes['SubmissionBonus'], ParentType, ContextType, RequireFields<MutationAddSubmissionBonusArgs, 'params'>>;
  addTeamBonus?: Resolver<ResolversTypes['TeamBonus'], ParentType, ContextType, RequireFields<MutationAddTeamBonusArgs, 'params'>>;
  applyAddonToExperience?: Resolver<ResolversTypes['Experience'], ParentType, ContextType, RequireFields<MutationApplyAddonToExperienceArgs, 'addonId' | 'checkoutId' | 'experienceId'>>;
  applyPlanToWorkspace?: Resolver<ResolversTypes['Workspace'], ParentType, ContextType, Partial<MutationApplyPlanToWorkspaceArgs>>;
  changePassword?: Resolver<ResolversTypes['Account'], ParentType, ContextType, Partial<MutationChangePasswordArgs>>;
  claimImpersonationSession?: Resolver<Maybe<ResolversTypes['ImpersonationAuthResponse']>, ParentType, ContextType, RequireFields<MutationClaimImpersonationSessionArgs, 'sessionId'>>;
  completePlanCheckout?: Resolver<ResolversTypes['Workspace'], ParentType, ContextType, RequireFields<MutationCompletePlanCheckoutArgs, 'checkoutId'>>;
  contactUs?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationContactUsArgs, 'params'>>;
  copySharedExperience?: Resolver<ResolversTypes['Experience'], ParentType, ContextType, RequireFields<MutationCopySharedExperienceArgs, 'experienceShareId'>>;
  createAndJoinTeam?: Resolver<ResolversTypes['Team'], ParentType, ContextType, RequireFields<MutationCreateAndJoinTeamArgs, 'params'>>;
  createAsset?: Resolver<ResolversTypes['CreateAssetPayload'], ParentType, ContextType, RequireFields<MutationCreateAssetArgs, 'params'>>;
  createCameraMission?: Resolver<ResolversTypes['CameraMission'], ParentType, ContextType, RequireFields<MutationCreateCameraMissionArgs, 'params'>>;
  createEducatorProfile?: Resolver<ResolversTypes['EducatorProfile'], ParentType, ContextType, Partial<MutationCreateEducatorProfileArgs>>;
  createExperience?: Resolver<ResolversTypes['Experience'], ParentType, ContextType, RequireFields<MutationCreateExperienceArgs, 'params'>>;
  createExperienceInvitation?: Resolver<ResolversTypes['ExperienceInvitation'], ParentType, ContextType, RequireFields<MutationCreateExperienceInvitationArgs, 'params'>>;
  createGpsMission?: Resolver<ResolversTypes['GpsMission'], ParentType, ContextType, RequireFields<MutationCreateGpsMissionArgs, 'params'>>;
  createSubmissionArchive?: Resolver<ResolversTypes['SubmissionArchive'], ParentType, ContextType, RequireFields<MutationCreateSubmissionArchiveArgs, 'params'>>;
  createTeam?: Resolver<ResolversTypes['Team'], ParentType, ContextType, RequireFields<MutationCreateTeamArgs, 'params'>>;
  createTextMission?: Resolver<ResolversTypes['TextMission'], ParentType, ContextType, RequireFields<MutationCreateTextMissionArgs, 'params'>>;
  createWorkspace?: Resolver<ResolversTypes['Workspace'], ParentType, ContextType, Partial<MutationCreateWorkspaceArgs>>;
  createWorkspaceInvitation?: Resolver<ResolversTypes['WorkspaceInvitation'], ParentType, ContextType, RequireFields<MutationCreateWorkspaceInvitationArgs, 'params'>>;
  deleteAccount?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  deleteBroadcast?: Resolver<ResolversTypes['Broadcast'], ParentType, ContextType, RequireFields<MutationDeleteBroadcastArgs, 'id'>>;
  deleteExperience?: Resolver<ResolversTypes['Experience'], ParentType, ContextType, RequireFields<MutationDeleteExperienceArgs, 'id'>>;
  deleteExperienceInvitation?: Resolver<ResolversTypes['ExperienceInvitation'], ParentType, ContextType, RequireFields<MutationDeleteExperienceInvitationArgs, 'invitationId'>>;
  deleteExperienceManager?: Resolver<ResolversTypes['ExperienceManager'], ParentType, ContextType, RequireFields<MutationDeleteExperienceManagerArgs, 'experienceId' | 'experienceManagerUserId'>>;
  deleteMission?: Resolver<ResolversTypes['Mission'], ParentType, ContextType, RequireFields<MutationDeleteMissionArgs, 'id'>>;
  deleteSavedMission?: Resolver<ResolversTypes['Mission'], ParentType, ContextType, RequireFields<MutationDeleteSavedMissionArgs, 'missionId'>>;
  deleteSubmission?: Resolver<ResolversTypes['Submission'], ParentType, ContextType, RequireFields<MutationDeleteSubmissionArgs, 'params'>>;
  deleteSubmissionBonus?: Resolver<ResolversTypes['SubmissionBonus'], ParentType, ContextType, RequireFields<MutationDeleteSubmissionBonusArgs, 'id'>>;
  deleteTeam?: Resolver<ResolversTypes['Team'], ParentType, ContextType, RequireFields<MutationDeleteTeamArgs, 'id'>>;
  deleteTeamBonus?: Resolver<ResolversTypes['TeamBonus'], ParentType, ContextType, RequireFields<MutationDeleteTeamBonusArgs, 'id'>>;
  deleteWorkspaceInvitation?: Resolver<ResolversTypes['WorkspaceInvitation'], ParentType, ContextType, RequireFields<MutationDeleteWorkspaceInvitationArgs, 'invitationId'>>;
  deleteWorkspaceMember?: Resolver<ResolversTypes['WorkspaceMember'], ParentType, ContextType, RequireFields<MutationDeleteWorkspaceMemberArgs, 'params'>>;
  duplicateExperience?: Resolver<ResolversTypes['Experience'], ParentType, ContextType, RequireFields<MutationDuplicateExperienceArgs, 'id'>>;
  endCohort?: Resolver<ResolversTypes['Cohort'], ParentType, ContextType, RequireFields<MutationEndCohortArgs, 'id'>>;
  forgotPassword?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationForgotPasswordArgs, 'email'>>;
  getBillingDetailsRedirect?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationGetBillingDetailsRedirectArgs, 'workspaceId'>>;
  joinTeam?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<MutationJoinTeamArgs, 'params'>>;
  leaveExperienceAsManager?: Resolver<ResolversTypes['ExperienceManager'], ParentType, ContextType, RequireFields<MutationLeaveExperienceAsManagerArgs, 'experienceId'>>;
  leaveTeam?: Resolver<ResolversTypes['Participant'], ParentType, ContextType, RequireFields<MutationLeaveTeamArgs, 'teamId'>>;
  loadAddonCheckout?: Resolver<ResolversTypes['HostedCheckoutPage'], ParentType, ContextType, RequireFields<MutationLoadAddonCheckoutArgs, 'addonId'>>;
  loadPlanCheckout?: Resolver<ResolversTypes['HostedCheckoutPage'], ParentType, ContextType, RequireFields<MutationLoadPlanCheckoutArgs, 'planId'>>;
  refresh?: Resolver<ResolversTypes['RefreshPayload'], ParentType, ContextType, Partial<MutationRefreshArgs>>;
  register?: Resolver<ResolversTypes['AuthPayload'], ParentType, ContextType, RequireFields<MutationRegisterArgs, 'params'>>;
  registerDevice?: Resolver<ResolversTypes['Device'], ParentType, ContextType, RequireFields<MutationRegisterDeviceArgs, 'fcmToken'>>;
  requestQuote?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationRequestQuoteArgs, 'params'>>;
  resendExperienceInvitation?: Resolver<ResolversTypes['ExperienceInvitation'], ParentType, ContextType, RequireFields<MutationResendExperienceInvitationArgs, 'invitationId'>>;
  resetExperience?: Resolver<ResolversTypes['Cohort'], ParentType, ContextType, RequireFields<MutationResetExperienceArgs, 'id'>>;
  resetPassword?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType, RequireFields<MutationResetPasswordArgs, 'newPassword' | 'passwordResetId'>>;
  saveMission?: Resolver<ResolversTypes['Mission'], ParentType, ContextType, RequireFields<MutationSaveMissionArgs, 'missionId'>>;
  scheduleBroadcast?: Resolver<ResolversTypes['Broadcast'], ParentType, ContextType, RequireFields<MutationScheduleBroadcastArgs, 'params'>>;
  scheduleCohort?: Resolver<Array<ResolversTypes['ExperienceTrigger']>, ParentType, ContextType, RequireFields<MutationScheduleCohortArgs, 'params'>>;
  sendBroadcast?: Resolver<ResolversTypes['Broadcast'], ParentType, ContextType, RequireFields<MutationSendBroadcastArgs, 'params'>>;
  setMyUseCases?: Resolver<Array<ResolversTypes['UseCase']>, ParentType, ContextType, RequireFields<MutationSetMyUseCasesArgs, 'useCases'>>;
  shareExperience?: Resolver<ResolversTypes['Experience'], ParentType, ContextType, RequireFields<MutationShareExperienceArgs, 'experienceId'>>;
  signIn?: Resolver<ResolversTypes['AuthPayload'], ParentType, ContextType, RequireFields<MutationSignInArgs, 'email' | 'password'>>;
  signOut?: Resolver<Maybe<ResolversTypes['Void']>, ParentType, ContextType>;
  startCohortAndScheduleEnd?: Resolver<ResolversTypes['Cohort'], ParentType, ContextType, RequireFields<MutationStartCohortAndScheduleEndArgs, 'params'>>;
  startPlanCheckout?: Resolver<ResolversTypes['HostedCheckoutPage'], ParentType, ContextType, RequireFields<MutationStartPlanCheckoutArgs, 'planId' | 'workspaceId'>>;
  toggleLikeSubmission?: Resolver<ResolversTypes['Submission'], ParentType, ContextType, RequireFields<MutationToggleLikeSubmissionArgs, 'id'>>;
  unregisterDevice?: Resolver<ResolversTypes['Device'], ParentType, ContextType, RequireFields<MutationUnregisterDeviceArgs, 'fcmToken'>>;
  unshareExperience?: Resolver<ResolversTypes['Experience'], ParentType, ContextType, RequireFields<MutationUnshareExperienceArgs, 'experienceId'>>;
  updateCameraMission?: Resolver<ResolversTypes['CameraMission'], ParentType, ContextType, RequireFields<MutationUpdateCameraMissionArgs, 'params'>>;
  updateCohortEnd?: Resolver<ResolversTypes['Cohort'], ParentType, ContextType, RequireFields<MutationUpdateCohortEndArgs, 'params'>>;
  updateExperience?: Resolver<ResolversTypes['Experience'], ParentType, ContextType, RequireFields<MutationUpdateExperienceArgs, 'params'>>;
  updateGpsMission?: Resolver<ResolversTypes['GpsMission'], ParentType, ContextType, RequireFields<MutationUpdateGpsMissionArgs, 'params'>>;
  updateMissionCustomOrderPosition?: Resolver<Array<ResolversTypes['Mission']>, ParentType, ContextType, RequireFields<MutationUpdateMissionCustomOrderPositionArgs, 'id' | 'newPosition'>>;
  updateScheduledBroadcast?: Resolver<ResolversTypes['Broadcast'], ParentType, ContextType, RequireFields<MutationUpdateScheduledBroadcastArgs, 'params'>>;
  updateSubmissionBonus?: Resolver<ResolversTypes['SubmissionBonus'], ParentType, ContextType, RequireFields<MutationUpdateSubmissionBonusArgs, 'params'>>;
  updateTeam?: Resolver<ResolversTypes['Team'], ParentType, ContextType, RequireFields<MutationUpdateTeamArgs, 'params'>>;
  updateTeamBonus?: Resolver<ResolversTypes['TeamBonus'], ParentType, ContextType, RequireFields<MutationUpdateTeamBonusArgs, 'params'>>;
  updateTextMission?: Resolver<ResolversTypes['TextMission'], ParentType, ContextType, RequireFields<MutationUpdateTextMissionArgs, 'params'>>;
  updateUserProfile?: Resolver<ResolversTypes['UserProfile'], ParentType, ContextType, RequireFields<MutationUpdateUserProfileArgs, 'params'>>;
  updateWorkspace?: Resolver<ResolversTypes['Workspace'], ParentType, ContextType, RequireFields<MutationUpdateWorkspaceArgs, 'params'>>;
  updateWorkspaceInvitation?: Resolver<ResolversTypes['WorkspaceInvitation'], ParentType, ContextType, RequireFields<MutationUpdateWorkspaceInvitationArgs, 'params'>>;
  updateWorkspaceMember?: Resolver<ResolversTypes['WorkspaceMember'], ParentType, ContextType, RequireFields<MutationUpdateWorkspaceMemberArgs, 'params'>>;
  validateExperiencePassword?: Resolver<ResolversTypes['PasswordValidationResponse'], ParentType, ContextType, RequireFields<MutationValidateExperiencePasswordArgs, 'experienceId' | 'password'>>;
};

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedTemplateLibraryExperiencesResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedTemplateLibraryExperiences'] = ResolversParentTypes['PaginatedTemplateLibraryExperiences']> = {
  edges?: Resolver<Array<ResolversTypes['TemplateLibraryExperienceSearchResult']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParticipantResolvers<ContextType = any, ParentType extends ResolversParentTypes['Participant'] = ResolversParentTypes['Participant']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  teamId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PasswordValidationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PasswordValidationResponse'] = ResolversParentTypes['PasswordValidationResponse']> = {
  valid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicCameraMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicCameraMission'] = ResolversParentTypes['PublicCameraMission']> = {
  allowCameraRollUploads?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  feedVisibility?: Resolver<ResolversTypes['FeedVisibility'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mediaType?: Resolver<ResolversTypes['MediaType'], ParentType, ContextType>;
  missionType?: Resolver<ResolversTypes['MissionType'], ParentType, ContextType>;
  points?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicCameraSubmissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicCameraSubmission'] = ResolversParentTypes['PublicCameraSubmission']> = {
  assets?: Resolver<Array<ResolversTypes['SubmissionAsset']>, ParentType, ContextType>;
  bonusPoints?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bonuses?: Resolver<Array<ResolversTypes['SubmissionBonus']>, ParentType, ContextType>;
  caption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  liked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mission?: Resolver<ResolversTypes['PublicMission'], ParentType, ContextType>;
  numLikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  publicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  team?: Resolver<ResolversTypes['PublicTeam'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicExperienceResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicExperience'] = ResolversParentTypes['PublicExperience']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  missionOrder?: Resolver<ResolversTypes['MissionOrder'], ParentType, ContextType>;
  missions?: Resolver<Array<ResolversTypes['PublicMission']>, ParentType, ContextType>;
  photoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicGpsMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicGpsMission'] = ResolversParentTypes['PublicGpsMission']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  feedVisibility?: Resolver<ResolversTypes['FeedVisibility'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  missionType?: Resolver<ResolversTypes['MissionType'], ParentType, ContextType>;
  points?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  radius?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicGpsSubmissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicGpsSubmission'] = ResolversParentTypes['PublicGpsSubmission']> = {
  bonusPoints?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bonuses?: Resolver<Array<ResolversTypes['SubmissionBonus']>, ParentType, ContextType>;
  caption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  latitude?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  liked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mission?: Resolver<ResolversTypes['PublicMission'], ParentType, ContextType>;
  numLikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  publicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  team?: Resolver<ResolversTypes['PublicTeam'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicMission'] = ResolversParentTypes['PublicMission']> = {
  __resolveType: TypeResolveFn<'PublicCameraMission' | 'PublicGpsMission' | 'PublicTextMission', ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  feedVisibility?: Resolver<ResolversTypes['FeedVisibility'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  missionType?: Resolver<ResolversTypes['MissionType'], ParentType, ContextType>;
  points?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
};

export type PublicSubmissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicSubmission'] = ResolversParentTypes['PublicSubmission']> = {
  __resolveType: TypeResolveFn<'PublicCameraSubmission' | 'PublicGpsSubmission' | 'PublicTextSubmission', ParentType, ContextType>;
  bonusPoints?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bonuses?: Resolver<Array<ResolversTypes['SubmissionBonus']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  liked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mission?: Resolver<ResolversTypes['PublicMission'], ParentType, ContextType>;
  numLikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  publicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  team?: Resolver<ResolversTypes['PublicTeam'], ParentType, ContextType>;
};

export type PublicTeamResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicTeam'] = ResolversParentTypes['PublicTeam']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mode?: Resolver<ResolversTypes['TeamMode'], ParentType, ContextType>;
  photoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicTextMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicTextMission'] = ResolversParentTypes['PublicTextMission']> = {
  allowApproximateAnswers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  answers?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  feedVisibility?: Resolver<ResolversTypes['FeedVisibility'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  missionType?: Resolver<ResolversTypes['MissionType'], ParentType, ContextType>;
  points?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicTextSubmissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicTextSubmission'] = ResolversParentTypes['PublicTextSubmission']> = {
  answer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bonusPoints?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bonuses?: Resolver<Array<ResolversTypes['SubmissionBonus']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  liked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mission?: Resolver<ResolversTypes['PublicMission'], ParentType, ContextType>;
  numLikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  publicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  team?: Resolver<ResolversTypes['PublicTeam'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  availableAddons?: Resolver<Array<ResolversTypes['Addon']>, ParentType, ContextType>;
  checkAccountDeletion?: Resolver<ResolversTypes['DeleteCheck'], ParentType, ContextType>;
  cohort?: Resolver<Maybe<ResolversTypes['Cohort']>, ParentType, ContextType, RequireFields<QueryCohortArgs, 'id'>>;
  cohortSubmissionArchives?: Resolver<Array<ResolversTypes['SubmissionArchive']>, ParentType, ContextType, RequireFields<QueryCohortSubmissionArchivesArgs, 'cohortId'>>;
  experience?: Resolver<Maybe<ResolversTypes['Experience']>, ParentType, ContextType, RequireFields<QueryExperienceArgs, 'id'>>;
  experienceInvitation?: Resolver<ResolversTypes['ExperienceInvitation'], ParentType, ContextType, RequireFields<QueryExperienceInvitationArgs, 'invitationId'>>;
  experienceManagers?: Resolver<Array<ResolversTypes['ExperienceManager']>, ParentType, ContextType, RequireFields<QueryExperienceManagersArgs, 'experienceId'>>;
  experienceMissions?: Resolver<Array<ResolversTypes['Mission']>, ParentType, ContextType, RequireFields<QueryExperienceMissionsArgs, 'id'>>;
  getTemplateLibraryExperiences?: Resolver<ResolversTypes['PaginatedTemplateLibraryExperiences'], ParentType, ContextType, Partial<QueryGetTemplateLibraryExperiencesArgs>>;
  isPasswordResetIdValid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<QueryIsPasswordResetIdValidArgs, 'passwordResetId'>>;
  isTeamNameAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryIsTeamNameAvailableArgs, 'params'>>;
  leaderboard?: Resolver<Array<ResolversTypes['TeamRanking']>, ParentType, ContextType, RequireFields<QueryLeaderboardArgs, 'cohortId'>>;
  likeCountBySubmission?: Resolver<Array<ResolversTypes['SubmissionLikeCount']>, ParentType, ContextType, RequireFields<QueryLikeCountBySubmissionArgs, 'cohortId'>>;
  mission?: Resolver<Maybe<ResolversTypes['Mission']>, ParentType, ContextType, RequireFields<QueryMissionArgs, 'id'>>;
  myActiveWorkspace?: Resolver<Maybe<ResolversTypes['Workspace']>, ParentType, ContextType>;
  myJoinedExperiences?: Resolver<Array<ResolversTypes['Experience']>, ParentType, ContextType>;
  myManagedExperiences?: Resolver<Array<ResolversTypes['Experience']>, ParentType, ContextType>;
  myOwnedWorkspaces?: Resolver<Array<ResolversTypes['Workspace']>, ParentType, ContextType>;
  myPersonalExperiences?: Resolver<Array<ResolversTypes['Experience']>, ParentType, ContextType>;
  myPreviousMissions?: Resolver<Array<ResolversTypes['Mission']>, ParentType, ContextType, Partial<QueryMyPreviousMissionsArgs>>;
  myRemainingMissions?: Resolver<Array<ResolversTypes['Mission']>, ParentType, ContextType, RequireFields<QueryMyRemainingMissionsArgs, 'participantId'>>;
  mySavedMissions?: Resolver<Array<ResolversTypes['Mission']>, ParentType, ContextType>;
  mySharedExperiences?: Resolver<Array<ResolversTypes['Experience']>, ParentType, ContextType>;
  myUseCases?: Resolver<Array<ResolversTypes['UseCase']>, ParentType, ContextType>;
  myUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  myWorkspaceExperiences?: Resolver<Array<ResolversTypes['Experience']>, ParentType, ContextType, RequireFields<QueryMyWorkspaceExperiencesArgs, 'workspaceId'>>;
  myWorkspaces?: Resolver<Array<ResolversTypes['Workspace']>, ParentType, ContextType>;
  pendingExperienceInvitations?: Resolver<Array<ResolversTypes['ExperienceInvitation']>, ParentType, ContextType, RequireFields<QueryPendingExperienceInvitationsArgs, 'experienceId'>>;
  pendingWorkspaceInvitations?: Resolver<Array<ResolversTypes['WorkspaceInvitation']>, ParentType, ContextType, RequireFields<QueryPendingWorkspaceInvitationsArgs, 'workspaceId'>>;
  plans?: Resolver<Array<ResolversTypes['WorkspacePlan']>, ParentType, ContextType, Partial<QueryPlansArgs>>;
  publicSubmission?: Resolver<ResolversTypes['PublicSubmission'], ParentType, ContextType, RequireFields<QueryPublicSubmissionArgs, 'id'>>;
  sharedExperience?: Resolver<ResolversTypes['PublicExperience'], ParentType, ContextType, RequireFields<QuerySharedExperienceArgs, 'experienceShareId'>>;
  submissionArchive?: Resolver<ResolversTypes['SubmissionArchive'], ParentType, ContextType, RequireFields<QuerySubmissionArchiveArgs, 'id'>>;
  submissionCountByMission?: Resolver<Array<ResolversTypes['MissionSubmissionCount']>, ParentType, ContextType, RequireFields<QuerySubmissionCountByMissionArgs, 'cohortId'>>;
  submissionCountByTeam?: Resolver<Array<ResolversTypes['TeamSubmissionCount']>, ParentType, ContextType, RequireFields<QuerySubmissionCountByTeamArgs, 'cohortId'>>;
  submissionFeed?: Resolver<ResolversTypes['SubmissionFeedConnection'], ParentType, ContextType, RequireFields<QuerySubmissionFeedArgs, 'experienceId' | 'orderBy' | 'orderDirection'>>;
  submissionInfo?: Resolver<Array<ResolversTypes['SubmissionInfo']>, ParentType, ContextType, RequireFields<QuerySubmissionInfoArgs, 'cohortId'>>;
  submissionInfoPaginated?: Resolver<ResolversTypes['SubmissionInfoConnection'], ParentType, ContextType, RequireFields<QuerySubmissionInfoPaginatedArgs, 'params'>>;
  team?: Resolver<Maybe<ResolversTypes['Team']>, ParentType, ContextType, RequireFields<QueryTeamArgs, 'id'>>;
  templateLibraryTags?: Resolver<Array<ResolversTypes['TemplateLibraryTag']>, ParentType, ContextType>;
  upcomingBilling?: Resolver<Maybe<ResolversTypes['Billing']>, ParentType, ContextType, RequireFields<QueryUpcomingBillingArgs, 'workspaceId'>>;
  useCases?: Resolver<Array<ResolversTypes['UseCase']>, ParentType, ContextType>;
  workspace?: Resolver<ResolversTypes['Workspace'], ParentType, ContextType, RequireFields<QueryWorkspaceArgs, 'workspaceId'>>;
  workspaceExperiences?: Resolver<Array<ResolversTypes['Experience']>, ParentType, ContextType, RequireFields<QueryWorkspaceExperiencesArgs, 'workspaceId'>>;
  workspaceInvitation?: Resolver<ResolversTypes['WorkspaceInvitation'], ParentType, ContextType, RequireFields<QueryWorkspaceInvitationArgs, 'invitationId'>>;
  workspaceMembers?: Resolver<Array<ResolversTypes['WorkspaceMember']>, ParentType, ContextType, RequireFields<QueryWorkspaceMembersArgs, 'workspaceId'>>;
};

export type RefreshPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefreshPayload'] = ResolversParentTypes['RefreshPayload']> = {
  intercomHmac?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Submission'] = ResolversParentTypes['Submission']> = {
  __resolveType: TypeResolveFn<'CameraSubmission' | 'GpsSubmission' | 'TextSubmission', ParentType, ContextType>;
  bonusPoints?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bonuses?: Resolver<Array<ResolversTypes['SubmissionBonus']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Participant'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  liked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mission?: Resolver<ResolversTypes['Mission'], ParentType, ContextType>;
  numLikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  publicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['SubmissionState'], ParentType, ContextType>;
  team?: Resolver<ResolversTypes['Team'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
};

export type SubmissionArchiveResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubmissionArchive'] = ResolversParentTypes['SubmissionArchive']> = {
  cohortId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  grouping?: Resolver<ResolversTypes['SubmissionArchiveGrouping'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['SubmissionArchiveState'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmissionAssetResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubmissionAsset'] = ResolversParentTypes['SubmissionAsset']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  extension?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['SubmissionAssetRole'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uploaded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmissionBonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubmissionBonus'] = ResolversParentTypes['SubmissionBonus']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  submissionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmissionFeedConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubmissionFeedConnection'] = ResolversParentTypes['SubmissionFeedConnection']> = {
  edges?: Resolver<Array<ResolversTypes['SubmissionFeedEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmissionFeedEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubmissionFeedEdge'] = ResolversParentTypes['SubmissionFeedEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Submission'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmissionInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubmissionInfo'] = ResolversParentTypes['SubmissionInfo']> = {
  bonusPoints?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  likes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  missionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  participantName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  points?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  submissionDataString?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  teamName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmissionInfoConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubmissionInfoConnection'] = ResolversParentTypes['SubmissionInfoConnection']> = {
  edges?: Resolver<Array<ResolversTypes['SubmissionInfoEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmissionInfoEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubmissionInfoEdge'] = ResolversParentTypes['SubmissionInfoEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SubmissionInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmissionLikeCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubmissionLikeCount'] = ResolversParentTypes['SubmissionLikeCount']> = {
  likeCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  missionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  submission?: Resolver<ResolversTypes['Submission'], ParentType, ContextType>;
  teamName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  createdAt?: SubscriptionResolver<Maybe<ResolversTypes['DateTime']>, "createdAt", ParentType, ContextType>;
  externalId?: SubscriptionResolver<Maybe<ResolversTypes['String']>, "externalId", ParentType, ContextType>;
  id?: SubscriptionResolver<ResolversTypes['ID'], "id", ParentType, ContextType>;
  plan?: SubscriptionResolver<ResolversTypes['WorkspacePlan'], "plan", ParentType, ContextType>;
  planId?: SubscriptionResolver<ResolversTypes['ID'], "planId", ParentType, ContextType>;
  status?: SubscriptionResolver<Maybe<ResolversTypes['String']>, "status", ParentType, ContextType>;
  termEndDate?: SubscriptionResolver<Maybe<ResolversTypes['DateTime']>, "termEndDate", ParentType, ContextType>;
  termStartDate?: SubscriptionResolver<Maybe<ResolversTypes['DateTime']>, "termStartDate", ParentType, ContextType>;
  updatedAt?: SubscriptionResolver<Maybe<ResolversTypes['DateTime']>, "updatedAt", ParentType, ContextType>;
  workspaceId?: SubscriptionResolver<ResolversTypes['ID'], "workspaceId", ParentType, ContextType>;
};

export type TeamResolvers<ContextType = any, ParentType extends ResolversParentTypes['Team'] = ResolversParentTypes['Team']> = {
  bonusPoints?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bonuses?: Resolver<Array<ResolversTypes['TeamBonus']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isPreCreated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastSubmission?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  maxSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mode?: Resolver<ResolversTypes['TeamMode'], ParentType, ContextType>;
  numSubmissions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  participants?: Resolver<Array<ResolversTypes['Participant']>, ParentType, ContextType>;
  passcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  submissions?: Resolver<Array<ResolversTypes['Submission']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamBonusResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamBonus'] = ResolversParentTypes['TeamBonus']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamPreviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamPreview'] = ResolversParentTypes['TeamPreview']> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mode?: Resolver<ResolversTypes['TeamMode'], ParentType, ContextType>;
  photoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamRankingResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamRanking'] = ResolversParentTypes['TeamRanking']> = {
  rank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  team?: Resolver<ResolversTypes['Team'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamSubmissionCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamSubmissionCount'] = ResolversParentTypes['TeamSubmissionCount']> = {
  submissionCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  team?: Resolver<ResolversTypes['Team'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateLibraryExperienceSearchResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateLibraryExperienceSearchResult'] = ResolversParentTypes['TemplateLibraryExperienceSearchResult']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  experienceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  photoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shareUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateLibraryTagResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateLibraryTag'] = ResolversParentTypes['TemplateLibraryTag']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TextMission'] = ResolversParentTypes['TextMission']> = {
  allowApproximateAnswers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  answers?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  creatorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  draft?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  experience?: Resolver<Maybe<ResolversTypes['Experience']>, ParentType, ContextType>;
  expireAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType, RequireFields<TextMissionExpireAtArgs, 'cohortId'>>;
  expireTrigger?: Resolver<Maybe<ResolversTypes['MissionTrigger']>, ParentType, ContextType>;
  feedVisibility?: Resolver<ResolversTypes['FeedVisibility'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  missionType?: Resolver<ResolversTypes['MissionType'], ParentType, ContextType>;
  numSubmissions?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<TextMissionNumSubmissionsArgs>>;
  points?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  releaseAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType, RequireFields<TextMissionReleaseAtArgs, 'cohortId'>>;
  releaseTrigger?: Resolver<Maybe<ResolversTypes['MissionTrigger']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['MissionStatus']>, ParentType, ContextType, RequireFields<TextMissionStatusArgs, 'cohortId'>>;
  submissions?: Resolver<Array<ResolversTypes['Submission']>, ParentType, ContextType, RequireFields<TextMissionSubmissionsArgs, 'cohortId'>>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextSubmissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TextSubmission'] = ResolversParentTypes['TextSubmission']> = {
  answer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bonusPoints?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bonuses?: Resolver<Array<ResolversTypes['SubmissionBonus']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Participant'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  liked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mission?: Resolver<ResolversTypes['TextMission'], ParentType, ContextType>;
  numLikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  publicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['SubmissionState'], ParentType, ContextType>;
  team?: Resolver<ResolversTypes['Team'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UseCaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UseCase'] = ResolversParentTypes['UseCase']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  accountId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  devices?: Resolver<Array<ResolversTypes['Device']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['UserProfile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProfile'] = ResolversParentTypes['UserProfile']> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  photoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface VoidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Void'], any> {
  name: 'Void';
}

export type WorkspaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Workspace'] = ResolversParentTypes['Workspace']> = {
  customerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultParticipantEmailRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  members?: Resolver<Array<ResolversTypes['WorkspaceMember']>, ParentType, ContextType>;
  myWorkspaceMember?: Resolver<Maybe<ResolversTypes['WorkspaceMember']>, ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['WorkspaceMember'], ParentType, ContextType>;
  photoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scope?: Resolver<ResolversTypes['WorkspaceScope'], ParentType, ContextType>;
  subscription?: Resolver<ResolversTypes['Subscription'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['WorkspaceType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceInvitationResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceInvitation'] = ResolversParentTypes['WorkspaceInvitation']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workspace?: Resolver<ResolversTypes['Workspace'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspaceMemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspaceMember'] = ResolversParentTypes['WorkspaceMember']> = {
  accountId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roleDisplayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  workspace?: Resolver<ResolversTypes['Workspace'], ParentType, ContextType>;
  workspaceDisplayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  workspaceScope?: Resolver<ResolversTypes['WorkspaceScope'], ParentType, ContextType>;
  workspaceType?: Resolver<ResolversTypes['WorkspaceType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspacePlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkspacePlan'] = ResolversParentTypes['WorkspacePlan']> = {
  chargebeeSku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  individualModeEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxConcurrentExperiences?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxParticipantsPerExperience?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxTeamsPerExperience?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxWorkspaceMembers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  priceUsdCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  purchasable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  requiredWorkspaceScope?: Resolver<ResolversTypes['WorkspaceScope'], ParentType, ContextType>;
  requiredWorkspaceType?: Resolver<ResolversTypes['WorkspaceType'], ParentType, ContextType>;
  showUpgradeBanner?: Resolver<ResolversTypes['ShowUpgradeBanner'], ParentType, ContextType>;
  upgradable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Account?: AccountResolvers<ContextType>;
  Addon?: AddonResolvers<ContextType>;
  Asset?: AssetResolvers<ContextType>;
  AuthPayload?: AuthPayloadResolvers<ContextType>;
  Billing?: BillingResolvers<ContextType>;
  Broadcast?: BroadcastResolvers<ContextType>;
  BroadcastRecipient?: BroadcastRecipientResolvers<ContextType>;
  BroadcastTrigger?: BroadcastTriggerResolvers<ContextType>;
  CameraMission?: CameraMissionResolvers<ContextType>;
  CameraSubmission?: CameraSubmissionResolvers<ContextType>;
  Card?: CardResolvers<ContextType>;
  Cohort?: CohortResolvers<ContextType>;
  CohortPreview?: CohortPreviewResolvers<ContextType>;
  CohortStatistics?: CohortStatisticsResolvers<ContextType>;
  Connection?: ConnectionResolvers<ContextType>;
  CreateAssetPayload?: CreateAssetPayloadResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  DeleteCheck?: DeleteCheckResolvers<ContextType>;
  Device?: DeviceResolvers<ContextType>;
  Edge?: EdgeResolvers<ContextType>;
  EducatorProfile?: EducatorProfileResolvers<ContextType>;
  Experience?: ExperienceResolvers<ContextType>;
  ExperienceAsset?: ExperienceAssetResolvers<ContextType>;
  ExperienceInvitation?: ExperienceInvitationResolvers<ContextType>;
  ExperienceManager?: ExperienceManagerResolvers<ContextType>;
  ExperienceManagerRole?: ExperienceManagerRoleResolvers<ContextType>;
  ExperienceTrigger?: ExperienceTriggerResolvers<ContextType>;
  GpsMission?: GpsMissionResolvers<ContextType>;
  GpsSubmission?: GpsSubmissionResolvers<ContextType>;
  HostedCheckoutPage?: HostedCheckoutPageResolvers<ContextType>;
  ImpersonationAuthResponse?: ImpersonationAuthResponseResolvers<ContextType>;
  Mission?: MissionResolvers<ContextType>;
  MissionSubmissionCount?: MissionSubmissionCountResolvers<ContextType>;
  MissionTrigger?: MissionTriggerResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PaginatedTemplateLibraryExperiences?: PaginatedTemplateLibraryExperiencesResolvers<ContextType>;
  Participant?: ParticipantResolvers<ContextType>;
  PasswordValidationResponse?: PasswordValidationResponseResolvers<ContextType>;
  PublicCameraMission?: PublicCameraMissionResolvers<ContextType>;
  PublicCameraSubmission?: PublicCameraSubmissionResolvers<ContextType>;
  PublicExperience?: PublicExperienceResolvers<ContextType>;
  PublicGpsMission?: PublicGpsMissionResolvers<ContextType>;
  PublicGpsSubmission?: PublicGpsSubmissionResolvers<ContextType>;
  PublicMission?: PublicMissionResolvers<ContextType>;
  PublicSubmission?: PublicSubmissionResolvers<ContextType>;
  PublicTeam?: PublicTeamResolvers<ContextType>;
  PublicTextMission?: PublicTextMissionResolvers<ContextType>;
  PublicTextSubmission?: PublicTextSubmissionResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RefreshPayload?: RefreshPayloadResolvers<ContextType>;
  Submission?: SubmissionResolvers<ContextType>;
  SubmissionArchive?: SubmissionArchiveResolvers<ContextType>;
  SubmissionAsset?: SubmissionAssetResolvers<ContextType>;
  SubmissionBonus?: SubmissionBonusResolvers<ContextType>;
  SubmissionFeedConnection?: SubmissionFeedConnectionResolvers<ContextType>;
  SubmissionFeedEdge?: SubmissionFeedEdgeResolvers<ContextType>;
  SubmissionInfo?: SubmissionInfoResolvers<ContextType>;
  SubmissionInfoConnection?: SubmissionInfoConnectionResolvers<ContextType>;
  SubmissionInfoEdge?: SubmissionInfoEdgeResolvers<ContextType>;
  SubmissionLikeCount?: SubmissionLikeCountResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  Team?: TeamResolvers<ContextType>;
  TeamBonus?: TeamBonusResolvers<ContextType>;
  TeamPreview?: TeamPreviewResolvers<ContextType>;
  TeamRanking?: TeamRankingResolvers<ContextType>;
  TeamSubmissionCount?: TeamSubmissionCountResolvers<ContextType>;
  TemplateLibraryExperienceSearchResult?: TemplateLibraryExperienceSearchResultResolvers<ContextType>;
  TemplateLibraryTag?: TemplateLibraryTagResolvers<ContextType>;
  TextMission?: TextMissionResolvers<ContextType>;
  TextSubmission?: TextSubmissionResolvers<ContextType>;
  UseCase?: UseCaseResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserProfile?: UserProfileResolvers<ContextType>;
  Void?: GraphQLScalarType;
  Workspace?: WorkspaceResolvers<ContextType>;
  WorkspaceInvitation?: WorkspaceInvitationResolvers<ContextType>;
  WorkspaceMember?: WorkspaceMemberResolvers<ContextType>;
  WorkspacePlan?: WorkspacePlanResolvers<ContextType>;
};

