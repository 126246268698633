import { z } from 'zod';
import * as dateFns from 'date-fns';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useTranslate } from '../../util/i18n';
import {
  Mission,
  MissionType,
  FeedVisibility,
  FEED_VISIBILITIES,
  MediaType,
  MEDIA_TYPES,
  SUBMISSION_SOURCES,
  SubmissionSource,
  TextMission,
  GpsMission,
  MissionTriggerTiming,
  TRIGGER_TIME_UNITS,
  MISSION_TRIGGER_TIME_ANCHOR,
  TriggerTimeUnit,
  MissionTaskType,
  TriggerTimeAnchorV1,
  MissionStatus,
} from '../../data/models';
import {
  BASE_MISSION_DEFAULTS,
  CAMERA_MISSION_DEFAULTS,
  TEXT_MISSION_DEFAULTS,
  DESCRIPTION_MAX_CHARS,
  DISPLAY_NAME_MAX_CHARS,
  GPS_MISSION_DEFAULTS,
  DEFAULT_FEED_VISIBILITY,
} from './constants';
import { formatMissionLink } from './util';
import { refineLatOrLngValue } from '../gps-location-form';
import { transformEmptyInputToNull } from '../../util/form-util';
import { Nullable } from 'types/util';
import {
  calculateMissionTrigger,
  getMissionTriggerDate,
  isMissionTriggerValid,
} from '../../util/time-util';

type MissionTrigger = {
  timing: MissionTriggerTiming;
  relativeDuration?: Nullable<number>;
  relativeUnit?: Nullable<TriggerTimeUnit>;
  relativeAnchor?: Nullable<TriggerTimeAnchorV1>;
  specificDay?: Nullable<number>;
  specificTime?: Nullable<string>;
};

interface MissionFormBaseInputData {
  id: string;
  missionType: MissionType;
  displayName: string;
  description: string;
  points: string;
  link: string;
  feedVisibility: FeedVisibility;
  imageAssetId: string;
  releaseTrigger: MissionTrigger | null;
  expireTrigger: MissionTrigger | null;
  draft: boolean;
}

export interface MissionFormInputData extends MissionFormBaseInputData {
  // Type-specific fields
  id: Mission['id'];
  allowCameraRollUploads: SubmissionSource;
  mediaType: MediaType;
  answers: TextMission['answers'];
  allowApproximateAnswers: TextMission['allowApproximateAnswers'];
  locationName: GpsMission['locationName'];
  latitude: GpsMission['latitude'];
  longitude: GpsMission['longitude'];
  radius: GpsMission['radius'];
}

type MissionFormBaseOutputData = Omit<MissionFormBaseInputData, 'points'> & {
  points: Mission['points'];
};

export interface CameraMissionFormFields extends MissionFormBaseOutputData {
  allowCameraRollUploads: boolean;
  mediaType: MissionFormInputData['mediaType'];
}

export interface CameraMissionFormOutputData extends CameraMissionFormFields {
  missionType: 'CAMERA';
}

export interface TextMissionFormFields extends MissionFormBaseOutputData {
  answers: TextMission['answers'];
  allowApproximateAnswers: TextMission['allowApproximateAnswers'];
}

export interface TextMissionFormOutputData extends TextMissionFormFields {
  missionType: 'TEXT';
}

export interface GpsMissionFormFields extends MissionFormBaseOutputData {
  locationName: GpsMission['locationName'];
  latitude: GpsMission['latitude'];
  longitude: GpsMission['longitude'];
  radius: GpsMission['radius'];
}

export interface GpsMissionFormOutputData extends GpsMissionFormFields {
  missionType: 'GPS';
}

export type MissionFormOutputData =
  | CameraMissionFormOutputData
  | TextMissionFormOutputData
  | GpsMissionFormOutputData;

export interface UseMissionFormArgs {
  defaultValues: Partial<MissionFormInputData> & Pick<MissionFormInputData, 'missionType'>;
  cohortStartDate?: Nullable<string>;
  cohortEndDate?: Nullable<string>;
  experienceTimezone: string;
  missionStatus: Nullable<MissionStatus>;
}

// eslint-disable-next-line complexity
export const useMissionForm = ({
  defaultValues,
  cohortEndDate,
  cohortStartDate,
  experienceTimezone,
  missionStatus,
}: UseMissionFormArgs) => {
  const { t } = useTranslate('missionForm');

  const missionTriggerSchema = (triggerType: 'releaseTrigger' | 'expireTrigger') =>
    z
      .discriminatedUnion('timing', [
        z.object({
          timing: z.literal('DEFAULT'),
        }),
        z.object({
          timing: z.literal(MissionTriggerTiming.Relative),
          relativeDuration: z
            .number({
              required_error: t('missionTrigger.relativeDuration.errors.required') ?? undefined,
              invalid_type_error: t('missionTrigger.relativeDuration.errors.invalid') ?? undefined,
            })
            .int(t('missionTrigger.relativeDuration.errors.invalid') ?? undefined)
            .min(1, t('missionTrigger.relativeDuration.errors.invalid') ?? undefined),
          relativeUnit: z.enum(TRIGGER_TIME_UNITS),
          relativeAnchor: z.enum(MISSION_TRIGGER_TIME_ANCHOR),
        }),
        z.object({
          timing: z.literal(MissionTriggerTiming.Specific),
          specificDay: z
            .number({
              required_error: t('missionTrigger.specificDay.errors.required') ?? undefined,
            })
            .int()
            .min(1, t('missionTrigger.specificDay.errors.minDay') ?? undefined),
          specificTime: z
            .date({ required_error: t('missionTrigger.specificTime.errors.required') ?? undefined })
            .transform((value) => {
              return dateFns.format(value, 'H:mm');
            })
            .or(z.string()),
        }),
      ])
      .superRefine((values, ctx) => {
        if (values.timing === 'RELATIVE') {
          const result = isMissionTriggerValid({
            startDate: cohortStartDate ? new Date(cohortStartDate) : null,
            endDate: cohortEndDate ? new Date(cohortEndDate) : null,
            experienceTimezone,
          })({
            timing: values.timing,
            relativeAnchor: values.relativeAnchor,
            relativeDuration: values.relativeDuration,
            relativeUnit: values.relativeUnit,
            specificDay: null,
            specificTime: null,
            type:
              triggerType === 'releaseTrigger'
                ? MissionTaskType.ReleaseMission
                : MissionTaskType.ExpireMission,
            status: missionStatus,
            triggerAt: null,
          });

          if (!result.valid) {
            let errorString = '';
            switch (result.messageKey) {
              case 'scheduledTimeInPast':
                errorString = t(`missionTrigger.errors.scheduledTimeInPast`) ?? '';
                break;
              case 'cannotReleaseAfterExpiry':
                errorString = t(`missionTrigger.errors.cannotReleaseAfterExpiry`) ?? '';
                break;
              default:
                errorString = t(`missionTrigger.errors.${triggerType}Outside`) ?? '';
            }
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: errorString,
              path: ['relativeDuration'],
            });
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: errorString,
              path: ['relativeUnit'],
            });
          }
        }
      })
      .superRefine((values, ctx) => {
        if (values.timing === 'SPECIFIC') {
          const result = isMissionTriggerValid({
            startDate: cohortStartDate ? new Date(cohortStartDate) : null,
            endDate: cohortEndDate ? new Date(cohortEndDate) : null,
            experienceTimezone,
          })({
            timing: values.timing,
            specificDay: values.specificDay,
            specificTime: values.specificTime,
            relativeAnchor: null,
            relativeDuration: null,
            relativeUnit: null,
            type:
              triggerType === 'releaseTrigger'
                ? MissionTaskType.ReleaseMission
                : MissionTaskType.ExpireMission,
            status: missionStatus,
            triggerAt: null,
          });

          if (!result.valid) {
            let errorString = '';
            switch (result.messageKey) {
              case 'scheduledTimeInPast':
                errorString = t(`missionTrigger.errors.scheduledTimeInPast`) ?? '';
                break;
              case 'cannotReleaseAfterExpiry':
                errorString = t(`missionTrigger.errors.cannotReleaseAfterExpiry`) ?? '';
                break;
              default:
                errorString = t(`missionTrigger.errors.${triggerType}Outside`) ?? '';
            }
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: errorString,
              path: ['specificDay'],
            });
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: errorString,
              path: ['specificTime'],
            });
          }
        }
      })
      .transform((values) => (values.timing === 'DEFAULT' ? null : values));

  const baseMissionSchema = z.object({
    id: z.string(),
    displayName: z
      .string()
      .min(1, { message: t('displayName.errors.required') ?? undefined })
      .max(DISPLAY_NAME_MAX_CHARS, {
        message: t('displayName.errors.max', { maxChars: DISPLAY_NAME_MAX_CHARS }) ?? undefined,
      }),
    description: z
      .string()
      .min(1, { message: t('description.errors.required') ?? undefined })
      .max(DESCRIPTION_MAX_CHARS, {
        message: t('description.errors.max', { maxChars: DESCRIPTION_MAX_CHARS }) ?? undefined,
      }),
    points: z
      .string()
      .min(0, { message: t('points.errors.required') ?? undefined })
      .transform((val, ctx) => {
        const parsed = parseInt(val);
        if (isNaN(parsed)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('points.errors.number') ?? undefined,
          });
          return z.NEVER;
        }
        return parsed;
      })
      .refine((points) => points >= 0, { message: t('points.errors.minValue') ?? undefined }),
    feedVisibility: z.enum(FEED_VISIBILITIES),
    // Formats the link on submission if necessary
    link: z.string().transform((link) => {
      const formattedLink = formatMissionLink(link);
      return transformEmptyInputToNull(formattedLink);
    }),
    imageAssetId: z.string().optional().transform(transformEmptyInputToNull),
    releaseTrigger: missionTriggerSchema('releaseTrigger'),
    expireTrigger: missionTriggerSchema('expireTrigger'),
    draft: z.boolean().default(false),
  });

  const missionFormSchema = z
    .discriminatedUnion('missionType', [
      z
        .object({
          missionType: z.literal('CAMERA'),
          allowCameraRollUploads: z
            .enum(SUBMISSION_SOURCES)
            .transform((val) => val === 'LIVE_AND_LIBRARY'),
          mediaType: z.enum(MEDIA_TYPES),
        })
        .merge(baseMissionSchema),
      z
        .object({
          missionType: z.literal('TEXT'),
          answers: z.array(z.string()),
          allowApproximateAnswers: z.boolean(),
        })
        .merge(baseMissionSchema),
      z
        .object({
          missionType: z.literal('GPS'),
          locationName: z.string().transform(transformEmptyInputToNull),
          latitude: z.string().min(1).refine(refineLatOrLngValue('lat')),
          longitude: z.string().min(1).refine(refineLatOrLngValue('lng')),
          radius: z.number().int(),
        })
        .merge(baseMissionSchema),
    ])
    // eslint-disable-next-line complexity
    .superRefine((values, context) => {
      let releaseAt: Date | undefined;
      let expireAt: Date | undefined;

      if (values.releaseTrigger?.timing === MissionTriggerTiming.Relative) {
        releaseAt = calculateMissionTrigger({
          referenceDate: getMissionTriggerDate(
            cohortStartDate,
            cohortEndDate,
            values.releaseTrigger.relativeAnchor,
          ),
          relativeAnchor: values.releaseTrigger.relativeAnchor,
          relativeDuration: values.releaseTrigger.relativeDuration,
          relativeUnit: values.releaseTrigger.relativeUnit,
          timing: MissionTriggerTiming.Relative,
          specificDay: null,
          specificTime: null,
          experienceTimezone,
        });
      }
      if (values.expireTrigger?.timing === MissionTriggerTiming.Relative) {
        expireAt = calculateMissionTrigger({
          referenceDate: getMissionTriggerDate(
            cohortStartDate,
            cohortEndDate,
            values.expireTrigger.relativeAnchor,
          ),
          relativeAnchor: values.expireTrigger.relativeAnchor,
          relativeDuration: values.expireTrigger.relativeDuration,
          relativeUnit: values.expireTrigger.relativeUnit,
          timing: MissionTriggerTiming.Relative,
          specificDay: null,
          specificTime: null,
          experienceTimezone,
        });
      }
      if (values.releaseTrigger?.timing === MissionTriggerTiming.Specific) {
        releaseAt = calculateMissionTrigger({
          referenceDate: getMissionTriggerDate(cohortStartDate, cohortEndDate),
          specificDay: values.releaseTrigger.specificDay,
          specificTime: values.releaseTrigger.specificTime,
          timing: MissionTriggerTiming.Specific,
          relativeAnchor: null,
          relativeDuration: null,
          relativeUnit: null,
          experienceTimezone,
        });
      }
      if (values.expireTrigger?.timing === MissionTriggerTiming.Specific) {
        expireAt = calculateMissionTrigger({
          referenceDate: getMissionTriggerDate(cohortStartDate, cohortEndDate),
          specificDay: values.expireTrigger.specificDay,
          specificTime: values.expireTrigger.specificTime,
          timing: MissionTriggerTiming.Specific,
          relativeAnchor: null,
          relativeDuration: null,
          relativeUnit: null,
          experienceTimezone,
        });
      }

      const bothAfterStart =
        values.releaseTrigger?.timing === MissionTriggerTiming.Relative &&
        values.releaseTrigger.relativeAnchor === TriggerTimeAnchorV1.AfterStart &&
        values.expireTrigger?.timing === MissionTriggerTiming.Relative &&
        values.expireTrigger.relativeAnchor === TriggerTimeAnchorV1.AfterStart;

      const bothBeforeEnd =
        values.releaseTrigger?.timing === MissionTriggerTiming.Relative &&
        values.releaseTrigger.relativeAnchor === TriggerTimeAnchorV1.BeforeEnd &&
        values.expireTrigger?.timing === MissionTriggerTiming.Relative &&
        values.expireTrigger.relativeAnchor === TriggerTimeAnchorV1.BeforeEnd;

      const bothSpecific =
        values.releaseTrigger?.timing === MissionTriggerTiming.Specific &&
        values.expireTrigger?.timing === MissionTriggerTiming.Specific;

      // Without a cohort start/end, we use the current time as the placeholder reference date
      // which works to compare release/expiry being in the right chronological order only if
      // they use the same timing (specific, relative after start, or relative before end)
      if (
        releaseAt &&
        expireAt &&
        ((cohortStartDate && cohortEndDate) || bothAfterStart || bothBeforeEnd || bothSpecific)
      ) {
        if (dateFns.isEqual(releaseAt, expireAt) || dateFns.isAfter(releaseAt, expireAt)) {
          context.addIssue({
            code: z.ZodIssueCode.invalid_date,
            message:
              t('missionTrigger.errors.releaseAfterExpiry') ??
              'Release must take place before Expiry',
            path: ['releaseTrigger'],
          });
        }
        if (dateFns.isEqual(expireAt, releaseAt) || dateFns.isBefore(expireAt, releaseAt)) {
          context.addIssue({
            code: z.ZodIssueCode.invalid_date,
            message:
              t('missionTrigger.errors.expireBeforeRelease') ??
              'Expiry must take place after Release',
            path: ['expireTrigger'],
          });
        }
      }
    });

  return useForm<MissionFormInputData, unknown, MissionFormOutputData>({
    mode: 'onChange',
    defaultValues: {
      id: defaultValues.id ?? BASE_MISSION_DEFAULTS.ID,
      missionType: defaultValues.missionType,
      displayName: defaultValues.displayName ?? BASE_MISSION_DEFAULTS.DISPLAY_NAME,
      description: defaultValues.description ?? BASE_MISSION_DEFAULTS.DESCRIPTION,
      points: defaultValues.points ?? BASE_MISSION_DEFAULTS.POINTS,
      feedVisibility:
        defaultValues.feedVisibility ?? DEFAULT_FEED_VISIBILITY[defaultValues.missionType],
      link: defaultValues.link ?? BASE_MISSION_DEFAULTS.LINK,
      allowCameraRollUploads:
        defaultValues.allowCameraRollUploads ?? CAMERA_MISSION_DEFAULTS.ALLOW_CAMERA_ROLL_UPLOADS,
      mediaType: defaultValues.mediaType ?? CAMERA_MISSION_DEFAULTS.MEDIA_TYPE,
      answers: defaultValues.answers ?? TEXT_MISSION_DEFAULTS.ANSWERS,
      allowApproximateAnswers:
        defaultValues.allowApproximateAnswers ?? TEXT_MISSION_DEFAULTS.ALLOW_APPROXIMATE_ANSWERS,
      locationName: defaultValues.locationName ?? GPS_MISSION_DEFAULTS.LOCATION_NAME,
      latitude: defaultValues.latitude ?? GPS_MISSION_DEFAULTS.LATITUDE,
      longitude: defaultValues.longitude ?? GPS_MISSION_DEFAULTS.LONGITUDE,
      radius: defaultValues.radius ?? GPS_MISSION_DEFAULTS.RADIUS,
      releaseTrigger: {
        timing:
          defaultValues.releaseTrigger?.timing ?? BASE_MISSION_DEFAULTS.MISSION_TRIGGER.TIMING,
        relativeDuration: defaultValues.releaseTrigger?.relativeDuration,
        relativeAnchor:
          defaultValues.releaseTrigger?.relativeAnchor ??
          BASE_MISSION_DEFAULTS.MISSION_TRIGGER.ANCHOR,
        relativeUnit:
          defaultValues.releaseTrigger?.relativeUnit ??
          BASE_MISSION_DEFAULTS.MISSION_TRIGGER.TIME_UNIT,
        specificDay: defaultValues.releaseTrigger?.specificDay,
        specificTime: defaultValues.releaseTrigger?.specificTime,
      },
      expireTrigger: {
        timing: defaultValues.expireTrigger?.timing ?? BASE_MISSION_DEFAULTS.MISSION_TRIGGER.TIMING,
        relativeDuration: defaultValues.expireTrigger?.relativeDuration,
        relativeAnchor:
          defaultValues.expireTrigger?.relativeAnchor ??
          BASE_MISSION_DEFAULTS.MISSION_TRIGGER.ANCHOR,
        relativeUnit:
          defaultValues.expireTrigger?.relativeUnit ??
          BASE_MISSION_DEFAULTS.MISSION_TRIGGER.TIME_UNIT,
        specificDay: defaultValues.expireTrigger?.specificDay,
        specificTime: defaultValues.expireTrigger?.specificTime,
      },
      draft: defaultValues.draft ?? BASE_MISSION_DEFAULTS.DRAFT,
    },
    resolver: zodResolver(missionFormSchema),
  });
};
