import { TimeInput, Text } from '@goosechase/ui';
import { Controller, Control, useFormState, UseFormTrigger } from 'react-hook-form';

import type { BroadcastFormInputData } from '../use-broadcast-form';
import { NumberInput } from '../../number-input';
import { useTranslate } from 'util/i18n';
import { convertSpecificTimeToDateObj } from '../../../util/time-util';
import { SubfieldArrow } from 'components/subfield-arrow';
import { Trans } from 'react-i18next';

interface BroadcastTriggerSpecificFieldsProps {
  control: Control<BroadcastFormInputData>;
  trigger: UseFormTrigger<BroadcastFormInputData>;
  isInFuture: boolean;
}

export const BroadcastTriggerSpecificFields = ({
  control,
  isInFuture,
  trigger,
}: BroadcastTriggerSpecificFieldsProps) => {
  const { t } = useTranslate('broadcastForm.broadcastTrigger');
  const { errors } = useFormState({ control });
  return (
    <div className="flex flex-col gap-3 w-full">
      <div className="flex gap-2 items-center w-full">
        <div className="w-[40px] h-[40px]">
          <SubfieldArrow />
        </div>
        <Text>{t('at')}</Text>
        <div className="flex flex-row">
          <Controller
            control={control}
            name={`specificTime`}
            render={({
              field: { name, value, onChange: onChangeTime },
              fieldState: { isDirty, error },
            }) => {
              let initialValue;
              if (typeof value === 'string') {
                initialValue = convertSpecificTimeToDateObj(value);
              } else {
                initialValue = value ? new Date(value) : undefined;
              }
              return (
                <TimeInput
                  className="w-max-[120px]"
                  placeholder={t('specificTime.placeholder') ?? undefined}
                  error=""
                  responseState={
                    error || Boolean(errors.specificTime?.message) ? 'error' : undefined
                  }
                  isDirty={isDirty}
                  name={name}
                  onChangeValue={(val) => {
                    onChangeTime(val);
                    trigger();
                  }}
                  value={initialValue}
                />
              );
            }}
          />
        </div>
        <Text className="whitespace-nowrap">{t('onDay')}</Text>
        <Controller
          name={`specificDay`}
          control={control}
          render={({
            field: { name, value, onChange: onChangeDay, onBlur },
            fieldState: { error },
          }) => (
            <NumberInput
              label=""
              placeholder={t('specificDay.placeholder') ?? undefined}
              name={name}
              onBlur={onBlur}
              onChange={(val) => {
                onChangeDay(val);
                trigger();
              }}
              value={value ?? null}
              error={Boolean(error) || Boolean(errors.specificDay?.message)}
              min={1}
            />
          )}
        />
      </div>
      {errors.specificTime?.message || errors.specificDay?.message ? (
        <div className="bg-vibrantRed-10 border border-vibrantRed rounded-lg p-4 ml-10">
          <Text size="md" className="">
            <span
              dangerouslySetInnerHTML={{
                __html: errors.specificTime?.message || errors.specificDay?.message || '',
              }}
            />
          </Text>
        </div>
      ) : isInFuture ? null : (
        <div className="bg-yellowOrange-10 border border-yellowOrange rounded-lg p-4 ml-10">
          <Trans>{t('warnings.past')}</Trans>
        </div>
      )}
    </div>
  );
};
