import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  TriggerTimeUnit,
  TriggerTimeAnchor,
  MissionTriggerTiming,
  BroadcastTriggerTiming,
  TriggerTimeAnchorV1,
  BroadcastTrigger,
  MissionStatus,
  MissionTaskType,
} from '../data/models';
import {
  set,
  addMinutes,
  addHours,
  addDays,
  addWeeks,
  addMonths,
  add,
  sub,
  isBefore,
  isAfter,
  format,
  isEqual,
  roundToNearestMinutes,
} from 'date-fns';
import { Nullable } from 'types/util';

export const TIME_UNIT_TO_TRANSLATION_KEY_MAP: Record<TriggerTimeUnit, string> = {
  MINUTES: 'MINUTES',
  HOURS: 'HOURS',
  DAYS: 'DAYS',
  WEEKS: 'WEEKS',
  MONTHS: 'MONTHS',
};

export const mergeDateAndTime = (date: Date, time: Date) => {
  return set(date, {
    hours: time.getHours(),
    minutes: time.getMinutes(),
  });
};

export const calculateRelativeTriggerTime = (
  experienceStart: Date,
  relativeUnit: TriggerTimeUnit,
  relativeDuration: number,
): Date => {
  const relativeDate = new Date(experienceStart);
  if (!(relativeUnit && relativeDuration)) {
    throw new Error('Cannot calculate relative time without unit and duration');
  }
  switch (relativeUnit) {
    case 'MINUTES': {
      return addMinutes(experienceStart, relativeDuration);
    }
    case 'HOURS': {
      return addHours(experienceStart, relativeDuration);
    }
    case 'DAYS': {
      return addDays(experienceStart, relativeDuration);
    }
    case 'WEEKS': {
      return addWeeks(experienceStart, relativeDuration);
    }
    case 'MONTHS': {
      return addMonths(experienceStart, relativeDuration);
    }
  }
  return relativeDate;
};

export const systemTimezoneAbbreviation = new Intl.DateTimeFormat(undefined, {
  timeZoneName: 'short',
})
  .formatToParts(new Date())
  .find((part) => part.type == 'timeZoneName')?.value;

export const abbreviateTimespan = (timespan: string) => {
  return timespan
    .replace(/ day(s)?/, 'd')
    .replace(/ hour(s)?/, 'h')
    .replace(/ minute(s)?/, 'm')
    .replace(/ second(s)?/, 's');
};

export type MissionTrigger = {
  timing: MissionTriggerTiming;
  relativeAnchor: Nullable<TriggerTimeAnchorV1>;
  relativeDuration: Nullable<number>;
  relativeUnit: Nullable<TriggerTimeUnit>;
  specificDay: Nullable<number>;
  specificTime: Nullable<string>;
  status: Nullable<MissionStatus>;
  type: MissionTaskType;
  triggerAt: Nullable<Date>;
  draft?: boolean;
};

export const MISSION_TIME_ANCHOR_TO_TRANSLATION_KEY_MAP: Record<TriggerTimeAnchorV1, string> = {
  [TriggerTimeAnchorV1.AfterStart]: 'afterStart',
  [TriggerTimeAnchorV1.BeforeEnd]: 'beforeEnd',
};

export const TIME_ANCHOR_TO_TRANSLATION_KEY_MAP: Record<TriggerTimeAnchor, string> = {
  [TriggerTimeAnchor.AfterStart]: 'afterStart',
  [TriggerTimeAnchor.AtStart]: 'atStart',
  [TriggerTimeAnchor.BeforeStart]: 'beforeStart',
  [TriggerTimeAnchor.AfterEnd]: 'afterEnd',
  [TriggerTimeAnchor.AtEnd]: 'atEnd',
  [TriggerTimeAnchor.BeforeEnd]: 'beforeEnd',
};

// specificTime expected format of 'HH:MM:SS'
export const convertSpecificTimeToDateObj = (specificTime: string | Date): Date => {
  // when specific time is a value from the mission form, it will already be a date
  if (specificTime instanceof Date) {
    return specificTime;
  }
  const [hours, minutes] = specificTime.split(':').map((v) => Number(v));
  return set(new Date(), { hours, minutes });
};

// specificTime expected format of 'HH:MM:SS'
export const formatSpecificTime = (specificTime: string): string =>
  format(convertSpecificTimeToDateObj(specificTime), 'h:mm aa');

interface AddDaysAndSetHoursAndMinutesParams {
  date: Date;
  specificDay: number;
  hours: number;
  minutes: number;
}

const addDaysAndSetHoursAndMinutes = ({
  date,
  specificDay,
  hours,
  minutes,
}: AddDaysAndSetHoursAndMinutesParams) => {
  return set(
    add(date, {
      days: specificDay - 1,
    }),
    { hours, minutes, seconds: 0, milliseconds: 0 },
  );
};

interface CalculateSpecificDateTimeParams {
  specificTime: string | Date;
  specificDay: number;
  experienceTimezone: string;
  triggerDate: Date;
}

export const calculateSpecificDateTime = ({
  specificTime,
  specificDay,
  experienceTimezone,
  triggerDate,
}: CalculateSpecificDateTimeParams) => {
  let hours, minutes;
  if (typeof specificTime === 'object') {
    hours = specificTime.getHours();
    minutes = specificTime.getMinutes();
  } else {
    [hours, minutes] = specificTime.split(':').map((v) => Number(v));
  }

  // Convert triggerDate to the same date in the experience's timezone
  const triggerDateInExperienceTz = utcToZonedTime(triggerDate, experienceTimezone);

  // Add days and set the time to the experience timezone date
  const specificDateTimeInExperienceTz = addDaysAndSetHoursAndMinutes({
    date: triggerDateInExperienceTz,
    specificDay,
    hours,
    minutes,
  });

  // Return the specificDateTime converted to UTC
  return zonedTimeToUtc(specificDateTimeInExperienceTz, experienceTimezone);
};

interface CalculateMissionTriggerParams {
  timing: MissionTriggerTiming;
  referenceDate: Date;
  relativeAnchor?: Nullable<TriggerTimeAnchorV1>;
  relativeUnit?: Nullable<TriggerTimeUnit>;
  relativeDuration?: Nullable<number>;
  specificTime?: Nullable<string>;
  specificDay?: Nullable<number>;
  experienceTimezone: string;
}

export const calculateMissionTrigger = ({
  timing,
  referenceDate,
  relativeAnchor,
  relativeUnit,
  relativeDuration,
  specificTime,
  specificDay,
  experienceTimezone,
}: CalculateMissionTriggerParams) => {
  if (
    timing === MissionTriggerTiming.Relative &&
    relativeUnit &&
    typeof relativeDuration === 'number'
  ) {
    if (relativeAnchor === TriggerTimeAnchorV1.AfterStart) {
      return add(referenceDate, {
        [relativeUnit.toLowerCase()]: relativeDuration,
      });
    }
    return sub(referenceDate, {
      [relativeUnit.toLowerCase()]: relativeDuration,
    });
  } else if (
    timing === MissionTriggerTiming.Specific &&
    specificTime &&
    typeof specificDay === 'number'
  ) {
    return calculateSpecificDateTime({
      specificTime,
      specificDay,
      triggerDate: referenceDate,
      experienceTimezone,
    });
  }
};

interface IsMissionTriggerValidParams {
  startDate?: Nullable<Date>;
  endDate?: Nullable<Date>;
  experienceTimezone: string;
}

export const isMissionTriggerValid =
  ({ startDate, endDate, experienceTimezone }: IsMissionTriggerValidParams) =>
  // eslint-disable-next-line complexity
  (values: MissionTrigger) => {
    // if either start or end don't exist, this validations should be skipped
    if (!endDate || !startDate || values.draft) {
      return {
        valid: true,
        messageKey: undefined,
      };
    }

    let referenceDate = startDate;

    if (
      values.timing === MissionTriggerTiming.Relative &&
      values.relativeAnchor === TriggerTimeAnchorV1.BeforeEnd
    ) {
      referenceDate = endDate;
    }

    const triggerDate = calculateMissionTrigger({
      referenceDate: referenceDate,
      ...values,
      experienceTimezone,
    });

    if (!triggerDate) {
      return {
        valid: false,
        messageKey: 'noTriggerDate',
      };
    }

    const now = new Date();
    const isInBounds = isAfter(triggerDate, startDate) && isBefore(triggerDate, endDate);

    if (!isInBounds) {
      return {
        valid: false,
        messageKey: 'outsideStartEnd',
      };
    }

    const isInPast = isBefore(triggerDate, now);
    let isValid = true;
    let messageKey = '';

    if (values.type === MissionTaskType.ReleaseMission) {
      if (!values.status) {
        // if there's no status then the experience hasn't started yet
        // so as long as it's in the future, it's fine
        if (isInPast) {
          isValid = false;
          messageKey = 'scheduledTimeInPast';
        }
      } else if (values.status === MissionStatus.Available) {
        // this is a release trigger and the mission is released
        // if it's in the future then it will be hidden and rerun
        if (isInPast) {
          // if the calculated trigger date matches the mission's release date
          // then the trigger isn't affected by any current changes and is valid
          if (
            values.triggerAt &&
            !isEqual(roundToNearestMinutes(values.triggerAt), roundToNearestMinutes(triggerDate))
          ) {
            isValid = false;
            messageKey = 'scheduledTimeInPast';
          }
        }
      } else if (values.status === MissionStatus.Hidden) {
        // hasn't been released yet, so trigger should be in the future to be valid
        if (isInPast) {
          isValid = false;
          messageKey = 'scheduledTimeInPast';
        }
      } else if (values.status === MissionStatus.Expired) {
        // has been expired, release should not be possible after expiry
        // but as long as it's in the past, it's fine
        if (!isInPast) {
          isValid = false;
          messageKey = 'cannotReleaseAfterExpiry';
        }
      }
    }

    if (values.type === MissionTaskType.ExpireMission) {
      if (!values.status) {
        // if there's no status then the experience hasn't started yet
        // so as long as it's in the future, it's fine
        if (isInPast) {
          isValid = false;
          messageKey = 'scheduledTimeInPast';
        }
      } else if (values.status === MissionStatus.Available) {
        // this is an expiry trigger and the mission is released
        // so as long as it's in the future, it's fine
        if (isInPast) {
          isValid = false;
          messageKey = 'scheduledTimeInPast';
        }
      } else if (values.status === MissionStatus.Hidden) {
        // hasn't been released yet, so trigger should be in the future to be valid
        if (isInPast) {
          isValid = false;
          messageKey = 'scheduledTimeInPast';
        }
      } else if (values.status === MissionStatus.Expired) {
        // has been expired, this trigger is redundant
        // if it's in the past, then it won't be rerun
        // if it's in the future, it might be after a newly updated release trigger, so it will be valid
        if (isInPast) {
          // if the calculated trigger date matches the mission's expiry date
          // then the trigger isn't affected by any current changes and is valid
          if (
            values.triggerAt &&
            !isEqual(roundToNearestMinutes(values.triggerAt), roundToNearestMinutes(triggerDate))
          ) {
            isValid = false;
            messageKey = 'scheduledTimeInPast';
          }
        }
      }
    }

    return {
      valid: isValid,
      messageKey,
    };
  };

interface IsBroadcastTriggerValidParams {
  trigger: Omit<BroadcastTrigger, 'createdAt' | 'updatedAt'>;
  startDate?: Nullable<string>;
  endDate?: Nullable<string>;
  experienceTimezone: string;
}

// eslint-disable-next-line complexity
export const isBroadcastTriggerValid = ({
  trigger,
  startDate,
  endDate,
  experienceTimezone,
}: IsBroadcastTriggerValidParams) => {
  if (trigger.relativeUnit && typeof trigger.relativeDuration === 'number') {
    if (trigger.relativeAnchor === 'AFTER_START') {
      if (!endDate || !startDate) {
        return true;
      }
      return isBefore(
        add(new Date(startDate), {
          [trigger.relativeUnit.toLowerCase()]: trigger.relativeDuration,
        }),
        new Date(endDate),
      );
    } else if (trigger.relativeAnchor === 'BEFORE_END') {
      if (!endDate || !startDate) {
        return true;
      }
      return isBefore(
        new Date(startDate),
        sub(new Date(endDate), {
          [trigger.relativeUnit.toLowerCase()]: trigger.relativeDuration,
        }),
      );
    } else if (trigger.relativeAnchor === 'BEFORE_START') {
      // check if more than 30 days before
      if (trigger.relativeUnit === TriggerTimeUnit.Minutes) {
        return trigger.relativeDuration <= 43200;
      } else if (trigger.relativeUnit === TriggerTimeUnit.Hours) {
        return trigger.relativeDuration <= 720;
      } else if (trigger.relativeUnit === TriggerTimeUnit.Days) {
        return trigger.relativeDuration <= 30;
      }
    } else if (trigger.relativeAnchor === 'AT_START' || trigger.relativeAnchor === 'AT_END') {
      return true;
    } else if (trigger.relativeAnchor === 'AFTER_END') {
      // check if more than 30 days after
      if (trigger.relativeUnit === TriggerTimeUnit.Minutes) {
        return trigger.relativeDuration <= 43200;
      } else if (trigger.relativeUnit === TriggerTimeUnit.Hours) {
        return trigger.relativeDuration <= 720;
      } else if (trigger.relativeUnit === TriggerTimeUnit.Days) {
        return trigger.relativeDuration <= 30;
      }
    } else {
      return false;
    }
  } else if (trigger.specificTime && typeof trigger.specificDay === 'number') {
    if (!endDate || !startDate) {
      return true;
    }

    const specificDate = calculateSpecificDateTime({
      specificDay: trigger.specificDay,
      specificTime: trigger.specificTime,
      triggerDate: new Date(startDate),
      experienceTimezone,
    });
    const afterStart = isBefore(new Date(startDate), specificDate);
    const beforeEnd = isBefore(specificDate, new Date(endDate));
    return afterStart && beforeEnd;
  }

  return true;
};

export const getMissionTriggerDate = (
  startDate?: Nullable<string>,
  endDate?: Nullable<string>,
  relativeAnchor?: TriggerTimeAnchorV1,
) => {
  const now = new Date();
  if (!startDate || !endDate) {
    return now;
  }
  if (relativeAnchor === TriggerTimeAnchorV1.BeforeEnd) {
    return new Date(endDate);
  }
  return new Date(startDate);
};

export const getTriggerDate = (
  startDate?: Nullable<string>,
  endDate?: Nullable<string>,
  relativeAnchor?: TriggerTimeAnchor,
) => {
  const now = new Date();
  if (!startDate || !endDate) {
    return now;
  }
  if (
    relativeAnchor === TriggerTimeAnchor.BeforeEnd ||
    relativeAnchor === TriggerTimeAnchor.AtEnd ||
    relativeAnchor === TriggerTimeAnchor.AfterEnd
  ) {
    return new Date(endDate);
  }
  return new Date(startDate);
};

interface CalculateTriggerParams {
  timing: MissionTriggerTiming | BroadcastTriggerTiming;
  triggerDate: Date;
  relativeAnchor: Nullable<TriggerTimeAnchor>;
  relativeUnit: Nullable<TriggerTimeUnit>;
  relativeDuration: Nullable<number>;
  specificTime: Nullable<string>;
  specificDay: Nullable<number>;
  experienceTimezone: string;
}

export const calculateTrigger = ({
  timing,
  triggerDate,
  relativeAnchor,
  relativeUnit,
  relativeDuration,
  specificTime,
  specificDay,
  experienceTimezone,
}: CalculateTriggerParams) => {
  if (timing === (MissionTriggerTiming.Relative || BroadcastTriggerTiming.Relative)) {
    if (
      relativeAnchor === TriggerTimeAnchor.AtStart ||
      relativeAnchor === TriggerTimeAnchor.AtEnd
    ) {
      return triggerDate;
    }
    if (relativeUnit && typeof relativeDuration === 'number') {
      if (relativeAnchor === TriggerTimeAnchor.AfterStart) {
        return add(triggerDate, {
          [relativeUnit.toLowerCase()]: relativeDuration,
        });
      } else if (relativeAnchor === TriggerTimeAnchor.BeforeStart) {
        return sub(triggerDate, {
          [relativeUnit.toLowerCase()]: relativeDuration,
        });
      } else if (relativeAnchor === TriggerTimeAnchor.AfterEnd) {
        return add(triggerDate, {
          [relativeUnit.toLowerCase()]: relativeDuration,
        });
      }
      return sub(triggerDate, {
        [relativeUnit.toLowerCase()]: relativeDuration,
      });
    } else {
      throw new Error('Could not determine trigger time');
    }
  } else if (
    timing === (MissionTriggerTiming.Specific || BroadcastTriggerTiming.Specific) &&
    specificTime &&
    typeof specificDay === 'number'
  ) {
    return calculateSpecificDateTime({
      specificTime,
      specificDay,
      triggerDate,
      experienceTimezone,
    });
  } else {
    throw new Error('Could not determine trigger time');
  }
};

export const allMissionTriggersValid = (
  startDate: Date,
  endDate: Date,
  missionTriggers: MissionTrigger[],
  experienceTimezone: string,
) => {
  return missionTriggers.every((missionTrigger) => {
    return isMissionTriggerValid({ startDate, endDate, experienceTimezone })(missionTrigger).valid;
  });
};

export const checkExperienceOverlaps = (
  startDateTime: Nullable<Date>,
  endDateTime: Nullable<Date>,
  workspaceExperiences: {
    startDate: Nullable<string>;
    endDate: Nullable<string>;
  }[],
) =>
  workspaceExperiences?.map((experience) => {
    // Check if startDateTime and endDateTime are provided
    if (startDateTime && endDateTime && experience.startDate && experience.endDate) {
      const overlapStartAndEnd =
        startDateTime <= new Date(experience.startDate) &&
        endDateTime >= new Date(experience.endDate);

      return {
        overlapStart:
          startDateTime >= new Date(experience.startDate) &&
          startDateTime <= new Date(experience.endDate),
        overlapEnd:
          overlapStartAndEnd ||
          (endDateTime >= new Date(experience.startDate) &&
            endDateTime <= new Date(experience.endDate)),
      };
    } else if (startDateTime && experience.startDate && experience.endDate) {
      // Check for overlap when only startDateTime is provided
      return {
        overlapStart:
          startDateTime >= new Date(experience.startDate) &&
          startDateTime <= new Date(experience.endDate),
        overlapEnd: false,
      };
    }

    // No overlap
    return { overlapStart: false, overlapEnd: false };
  }) ?? [];

export const allBroadcastTriggersValid = (
  startDate: Date,
  endDate: Date,
  broadcastTriggers: Omit<BroadcastTrigger, 'createdAt' | 'updatedAt'>[],
  experienceTimezone: string,
) =>
  broadcastTriggers.every((broadcastTrigger) => {
    return isBroadcastTriggerValid({
      trigger: broadcastTrigger,
      startDate: startDate.toString(),
      endDate: endDate.toString(),
      experienceTimezone,
    });
  });
