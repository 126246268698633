import { mergeClasses, Avatar, Icon, IconButton, Text, Tooltip } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import { Trans } from 'react-i18next';
import { Broadcast, BroadcastRecipientType, TeamMode, TriggerTimeAnchor } from 'data/models';
import { MoreButton } from './more-button.component';
import { format as formatDate, parseISO as parseDate } from 'date-fns';
import {
  formatSpecificTime,
  systemTimezoneAbbreviation,
  TIME_ANCHOR_TO_TRANSLATION_KEY_MAP,
} from 'util/time-util';
import { createRef, useEffect, useState } from 'react';
import { selectBroadcast, useDeleteBroadcastMutation } from 'data/broadcasts';
import { LoadingSpinner } from 'components/loading-spinner';
import { displayToast } from 'services/toast';
import { useDispatch } from 'react-redux';

interface BroadcastStatusIconProps {
  sent: boolean;
  isValid: boolean;
  errorMessage?: string;
}

const BroadcastStatusIcon = ({ sent, isValid, errorMessage = '' }: BroadcastStatusIconProps) => {
  if (isValid) {
    return sent ? (
      <Icon color="black" name="Success" className="mb-0.5 mr-2 inline" />
    ) : (
      <Icon color="black" name="Announcement" className="mb-1 mr-2 inline" size={16} />
    );
  } else {
    return (
      <div className="inline-block">
        <Tooltip
          body={errorMessage}
          variant="dark"
          className={mergeClasses('w-[220px]')}
          offset={10}>
          <Icon color="white" name="Warning" className="mr-2 inline" size={20} />
        </Tooltip>
      </div>
    );
  }
};

interface TimingTextProps {
  broadcast: Broadcast;
}

// eslint-disable-next-line complexity
const TimingText = ({ broadcast }: TimingTextProps) => {
  const { t } = useTranslate('pages.broadcasts.list.trigger');
  const parsedDate = parseDate(broadcast.updatedAt);
  const formattedSent = formatDate(parsedDate, "EE, LLL d yyyy 'at' h:mm aa");

  if (broadcast.type === 'IMMEDIATE') {
    return <span>{formattedSent}</span>;
  } else {
    if (broadcast.trigger) {
      const formattedScheduled = broadcast.triggerAt
        ? formatDate(broadcast.triggerAt, "EE, LLL d yyyy 'at' h:mm aa")
        : '';

      const sentTimeText = (
        <span className="text-paragraph-sm">
          (
          <>
            {broadcast.state === 'SENT' ? formattedSent : formattedScheduled}{' '}
            {systemTimezoneAbbreviation}
          </>
          )
        </span>
      );

      if (broadcast.trigger.timing === 'RELATIVE') {
        const { relativeAnchor, relativeDuration, relativeUnit } = broadcast.trigger;

        const duration =
          relativeAnchor === TriggerTimeAnchor.AtStart || relativeAnchor === TriggerTimeAnchor.AtEnd
            ? ''
            : t(`${relativeUnit?.toLowerCase()}_${relativeDuration === 1 ? 'one' : 'other'}`, {
                count: relativeDuration ?? 0,
              });

        const anchorText = relativeAnchor
          ? t(`${TIME_ANCHOR_TO_TRANSLATION_KEY_MAP[relativeAnchor]}.scheduled`)
          : '';

        return (
          <span>
            <Trans>
              {t(
                broadcast.state === 'SENT'
                  ? 'schedule.relative.complete'
                  : 'schedule.relative.pending',
                {
                  duration,
                  anchor: anchorText,
                },
              )}{' '}
              {broadcast.triggerAt ? sentTimeText : ''}
            </Trans>
          </span>
        );
      } else {
        const { specificDay, specificTime } = broadcast.trigger;
        const timeToDisplay = specificTime ? formatSpecificTime(specificTime) : specificTime;

        return (
          <span>
            <Trans>
              {t(
                broadcast.state === 'SENT'
                  ? 'schedule.specific.complete'
                  : 'schedule.specific.pending',
                {
                  time: timeToDisplay,
                  day: specificDay,
                },
              )}{' '}
              {broadcast.triggerAt ? sentTimeText : ''}
            </Trans>
          </span>
        );
      }
    } else {
      return <></>;
    }
  }
};

const MIN_HEIGHT_AFTER_LINE_CLAMP = 60;

export interface BroadcastListItemProps {
  broadcastTriggerId: string;
  broadcast: Broadcast;
  message: string;
  recipientType: BroadcastRecipientType;
  teamName?: string;
  teamPhoto?: string;
  teamMode?: TeamMode;
  sent: boolean;
  isValid: boolean;
}

export const BroadcastListItem = ({
  message,
  broadcast,
  recipientType,
  teamName,
  teamPhoto,
  teamMode,
  sent,
  isValid,
}: BroadcastListItemProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslate('pages.broadcasts.list');
  const [deleteBroadcast, { isLoading: isLoadingDeleteBroadcast }] = useDeleteBroadcastMutation();

  const [open, setOpen] = useState(false);
  const [shouldDisplayCaret, setShouldDisplayCaret] = useState(false);
  const ref = createRef<HTMLParagraphElement>();

  const toggleDescription = () => {
    setOpen((value) => !value);
  };

  const handleEdit = () => {
    dispatch(selectBroadcast({ ...broadcast, triggerAt: null }));
  };

  const handleDelete = async () => {
    try {
      const response = await deleteBroadcast({ id: broadcast.id }).unwrap();
      displayToast({
        id: response.deleteBroadcast.id,
        type: 'success',
        title: t('deleteSuccessToast.title'),
        body: t('deleteSuccessToast.body'),
      });
    } catch (e) {
      displayToast({
        id: broadcast.id,
        type: 'error',
        title: t('deleteErrorToast.title'),
        body: t('deleteErrorToast.body'),
      });
    }
  };

  useEffect(() => {
    if (ref.current && ref.current.scrollHeight >= MIN_HEIGHT_AFTER_LINE_CLAMP) {
      setShouldDisplayCaret(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, message]);

  const isLoading = isLoadingDeleteBroadcast;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-y-2 px-4 py-5">
      <div className="flex flex-row justify-between items-start relative gap-x-2">
        <Text
          size="lg"
          className={mergeClasses('text-black flex-1 whitespace-pre-line', {
            'line-clamp-2': !open,
            'text-black-48': !isValid,
          })}
          ref={ref}>
          {message}
        </Text>
        <div className="w-6 self-start">
          {shouldDisplayCaret ? (
            <IconButton
              icon="CaretDown"
              className="h-6 w-6"
              iconColor="black"
              iconClassName={mergeClasses({
                'rotate-180': open,
              })}
              onClick={toggleDescription}
              aria-label={t('ariaExpandLabel') ?? undefined}
            />
          ) : null}
        </div>
        {!sent && <MoreButton handleEdit={handleEdit} handleDelete={handleDelete} />}
      </div>

      <div>
        <BroadcastStatusIcon
          sent={sent}
          isValid={isValid}
          errorMessage={broadcast.errorMessage ?? undefined}
        />
        <span>{sent ? t('sentTo') : t('scheduledTo')}</span>
        <span className="w-fit inline-flex flex-row flex-nowrap items-center align-middle gap-x-1 font-semibold bg-offWhite rounded px-2 py-1 ml-2 mr-2 mb-1">
          {recipientType === 'TEAM' ? (
            <Avatar
              size="xs5"
              bordered={true}
              className="border-black-12"
              placeholder={teamMode === 'TEAM' ? 'team-duo' : 'single'}
              src={teamPhoto ?? undefined}
              alt={teamName ?? ''}></Avatar>
          ) : null}
          {recipientType === 'ALL' ? t('allParticipants') : teamName}
        </span>
        <TimingText broadcast={broadcast} />
      </div>
    </div>
  );
};
