import { authSlice } from './auth.slice';
import {
  loadMyUserApi,
  loginApi,
  signUpApi,
  logoutApi,
  changePasswordApi,
  updateUserProfileApi,
  deleteAccountApi,
  forgotPasswordApi,
  isPasswordResetIdValidApi,
  resetPasswordApi,
  checkAccountDeletionApi,
  authImpersonationSessionApi,
} from './auth.api';
export type { LoginMutation, SignUpMutation } from './auth.api';

export const { useLoadMyUserQuery } = loadMyUserApi;
export const { useLoginMutation } = loginApi;
export const { useSignUpMutation } = signUpApi;
export const { useLogoutMutation } = logoutApi;
export const { useChangePasswordMutation } = changePasswordApi;
export const { useUpdateUserProfileMutation } = updateUserProfileApi;
export const { useDeleteAccountMutation } = deleteAccountApi;
export const { useForgotPasswordMutation } = forgotPasswordApi;
export const { useIsPasswordResetIdValidQuery } = isPasswordResetIdValidApi;
export const { useResetPasswordMutation } = resetPasswordApi;
export const { useCheckAccountDeletionQuery } = checkAccountDeletionApi;
export const { useAuthenticateImpersonationSessionMutation } = authImpersonationSessionApi;

export const { setAuthState, clearAccessToken, logout, setImpersonationActive } = authSlice.actions;

export const authReducer = authSlice.reducer;
export * from './auth.hooks';
