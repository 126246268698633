import { useState, useRef, type RefObject, useEffect } from 'react';
import { z } from 'zod';
import { useCreateAssetMutation } from 'data/assets';
import { useTranslate } from 'util/i18n';
import { useActiveWorkspaceQuery } from 'data/workspaces';
import { AssetMimeType, ASSET_MIME_TYPES } from 'data/models';
import { isZodError } from 'data/errors';

export type PhotoUploadUIProps = {
  label: string;
  defaultSrc?: string;
  errorMessage?: string;
  uploading: boolean;
  fileRef: RefObject<HTMLInputElement>;
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  photoUrl?: string;
  onDelete: () => void;
} & React.InputHTMLAttributes<HTMLButtonElement>;

type PhotoUploadFieldProps = {
  label: string;
  setValue: (value: string) => void;
  setError: (errorType: string, message: string) => void;
  defaultSrc?: string;
  errorMessage?: string;
  uploading: boolean;
  setUploading: (value: boolean) => void;
  photoUploadUI: (data: PhotoUploadUIProps) => JSX.Element;
} & React.InputHTMLAttributes<HTMLInputElement>;

function put(url: string, data: File) {
  return new Promise((resolve, reject) => {
    fetch(url, { method: 'PUT', body: data })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const PhotoUploadField = ({
  defaultSrc,
  label,
  setValue,
  setError,
  errorMessage,
  uploading,
  setUploading,
  photoUploadUI,
}: PhotoUploadFieldProps) => {
  const [photoUrl, setPhotoUrl] = useState<string | undefined>(defaultSrc);
  const { t } = useTranslate('pages.createExperience.createExperienceForm');
  const fileRef = useRef<HTMLInputElement>(null);
  const [create] = useCreateAssetMutation();
  const { data: workspaceData } = useActiveWorkspaceQuery();
  const workspace = workspaceData?.myActiveWorkspace;

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!uploading) {
      fileRef.current?.click();
    }
  };

  const getPresignedUrl = async (
    fileName: string,
    mimeType: AssetMimeType,
  ): Promise<{ presignedUrl: string; assetId: string }> => {
    try {
      if (!workspace) {
        throw new Error('No workspace set, cannot create asset');
      }
      const response = await create({
        params: { workspaceId: workspace.id, fileName, mimeType },
      }).unwrap();

      return response.createAsset;
    } catch {
      // TODO: Sentry - network error
      throw new Error('Error adding photo, please try again');
    }
  };

  const uploadToS3 = async (file: File, fileName: string) => {
    try {
      const mimeType = z.enum(ASSET_MIME_TYPES).parse(file.type);

      const { presignedUrl, assetId: newAssetId } = await getPresignedUrl(fileName, mimeType);

      await put(presignedUrl, file);

      const newPhotoUrl = presignedUrl.split('?').shift();

      setPhotoUrl(newPhotoUrl);
      setValue(newAssetId);
      setUploading(false);
    } catch (err) {
      if (isZodError(err)) {
        setUploading(false);
        setError(err.issues[0].code, t('photoId.errors.mimeType'));
      } else {
        throw err;
      }
    }
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    setUploading(true);

    // See: https://html.spec.whatwg.org/multipage/input.html#fakepath-srsly
    const fileName = e.target.value.replace('C:\\fakepath\\', '');
    uploadToS3(e.target.files[0], fileName);
  };

  const onDelete = () => {
    setPhotoUrl(undefined);
    setValue('');
    if (fileRef.current) {
      fileRef.current.value = '';
    }
  };

  useEffect(() => {
    if (defaultSrc) {
      setPhotoUrl(defaultSrc);
    }
  }, [defaultSrc]);

  return (
    <>
      <input
        type="file"
        id="photoFileInput"
        ref={fileRef}
        onChange={onFileChange}
        hidden
        accept="image/*"
      />
      {photoUploadUI({
        label,
        photoUrl,
        uploading,
        fileRef,
        onFileChange,
        errorMessage,
        onClick,
        onDelete,
      })}
    </>
  );
};
